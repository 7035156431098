import { z } from 'zod';
import { Box } from '@mui/material';
import { trpc } from '@api/bff/client';
import { Section } from '@components/Section';
import { dispatchToast } from '@shared/toast';
import {
  Button,
  SelectField,
  Toggle,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useProfiles } from '../Profiles/ProfileTable/hooks/useProfiles';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import {
  updatePreferencesRequestSchema,
  GetPreferencesResponse,
  UpdatePreferencesRequest,
} from 'bff/src/routers/travel-preferences/schemas';
import { PreferencesSkeleton } from './components/PreferencesSkeleton';

// Tipo para o formulário baseado no schema de request
type PreferencesFormData = UpdatePreferencesRequest;

export function Preferences() {
  const { userOptions, isLoadingUsers, isErrorUsers } = useProfiles();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isSubmitting },
    setError,
  } = useForm<PreferencesFormData>({
    resolver: zodResolver(updatePreferencesRequestSchema),
    defaultValues: {
      travelCoordinatorId: '',
      sendReservationSummaryEmail: false,
    },
    mode: 'onChange',
  });

  const {
    data: currentPreferences,
    isError,
    isLoading,
    refetch,
  } = trpc.getPreferences.useQuery<GetPreferencesResponse>(undefined, {
    staleTime: 60000,
    retry: 2,
  });

  const savePreferences = trpc.updatePreferences.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: 'success',
        content: 'Preferências salvas com sucesso!',
      });
      refetch();
    },
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Erro ao salvar as preferências. Tente novamente.',
      });
    },
  });

  useEffect(() => {
    if (currentPreferences) {
      reset({
        travelCoordinatorId: currentPreferences.travelCoordinatorId,
        sendReservationSummaryEmail:
          currentPreferences.sendReservationSummaryEmail,
      });
    }
  }, [currentPreferences, reset]);

  const onSubmit = async (data: PreferencesFormData) => {
    try {
      const validatedData = updatePreferencesRequestSchema.parse(data);
      await savePreferences.mutateAsync(validatedData);
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors.forEach((err) => {
          setError(err.path[0] as keyof PreferencesFormData, {
            type: 'manual',
            message: err.message,
          });
        });
      }
    }
  };

  const transformedUserOptions =
    userOptions?.map((user) => ({
      value: user.id,
      label: user.name,
    })) ?? [];

  if (isLoading || isLoadingUsers) {
    return <PreferencesSkeleton />;
  }

  if (isError || isErrorUsers) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={4}
        color="error.main"
      >
        <Typography variant={'body4'}>
          Erro ao carregar dados. Por favor, tente novamente mais tarde.
        </Typography>
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Section
        title="Configurações Gerais"
        caption="Edite as configurações gerais que interferem nas viagens corporativas"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Controller
              name="travelCoordinatorId"
              control={control}
              rules={{
                required: 'Responsável é obrigatório',
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <SelectField
                  {...field}
                  label="Responsável"
                  searchable
                  options={transformedUserOptions}
                  error={!!errors.travelCoordinatorId}
                  helperText={errors.travelCoordinatorId?.message}
                  fullWidth
                  value={value || ''}
                  onSelectChange={(_, newValue) => {
                    onChange(newValue?.value || null);
                  }}
                  disabled={isSubmitting}
                  required
                />
              )}
            />

            <Controller
              name="sendReservationSummaryEmail"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <Box display="flex" alignItems="center" gap={2}>
                  <Toggle
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={isSubmitting}
                    {...field}
                  />
                  <Typography variant={'body4'}>
                    Enviar resumo da reserva no email
                  </Typography>
                </Box>
              )}
            />

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                variant="primary"
                type="submit"
                disabled={!isDirty || isSubmitting}
                loading={isSubmitting}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      </Section>
    </Box>
  );
}
