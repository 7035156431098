import { trpc } from '@api/bff/client';
import { TableBaseBatchActions } from '@components/TravelDataTable';
import { Table } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types';
import { dispatchToast } from '@shared/toast';
import { GetReasonByIdResponse } from 'bff/src/routers/reasons';
import { useState } from 'react';

export type Props = {
  table: Table<GetReasonByIdResponse>;
  onRefresh: () => Promise<unknown> | unknown;
};

const ReasonTableBatchActions = ({ onRefresh, table }: Props) => {
  const deleteReason = trpc.deleteReason.useMutation();

  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    try {
      setIsDeleting(true);

      await deleteReason.mutateAsync({
        ids: table.selected.selected.map((o) => o.original.id),
        isAllSelected: table.selected.allSelected,
      });

      table.resetSelected();

      await onRefresh?.();

      dispatchToast({
        type: 'success',
        content: `Os motivos de viagem foram excluídas com sucesso`,
      });
    } catch (err) {
      onRefresh?.();

      dispatchToast({
        content:
          err?.message ||
          `Erro ao excluir os motivos de viagem. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <TableBaseBatchActions
      delete={{
        title: 'Excluir motivos',
        message:
          'Deseja realmente excluir os motivos de viagem selecionadas? Essa ação não poderá ser desfeita',
        status: 'error',
        primaryButtonLabel: 'Excluir',
        loading: isDeleting,
      }}
      onDelete={handleDelete}
    />
  );
};

export default ReasonTableBatchActions;
