import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as SC from '../reports.styled';

import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { BASE_URL } from '@shared/constants/Routes.constants';
import { UserProfileData } from '@shared/types';
import { enums } from '../../../legacy/utils/enums';

export type ReportsChoicePros = {
  userProfile: UserProfileData;
};
export default function ReportsChoice({ userProfile }: ReportsChoicePros) {
  const navigate = useNavigate();
  const {
    company: { isAgency },
    travelProfile,
  } = userProfile;

  return (
    <SC.Choices>
      <LinkButton
        variant="default"
        onClick={() => navigate(`${BASE_URL}/reports/travel-requests`)}
      >
        <React.Fragment key=".0">
          Requisições de Viagem <Icons name="IconArrowRight" />
        </React.Fragment>
      </LinkButton>
      <LinkButton
        variant="default"
        onClick={() => navigate(`${BASE_URL}/reports/bookings`)}
      >
        <React.Fragment key=".0">
          Itens de Requisição de Viagem <Icons name="IconArrowRight" />
        </React.Fragment>
      </LinkButton>
      <LinkButton
        variant="default"
        onClick={() => navigate(`${BASE_URL}/reports/air-tickets`)}
      >
        <React.Fragment key=".0">
          Bilhetes Aéreos <Icons name="IconArrowRight" />
        </React.Fragment>
      </LinkButton>
      {isAgency && travelProfile === enums.userTravelProfile.consultant && (
        <LinkButton
          variant="default"
          onClick={() => navigate(`${BASE_URL}/reports/sales-integration`)}
        >
          <React.Fragment key=".0">
            Integração de Vendas <Icons name="IconArrowRight" />
          </React.Fragment>
        </LinkButton>
      )}
    </SC.Choices>
  );
}
