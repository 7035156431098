'use strict';

import React from 'react';
import { componentUtil } from '../../../utils/componentUtil.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import TravelerTemplate from './traveler.template.js';

import { enums } from '../../../utils/enums.js';
import { listItemsResolver } from '../../../utils/resolvers/listItemresolver.js';
import { travelerResolver } from '../../../utils/resolvers/travelerResolver.js';
import { gritter, utils } from '../../../utils/utils.js';

export default class TravelerComponent extends React.Component {
  defaultNationality = { id: 210, name: 'Brasil' };

  externalOptions = {
    events: {
      onSelected: null,
      onDeleted: null,
    },
  };

  newItemIdentifier = '_new_traveler';

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    for (var propName in this.events)
      componentUtil.react.bindMethods(this.events[propName], this);

    for (var propName in this.selects)
      componentUtil.react.bindMethods(this.selects[propName], this);

    this.state = {
      traveler: null,
      confirmedTraveler: null,
      autoCompleteInputText: '',
      showSocialName: false,
      genderSelected: 0,
      documentTypeSelected: 0,
      displayDetailsTravel: false,
      showValidationsResults: false,
      showDocumentType: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      showValidationsFieldsExternal: props.showValidationsFields
        ? props.showValidationsFields
        : false,
    };
  }

  render() {
    let additionalFields = this.props.additionalFields
      ? this.props.additionalFields
      : null;
    let travelerNumber = this.props.travelerNumber
      ? this.props.travelerNumber
      : '';

    return (
      <ErrorBoundary>
        <TravelerTemplate.main
          displayDetailsTravel={this.state.displayDetailsTravel}
          traveler={this.state.traveler}
          travelerType={this.props.travelerType}
          confirmedTraveler={this.state.confirmedTraveler}
          disabled={this.props.disabled ? this.props.disabled : false}
          autoCompleteInputText={
            this.props.autoCompleteInputText
              ? this.props.autoCompleteInputText
              : this.state.autoCompleteInputText
          }
          label={this.props.label}
          showSocialName={this.state.showSocialName}
          showDocumentType={this.state.showDocumentType}
          genderOptions={this.selects.selectGender.getProps()}
          documentOptions={this.document.getProps()}
          documentTypeOptions={this.selects.selectDocument.getProps()}
          documentTypeAdditionalOptions={this.selects.selectDocument.getPropsAdditional()}
          honorificPrefixOptions={this.selects.selectHonorificPrefix.getProps()}
          socialHonorificPrefixOptions={this.selects.selectSocialHonorificPrefix.getProps()}
          birthDateOptions={this.birthDate.getProps()}
          showValidationsResults={this.state.showValidationsResults}
          showValidationsFieldsExternal={
            this.state.showValidationsFieldsExternal
          }
          additionalField={additionalFields}
          travelerNumber={travelerNumber}
          dataSource={this.dataSources.travelers}
          nationalityDataSource={this.props.dataSources.nationality}
          eventsName={this.events.name}
          eventsBirthDate={this.events.birthDate}
          eventsNationality={this.events.nationality}
          eventsPhone={this.events.phone}
          eventsEmail={this.events.email}
          eventsDocument={this.events.document}
          eventsDocumentAdditional={this.events.documentAdditional}
          onAutoCompleteChange={this.events.onAutoCompleteChanged}
          onAutoCompleteInputChange={this.events.onAutoCompleteInputChanged}
          onCloseTravelerClick={this.events.onCloseTravelerClicked}
          onConfirmTravelerClick={this.events.onConfirmTravelerClicked}
          needCompleteAddress={this.props.needCompleteAddress}
          eventsAddress={this.events.address}
          isWoobaAndInternacional={this.props.isWoobaAndInternacional}
        />
      </ErrorBoundary>
    );
  }

  closeTravelerDetails() {
    this.setState({
      displayDetailsTravel: false,
      showValidationsResults: false,
    });
  }

  resolveTravelerNationality(traveler) {
    traveler.nationalityLocationId =
      traveler.nationalityLocationId || this.defaultNationality.id;
    traveler.nationality = traveler.nationality || this.defaultNationality;
  }

  resolveTravelerName(traveler) {
      const names = traveler.name?.firstName?.split(" ");
      if (!names) return;

      const prepositions = ["da", "de", "do", "das", "dos"];
      let firstName = names[0];
      let middleName = "";
      let lastName = "";

      if (names.length === 2) {
          traveler.name.firstName = names[0];
          traveler.name.lastName = names[1];
      } else if (names.length > 2) {
          if (prepositions.includes(names.at(-2))) {
              lastName = `${names.at(-2)} ${names.at(-1)}`;
              middleName = names.slice(1, -2).join(" ");
          } else {
              lastName = names.at(-1);
              middleName = names.slice(1, -1).join(" ");
          }
          traveler.name.firstName = firstName;
          traveler.name.middleName = middleName;
          traveler.name.lastName = lastName;
      }
  }

  resolveTravelerDocument(traveler) {
    const travelerDocuments = traveler.documents;
    let documentType = 0;

    if (this.props.isInternational)
      documentType = enums.travelerDocumentType.Passport;
    else documentType = enums.travelerDocumentType.Cpf;

    let selectedDocument = travelerDocuments.find(
      (doc) => doc.type == documentType,
    );
    if (selectedDocument && !selectedDocument.printedName)
      selectedDocument.printedName = traveler.fullName;

    traveler.selectedDocumentType =
      (selectedDocument && selectedDocument.type) || documentType;
    traveler.selectedDocumentLabel = enums.travelerDocumentType[documentType];
    traveler.selectedDocumentNumber =
      (selectedDocument && selectedDocument.number) || '';
    traveler.selectedDocumentName =
      (selectedDocument && selectedDocument.printedName) || traveler.fullName;
    traveler.selectedDocumentExpiration =
      (selectedDocument && selectedDocument.expiration) || '';
  }

  resolveTravelerDocumentAdditional(traveler) {
    const travelerDocuments = traveler.documents;
    let documentType = enums.travelerDocumentType.Cpf;

    let selectedDocument = travelerDocuments.find(
      (doc) => doc.type == documentType,
    );

    if (selectedDocument && !selectedDocument.printedName)
      selectedDocument.printedName = traveler.fullName;

    traveler.selectedDocumentTypeAdditional = (selectedDocument && selectedDocument.type) || documentType;
    traveler.selectedDocumentLabelAdditional = enums.travelerDocumentType[documentType];
    traveler.selectedDocumentNumberAdditional = (selectedDocument && selectedDocument.number) || '';
    traveler.selectedDocumentNameAdditional = (selectedDocument && selectedDocument.printedName) || traveler.fullName;
    traveler.selectedDocumentExpirationAdditional =(selectedDocument && selectedDocument.expiration) || '';
  }

  events = {
    onAutoCompleteChanged: (selectedItem) => {
      if (selectedItem) {
        if (selectedItem.value == this.newItemIdentifier) {
          this.setState({
            displayDetailsTravel: true,
            traveler: this.getDefaultTravelerObj(),
          });
        } else {
          this.resolveTravelerNationality(selectedItem.traveler);
          this.resolveTravelerDocument(selectedItem.traveler);
          this.resolveTravelerName(selectedItem.traveler);

          if(this.props.isWoobaAndInternacional)
            this.resolveTravelerDocumentAdditional(selectedItem.traveler);

          this.setState({
            traveler: selectedItem.traveler,
            displayDetailsTravel: true,
          });

          this.validateFields(selectedItem.traveler);

          this.dataSources.nationality.get(
            selectedItem.traveler.nationalityLocationId,
          );
        }
      } else {
        this.setState({
          traveler: null,
          confirmedTraveler: null,
          displayDetailsTravel: false,
        });
      }
    },
    onAutoCompleteInputChanged: (inputText) => {
      this.setState({
        autoCompleteInputText: null,
      });

      if (this.state.traveler)
        this.props.onAutoCompleteInputChanged &&
          this.props.onAutoCompleteInputChanged(this.state.traveler.id);
    },

    onCloseTravelerClicked: () => {
      this.setState({
        confirmedTraveler: null,
        traveler: null,
        autoCompleteInputText: '',
        displayDetailsTravel: false,
        showValidationsResults: false,
      });

      this.props.onCloseTravelerClick && this.props.onCloseTravelerClick();
    },

    onConfirmTravelerClicked: () => {
      const _this = this;

      if (!this.validateFields()) {
        this.setState({ showValidationsResults: true });
        this.props.onValidationAdditionalFieldsClick &&
          this.props.onValidationAdditionalFieldsClick();
      } else {
        let confirmedTraveler = { ...this.state.traveler };
        let existingDocumentTypeInDocuments = confirmedTraveler.documents.find(
          (d) => d.type == confirmedTraveler.selectedDocumentType,
        );

        if (!existingDocumentTypeInDocuments)
          confirmedTraveler.documents.push({
            expiration: confirmedTraveler.selectedDocumentExpiration,
            number: confirmedTraveler.selectedDocumentNumber,
            printedName: confirmedTraveler.selectedDocumentName,
            type: confirmedTraveler.selectedDocumentType,
          });

        if(this.props.isWoobaAndInternacional) {
            let existingDocTypeInDocuments = confirmedTraveler.documents.find(
            (d) => d.type == confirmedTraveler.selectedDocumentTypeAdditional,
          );

          if(!existingDocTypeInDocuments)
            confirmedTraveler.documents.push({
            expiration: confirmedTraveler.selectedDocumentExpirationAdditional,
            number: confirmedTraveler.selectedDocumentNumberAdditional,
            printedName: confirmedTraveler.selectedDocumentNameAdditional,
            type: confirmedTraveler.selectedDocumentTypeAdditional,
          });
        }

        if (confirmedTraveler.id)
          this.dataSources.travelers.update(confirmedTraveler);
        else this.dataSources.travelers.insert(confirmedTraveler);

        this.setState({
          confirmedTraveler,
          autoCompleteInputText: null,
          showValidationsResults: false,
        });
      }
    },

    name: {
      onShowSocialNameChanged: (ev) => {
        let traveler = this.state.traveler;
        traveler.useSocialName = ev.target.checked;

        traveler.selectedDocumentName = travelerResolver.getFullName(traveler);
        if(this.props.isWoobaAndInternacional)
          traveler.selectedDocumentNameAdditional = travelerResolver.getFullName(traveler);

        this.setState({ traveler: traveler });
      },
      onFirstNameChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.name.firstName = value;

        traveler.selectedDocumentName = travelerResolver.getFullName(traveler);
        this.setDocumentPrintedName(traveler);

        if(this.props.isWoobaAndInternacional){
          traveler.selectedDocumentNameAdditional = travelerResolver.getFullName(traveler);
          this.setDocumentPrintedNameAdditional(traveler);
        }

        this.setState({ traveler: traveler });
      },
      onMiddleNameChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.name.middleName = value;

        traveler.selectedDocumentName = travelerResolver.getFullName(traveler);
        this.setDocumentPrintedName(traveler);

        if(this.props.isWoobaAndInternacional) {
          traveler.selectedDocumentNameAdditional = travelerResolver.getFullName(traveler);
          this.setDocumentPrintedNameAdditional(traveler);
        }

        this.setState({ traveler: traveler });
      },
      onLastNameChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.name.lastName = value;

        traveler.selectedDocumentName = travelerResolver.getFullName(traveler);
        this.setDocumentPrintedName(traveler);

        if(this.props.isWoobaAndInternacional){
          traveler.selectedDocumentNameAdditional = travelerResolver.getFullName(traveler);
          this.setDocumentPrintedNameAdditional(traveler);
        }

        this.setState({ traveler: traveler });
      },
      onFirstSocialNameChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.socialName.firstName = value;

        traveler.selectedDocumentName = travelerResolver.getFullName(traveler);
        this.setDocumentPrintedName(traveler);

        if(this.props.isWoobaAndInternacional){
          traveler.selectedDocumentNameAdditional = travelerResolver.getFullName(traveler);
          this.setDocumentPrintedNameAdditional(traveler);
        }

        this.setState({ traveler: traveler });
      },
      onMiddleSocialNameChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.socialName.middleName = value;

        traveler.selectedDocumentName = travelerResolver.getFullName(traveler);
        this.setDocumentPrintedName(traveler);

        if(this.props.isWoobaAndInternacional){
          traveler.selectedDocumentNameAdditional = travelerResolver.getFullName(traveler);
          this.setDocumentPrintedNameAdditional(traveler);
        }

        this.setState({ traveler: traveler });
      },
      onLastSocialNameChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.socialName.lastName = value;

        traveler.selectedDocumentName = travelerResolver.getFullName(traveler);
        this.setDocumentPrintedName(traveler);

        if(this.props.isWoobaAndInternacional){
          traveler.selectedDocumentNameAdditional = travelerResolver.getFullName(traveler);
          this.setDocumentPrintedNameAdditional(traveler);
        }

        this.setState({ traveler: traveler });
      },
      onAliasChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.alias = value;

        this.setState({ traveler: traveler });
      },
    },

    birthDate: {
      onBirthDateChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.birthDate = value;
        this.setState({ traveler: traveler });
      },
    },

    nationality: {
      onNationalityChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.nationality = value;
        traveler.nationalityLocationId = value.id;
        let showDocumentType = traveler.nationality.name != 'Brasil';
        this.setState({ traveler: traveler, showDocumentType });
      },
      onNationalityLocationInputChanged: (inputText) => {
        const traveler = this.state.traveler;
        let data = traveler.nationality || {};
        data.name = inputText;

        this.setState({ traveler: traveler });
      },
    },

    phone: {
      onPhoneChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.phone = value;
        this.setState({ traveler: traveler });
      },
    },

    email: {
      onEmailChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.email = value;
        this.setState({ traveler: traveler });
      },
    },

    document: {
      onDocumentNumberChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.selectedDocumentNumber = value;

        let existingDocumentTypeInDocuments = traveler.documents.find(
          (d) => d.type == traveler.selectedDocumentType,
        );
        if (existingDocumentTypeInDocuments)
          existingDocumentTypeInDocuments.number = value;

        this.setState({ traveler: traveler });
      },
      onDocumentNameChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.selectedDocumentName = value;

        this.setDocumentPrintedName(traveler);

        this.setState({ traveler: traveler });
      },
      onDocumentExpirationChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.selectedDocumentExpiration = value;

        let existingDocumentTypeInDocuments = traveler.documents.find(
          (d) => d.type == traveler.selectedDocumentType,
        );
        if (existingDocumentTypeInDocuments)
          existingDocumentTypeInDocuments.expiration = value;

        this.setState({ traveler: traveler });
      },
    },

    documentAdditional: {
      onDocumentNumberChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.selectedDocumentNumberAdditional = value;

        let existingDocumentTypeInDocuments = traveler.documents.find(
          (d) => d.type == traveler.selectedDocumentTypeAdditional,
        );
        if (existingDocumentTypeInDocuments)
          existingDocumentTypeInDocuments.number = value;

        this.setState({ traveler: traveler });
      },
      onDocumentNameChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.selectedDocumentNameAdditional = value;

        this.setDocumentPrintedNameAdditional(traveler);

        this.setState({ traveler: traveler });
      },
      onDocumentExpirationChanged: (value) => {
        let traveler = this.state.traveler;
        traveler.selectedDocumentExpirationAdditional = value;

        let existingDocumentTypeInDocuments = traveler.documents.find(
          (d) => d.type == traveler.selectedDocumentTypeAdditional,
        );

        if (existingDocumentTypeInDocuments)
          existingDocumentTypeInDocuments.expiration = value;

        this.setState({ traveler: traveler });
      },
    },

    address: {
      onAddressStreetChanged: (value) => {
        let traveler = this.state.traveler;
        if (!traveler.address) traveler.address = {};
        traveler.address.street = value;
        this.setState({ traveler: traveler });
      },
      onAddressComplementChanged: (value) => {
        let traveler = this.state.traveler;
        if (!traveler.address) traveler.address = {};
        traveler.address.complement = value;
        this.setState({ traveler: traveler });
      },
      onAddressNumberChanged: (value) => {
        let traveler = this.state.traveler;
        if (!traveler.address) traveler.address = {};
        traveler.address.number = value;
        this.setState({ traveler: traveler });
      },
      onAddressStateChanged: (value) => {
        let traveler = this.state.traveler;
        if (!traveler.address) traveler.address = {};
        traveler.address.state = value;
        this.setState({ traveler: traveler });
      },
      onAddressCityChanged: (value) => {
        let traveler = this.state.traveler;
        if (!traveler.address) traveler.address = {};
        traveler.address.city = value;
        this.setState({ traveler: traveler });
      },
      onAddressZipCodeChanged: (value) => {
        let traveler = this.state.traveler;
        if (!traveler.address) traveler.address = {};
        traveler.address.zipCode = value;
        this.setState({ traveler: traveler });
      },
    },
  };

  setDocumentPrintedName(traveler) {
    let existingDocumentTypeInDocuments = traveler.documents.find(
      (d) => d.type == traveler.selectedDocumentType,
    );

    if (existingDocumentTypeInDocuments)
      existingDocumentTypeInDocuments.printedName =
        traveler.selectedDocumentName;
  }

  setDocumentPrintedNameAdditional(traveler) {
    let existingDocumentTypeInDocuments = traveler.documents.find(
      (d) => d.type == traveler.selectedDocumentTypeAdditional,
    );

    if (existingDocumentTypeInDocuments)
      existingDocumentTypeInDocuments.printedName =
        traveler.selectedDocumentNameAdditional;
  }

  validateFields(traveler) {
    let additionalFields = this.props.additionalFields;

    if (!traveler) traveler = this.state.traveler;

    let isValid = true;

    isValid = traveler.name.honorificPrefix;
    isValid = isValid && this.isValidString(traveler.name.firstName);
    isValid = isValid && this.isValidString(traveler.name.lastName);

    if (traveler.useSocialName) {
      isValid = isValid && traveler.socialName.honorificPrefix;
      isValid = isValid && this.isValidString(traveler.socialName.firstName);
      isValid = isValid && this.isValidString(traveler.socialName.lastName);
    }

    isValid = isValid && this.isValidString(traveler.birthDate);
    //isValid = isValid && this.isValidString(traveler.nationality);
    isValid = isValid && this.isValidString(traveler.phone);
    isValid = isValid && this.isValidString(traveler.selectedDocumentNumber);
    if (
      traveler.selectedDocumentType == enums.travelerDocumentType.Passport ||
      traveler.selectedDocumentType == enums.travelerDocumentType.CNH
    )
      isValid =
        isValid && this.isValidString(traveler.selectedDocumentExpiration);

    isValid = isValid && traveler.gender;
    isValid = isValid && traveler.selectedDocumentType;

    if (
      additionalFields &&
      additionalFields.props &&
      additionalFields.props.hotelsOptions
    )
      isValid =
        isValid &&
        additionalFields.props.hotelsOptions.data != 0 &&
        additionalFields.props.hotelsOptions.data != null &&
        additionalFields.props.hotelsOptions.data != undefined;

    if (
      additionalFields &&
      additionalFields.props &&
      additionalFields.props.roomOptions
    )
      isValid =
        isValid &&
        additionalFields.props.roomOptions.data != 0 &&
        additionalFields.props.roomOptions.data != null &&
        additionalFields.props.roomOptions.data != undefined;

    if (this.props.needCompleteAddress) {
      isValid = isValid && this.isValidString(traveler.address.street);
      isValid = isValid && this.isValidString(traveler.address.number);
      isValid = isValid && this.isValidString(traveler.address.state);
      isValid = isValid && this.isValidString(traveler.address.city);
      isValid = isValid && this.isValidString(traveler.address.zipCode);
    }

    if(this.props.isWoobaAndInternacional) {
      isValid = isValid && this.isValidString(traveler.selectedDocumentNumberAdditional);
      isValid = isValid && traveler.selectedDocumentTypeAdditional;

      if (
        traveler.selectedDocumentTypeAdditional == enums.travelerDocumentType.Passport ||
        traveler.selectedDocumentTypeAdditional == enums.travelerDocumentType.CNH
      )
      isValid =
        isValid && this.isValidString(traveler.selectedDocumentExpirationAdditional);
    }

    return isValid;
  }

  isValidString(field) {
    return field && field.length ? true : false;
  }

  getDefaultTravelerObj() {
    return {
      id: 0,
      fullName: '',
      name: {
        firstName: '',
        middleName: '',
        lastName: '',
      },
      socialName: {
        firstName: '',
        middleName: '',
        lastName: '',
      },
      birthDate: '',
      gender: 0,
      nationalityLocationId: this.defaultNationality.id,
      nationality: this.defaultNationality,
      phone: '',
      documents: [],
      selectedDocumentType: this.props.isInternational
        ? enums.travelerDocumentType.Passport
        : enums.travelerDocumentType.Cpf,
      selectedDocumentLabel: this.props.isInternational
        ? enums.travelerDocumentType[3]
        : enums.travelerDocumentType[2],
      selectedDocumentNumber: '',
      type: this.props.travelerType ? this.props.travelerType : null,
      address: {
        id: 0,
        street: '',
        complement: '',
        number: '',
        state: '',
        city: '',
        zipCode: '',
      },
      selectedDocumentTypeAdditional: enums.travelerDocumentType.Cpf,
      selectedDocumentLabelAdditional: enums.travelerDocumentType[2],
      selectedDocumentNumberAdditional: ''
    };
  }

  selects = {
    selectGender: {
      getProps: function () {
        let _this = this;

        return {
          placeholder: 'Selecione',
          options: {
            items: listItemsResolver.resolveEnum(enums.gender),
            events: {
              onSelected: function (selectedItem) {
                let traveler = _this.state.traveler;
                traveler.gender = selectedItem.value;
                _this.setState({ traveler: traveler });
              },
            },
          },
        };
      },
    },
    selectDocument: {
      getProps: function () {
        let _this = this;

        return {
          placeholder: 'Selecione',
          options: {
            items: listItemsResolver.resolveEnum(enums.travelerDocumentType),
            isRvOffline: _this.props.isRvOffline,
            events: {
              onSelected: function (selectedItem) {
                let traveler = _this.state.traveler;
                const travelerDocument =
                  (traveler.documents &&
                    traveler.documents.find(
                      (d) => d.type == selectedItem.value,
                    )) ||
                  null;

                traveler.selectedDocumentType = selectedItem.value;
                traveler.selectedDocumentNumber =
                  (travelerDocument && travelerDocument.number) || '';
                traveler.selectedDocumentName =
                  (travelerDocument && travelerDocument.printedName) ||
                  travelerResolver.getFullName(traveler);
                traveler.selectedDocumentExpiration =
                  (travelerDocument && travelerDocument.expiration) || '';

                _this.setDocumentPrintedName(traveler);

                _this.setState({ traveler: traveler });
              },
            },
          },
        };
      },
      getPropsAdditional: function (){
        let _this = this;

        return {
          placeholder: 'Selecione',
          options: {
            items: listItemsResolver.resolveEnum(enums.travelerDocumentType),
            isRvOffline: _this.props.isRvOffline,
            events: {
              onSelected: function (selectedItem) {
                let traveler = _this.state.traveler;
                const travelerDocument =
                  (traveler.documents &&
                    traveler.documents.find(
                      (d) => d.type == selectedItem.value,
                    )) ||
                  null;

                traveler.selectedDocumentTypeAdditional = selectedItem.value;
                traveler.selectedDocumentNumberAdditional = (travelerDocument && travelerDocument.number) || '';
                traveler.selectedDocumentNameAdditional = (travelerDocument && travelerDocument.printedName) || travelerResolver.getFullName(traveler);
                traveler.selectedDocumentExpirationAdditional = (travelerDocument && travelerDocument.expiration) || '';

                _this.setDocumentPrintedNameAdditional(traveler);

                _this.setState({ traveler: traveler });
              },
            },
          },
        };
      }
    },
    selectHonorificPrefix: {
      getProps: function () {
        let _this = this;

        return {
          placeholder: 'Mx.',
          options: {
            items: listItemsResolver.resolveEnum(enums.travelerHonorificPrefix),
            events: {
              onSelected: function (selectedItem) {
                let traveler = _this.state.traveler;
                traveler.name.honorificPrefix = selectedItem.value;
                _this.setState({ traveler: traveler });
              },
            },
          },
        };
      },
    },
    selectSocialHonorificPrefix: {
      getProps: function () {
        let _this = this;

        return {
          placeholder: 'Mx.',
          options: {
            items: listItemsResolver.resolveEnum(enums.travelerHonorificPrefix),
            events: {
              onSelected: function (selectedItem) {
                let traveler = _this.state.traveler;
                traveler.socialName.honorificPrefix = selectedItem.value;
                _this.setState({ traveler: traveler });
              },
            },
          },
        };
      },
    },
  };

  birthDate = {
    getProps: () => {
      return {
        validateBirthDate: (strDate) => {
          let validationResult = {
            isValid: true,
            message: '',
          };

          const date = utils.date.strDateToDate(strDate, '/');
          const age = utils.date.getYearsOld(new Date(), date);

          const ageRangeByTravelerType = {};

          ageRangeByTravelerType[enums.travelerType.Adt] = [
            12,
            100,
            'Idade deve ser maior ou igual a 12 anos.',
          ];
          ageRangeByTravelerType[enums.travelerType.Chd] = [
            2,
            12,
            'Idade deve estar entre 2 e 12 anos.',
          ];
          ageRangeByTravelerType[enums.travelerType.Inf] = [
            0,
            2,
            'Idade deve ser menor que 2 anos',
          ];

          const ageRange = ageRangeByTravelerType[this.props.travelerType];

          if (age < ageRange[0] || age >= ageRange[1]) {
            validationResult.isValid = false;
            validationResult.message = ageRange[2];

            console.log('TravelerAgeCalculated', age);
          }

          return validationResult;
        },
      };
    },
  };

  document = {
    getProps: () => {
      return {
        validateDocumentByType: (travelerDocumentType, documentNumber) => {
          let validationResult = {
            isValid: true,
            message: '',
          };

          if (
            travelerDocumentType == enums.travelerDocumentType.RG &&
            !utils.document.validateRg(documentNumber)
          ) {
            validationResult.isValid = false;
            validationResult.message = 'Numero de RG inválido';
          } else if (
            travelerDocumentType == enums.travelerDocumentType.CNH &&
            !utils.document.validateCnh(documentNumber)
          ) {
            validationResult.isValid = false;
            validationResult.message = 'Numero de CNH inválido';
          } else if (
            travelerDocumentType == enums.travelerDocumentType.Passport &&
            !utils.document.validatePassport(documentNumber)
          ) {
            validationResult.isValid = false;
            validationResult.message = 'Numero de passaporte inválido';
          }

          return validationResult;
        },
      };
    },
  };

  highlightExternalTraveler(traveler) {
    traveler.labelToShow = traveler.label;
    if (traveler.userId == null) traveler.labelToShow += ' (Externo)';
  }

  dataSources = {
    travelers: {
      load: (loadOptions) => {
        const _this = this;
        const dataSource = this.props.dataSources.travelers;

        loadOptions.travelerType = _this.props.travelerType;
        if (_this.props.travelerType == enums.travelerType.Chd)
          loadOptions.loadUsers = false;

        return new Promise((resolve, reject) => {
          dataSource.load(loadOptions).then((items) => {
            //Destaca viajantes externos
            items.forEach((i) => {
              _this.highlightExternalTraveler(i.traveler);
              i.label = i.traveler.labelToShow;
            });

            //Injeta o item para adicionar o viajante
            const newItemObj = {
              value: _this.newItemIdentifier,
              label: ' + Adicionar Viajante ',
            };
            const newItems = [newItemObj, ...items];

            resolve(newItems);
          });
        });
      },
      insert: (traveler) => {
        const _this = this;
        const dataSource = this.props.dataSources.travelers;

        if (traveler.gender)
          // TODO: retirar após correção do backend de Genre para Gender
          traveler.genre = traveler.gender;

        dataSource.events.beforeInsert &&
          dataSource.events.beforeInsert(traveler);

        dataSource
          .insert(traveler)
          .then((traveler) => {
            _this.highlightExternalTraveler(traveler);
            traveler.type = _this.props.travelerType
              ? _this.props.travelerType
              : null;

            _this.setState({
              confirmedTraveler: traveler,
              displayDetailsTravel: false
            });

            let docs = [];
            docs.push(traveler.documents.find(d => d.type == _this.state.traveler.selectedDocumentType))
            if (_this.props.isWoobaAndInternacional)
              docs.push(traveler.documents.find(d => d.type == _this.state.traveler.selectedDocumentTypeAdditional))

            //Confirma somente o documento conforme seleção
            traveler.documents = docs;

            _this.props.onTravelerConfirm &&
              _this.props.onTravelerConfirm(traveler);
          })
          .catch((result) => {
            _this.setState({ confirmedTraveler: null });
          })
          .finally((result) => {
            dataSource.events.afterInsert &&
              dataSource.events.afterInsert(result, traveler);
          });
      },
      update: (traveler) => {
        const _this = this;
        const dataSource = this.props.dataSources.travelers;

        if (traveler.gender)
          // TODO: retirar após correção do backend de Genre para Gender
          traveler.genre = traveler.gender;

        dataSource.events.beforeUpdate &&
          dataSource.events.beforeUpdate(traveler);

        dataSource
          .update(traveler)
          .then((traveler) => {
            _this.highlightExternalTraveler(traveler);
            traveler.type = _this.props.travelerType
              ? _this.props.travelerType
              : null;

            _this.setState({
              confirmedTraveler: traveler,
              displayDetailsTravel: false
            });

            let docs = [];
            docs.push(traveler.documents.find(d => d.type == _this.state.traveler.selectedDocumentType))
            if (_this.props.isWoobaAndInternacional)
              docs.push(traveler.documents.find(d => d.type == _this.state.traveler.selectedDocumentTypeAdditional))

            //Confirma somente o documento conforme seleção
            traveler.documents = docs;

            _this.props.onTravelerConfirm &&
              _this.props.onTravelerConfirm(traveler);
          })
          .catch((result) => {
            _this.setState({ confirmedTraveler: null });
          })
          .finally((result) => {
            dataSource.events.afterUpdate &&
              dataSource.events.afterUpdate(result, traveler);
          });
      },
      events: {
        beforeLoad: (loadOptions) => {
          const dataSource = this.props.dataSources.travelers;
          dataSource.events &&
            dataSource.events.beforeLoad &&
            dataSource.events.beforeLoad(loadOptions);
        },
        afterLoad: (items) => {
          const dataSource = this.props.dataSources.travelers;
          dataSource.events &&
            dataSource.events.afterLoad &&
            dataSource.events.afterLoad(items);
        },
      },
    },
    nationality: {
      get: (id) => {
        let _this = this;

        if (id != 0 && id != undefined && id != null) {
          let dataSource = _this.props.dataSources.nationality;

          dataSource.events &&
            dataSource.events.beforeLoad &&
            dataSource.events.beforeLoad();

          dataSource
            .get(id)
            .then((item) => {
              let traveler = _this.state.traveler;
              traveler.nationality = {
                id: item.l.i,
                name: item.l.n,
                ia: item.l.ia,
                type: item.l.t,
                parentId: item.l.p,
              };
              let showDocumentType = traveler.nationality.name != 'Brasil';
              _this.setState({ traveler, showDocumentType });

              if (dataSource.events && dataSource.events.afterLoad)
                dataSource.events.afterLoad(item);
            })
            .catch((result) => {
              gritter.Error(result);

              if (dataSource.events && dataSource.events.afterLoad)
                dataSource.events.afterLoad();
            });
        }
      },
    },
  };
}
