import { trpc } from '@api/bff/client';
import { Icon } from '@components/Pills/styled';
import { TravelDataTable } from '@components/TravelDataTable';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { useState } from 'react';
import { PolicyRegister } from '../PolicyRegister';
import PolicyTableActions from './components/PolicyTableActions';
import {
  usePoliciesColumns,
  usePoliciesFilters,
} from './hooks/usePoliciesTable';

const ONE_MINUTE = 60000;

export function PolicyTable() {
  const { data, isError, isLoading, refetch } = trpc.getPolicies.useQuery(
    null,
    { staleTime: ONE_MINUTE },
  );

  const columns = usePoliciesColumns();
  const filters = usePoliciesFilters();

  const [editingPolicyId, setEditingPolicyId] = useState<string | null>(null);
  const [showPolicyRegister, setShowPolicyRegister] = useState(false);

  return (
    <Box width="100%">
      <Box border={1} color={'#ebe6e9'} p={2} borderRadius={2}>
        <TravelDataTable
          header={{ title: 'Políticas', tagLabel: 'políticas' }}
          headerEndContent={
            <Button
              size="large"
              variant={'primary'}
              onClick={() => setShowPolicyRegister(true)}
            >
              Adicionar política <Icon name="IconPlus" />
            </Button>
          }
          search={{
            placeholder: 'Busca por nome, descrição da política',
          }}
          columns={columns}
          filters={filters}
          rows={data?.policies || []}
          renderActions={({ item: policy }) => (
            <PolicyTableActions
              policy={policy}
              onRefresh={refetch}
              onEdit={() => {
                setEditingPolicyId(policy.id);
                setShowPolicyRegister(true);
              }}
            />
          )}
          isLoading={isLoading}
          isError={isError}
        />
      </Box>

      {showPolicyRegister && (
        <PolicyRegister
          policyId={editingPolicyId}
          onCancel={() => {
            setEditingPolicyId(null);
            setShowPolicyRegister(false);
          }}
          onSave={() => {
            refetch?.();

            setShowPolicyRegister(false);
            setEditingPolicyId(null);
          }}
        />
      )}
    </Box>
  );
}
