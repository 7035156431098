import { BooleanTag } from '@components/BooleanTag';
import {
  TravelTableColumn,
  TravelTableFilter,
} from '@components/TravelDataTable/types';
import { GetReasonsResponse } from 'bff/src/routers/reasons';
import { ReasonStatus } from 'bff/src/routers/reasons/enums';
import { format } from 'date-fns';
import { ToggleReasonStatus } from '../components/ToggleReasonStatus';

type Reason = GetReasonsResponse['reasons'][number];

export function useReasonsColumns(): TravelTableColumn<Reason>[] {
  return [
    {
      accessorKey: 'code',
      header: 'Código',
    },
    {
      accessorKey: 'name',
      header: 'Nome do motivo de viagem',
    },
    {
      accessorKey: 'requireDetails',
      header: 'Descrição Obrigatória',
      cell: ({ row }) => <BooleanTag value={row.original.requireDetails} />,
    },
    {
      accessorKey: 'updatedAt',
      header: 'Última atualização',
      cell: ({ row }) =>
        format(new Date(row.original.updatedAt), 'dd/MM/yyyy HH:mm'),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      sticky: 'right',
      size: 100,
      cell: ({ row }) => <ToggleReasonStatus reason={row.original} />,
    },
  ];
}

export function useReasonsFilters(): TravelTableFilter[] {
  return [
    {
      name: 'status',
      label: 'Status',
      options: [
        { label: 'Ativo', value: ReasonStatus.ACTIVE },
        { label: 'Inativo', value: ReasonStatus.INACTIVE },
      ],
    },
  ];
}
