import React from 'react';
import { Box } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { Wizard } from '@components/Wizard';
import { useProfileForm } from './hooks/useProfileForm';
import { useProfileTitle } from '../hooks/useProfileTitle';
import { TravelProfileType } from '../types';
import { WizardStep } from '@components/Wizard/components/WizardStep';
import { Section } from '@components/Section';
import { FormStateDebug } from '@components/FormStateDebug';
import TransferListEmployees from './components/TransferListEmployees';

type Props = {
  profileId?: string | null;
  profileType: TravelProfileType;
  onSave: () => void;
  onCancel: () => void;
  debug?: boolean;
};

export const RegisterProfile = ({
  onCancel,
  onSave,
  profileType,
  debug,
}: Props) => {
  const { profileForm, onSubmit, isSaving } = useProfileForm({
    onSave,
  });
  const profileTitle = useProfileTitle(profileType);
  return (
    <Wizard
      onCancel={onCancel}
      steps={['Perfil de viagem']}
      onComplete={onSubmit}
      isSubmitting={isSaving}
      activeStep={0}
    >
      <WizardStep
        title={`Editar perfil de viagem - ${profileTitle}`}
        description="Escolha quais pessoas você gostaria de adicionar a esse perfil de viagem."
      >
        <FormProvider {...profileForm}>
          <Section
            title="Definição de Perfis de Usuário"
            caption="Selecione, entre as opções disponíveis, quais usuários estarão habilitados para este perfil."
          >
            <TransferListEmployees profileType={profileType} />
          </Section>
          {debug && (
            <Box>
              <FormStateDebug />
            </Box>
          )}
        </FormProvider>
      </WizardStep>
    </Wizard>
  );
};
