import { trpc } from '@api/bff/client';
import { TableBaseActions } from '@components/TravelDataTable';
import { dispatchToast } from '@shared/toast';
import { GetReasonsResponse } from 'bff/src/routers/reasons';
import { memo, useState } from 'react';

export type Props = {
  reason: GetReasonsResponse['reasons'][number];
  isSelecting?: boolean;
  onRefresh: () => Promise<unknown> | unknown;
  onEdit: () => void;
};

const ReasonTableActions = ({
  reason,
  onRefresh,
  onEdit,
  isSelecting,
}: Props) => {
  const duplicateReason = trpc.duplicateReason.useMutation();
  const deleteReason = trpc.deleteReason.useMutation();

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDuplicate() {
    try {
      setIsDuplicating(true);

      await duplicateReason.mutateAsync({ id: reason.id });

      await onRefresh?.();

      dispatchToast({
        type: 'success',
        content: `O motivo de viagem ${reason.name} foi duplicada com sucesso`,
      });
    } catch (err) {
      onRefresh?.();

      dispatchToast({
        content:
          err.message ||
          `Erro ao duplicar o motivo de viagem. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDuplicating(false);
    }
  }

  async function handleDelete() {
    try {
      setIsDeleting(true);

      await deleteReason.mutateAsync({ ids: [reason.id] });

      await onRefresh?.();

      dispatchToast({
        type: 'success',
        content: `O motivo de viagem ${reason.name} foi excluída com sucesso`,
      });
    } catch (err) {
      onRefresh?.();

      dispatchToast({
        content:
          err?.message ||
          `Erro ao excluir o motivo de viagem. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <TableBaseActions
      delete={{
        title: 'Excluir motivo de viagem',
        message:
          'Deseja realmente excluir esso motivo de viagem? Essa ação não poderá ser desfeita',
        status: 'error',
        primaryButtonLabel: 'Excluir',
        loading: isDeleting,
        disabled: isSelecting,
      }}
      duplicate={{
        loading: isDuplicating,
        disabled: isSelecting,
      }}
      edit={{
        disabled: isSelecting,
      }}
      onDelete={handleDelete}
      onDuplicate={handleDuplicate}
      onEdit={onEdit}
      actions={['edit', 'delete', 'duplicate']}
    />
  );
};

export default memo(ReasonTableActions);
