import {
  BoxAdornment,
  TextField,
  Toggle,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box, Grid } from '@mui/material';
import {
  Controller,
  ControllerRenderProps,
  UseFormReturn,
} from 'react-hook-form';

import { Autocomplete } from '@components/Autocomplete';
import { CodeField } from '@components/CodeField';
import { generateCodeByText } from '@utils/code.utils';
import { getProductName } from '@utils/product.utils';
import { JustificationStatus } from 'bff/src/routers/justifications/enums';
import { TravelProduct } from 'bff/src/routers/policies/enums';
import { ChangeEvent, useState } from 'react';
import { JustificationRegisterFormData } from '../../types';
import { Container, FieldDataWrapper } from './styled';

type Props = {
  justificationForm: UseFormReturn<JustificationRegisterFormData>;
  isEditing?: boolean;
};

export const JustificationForm = ({ justificationForm, isEditing }: Props) => {
  const [shouldGenerateCode, setShouldGenerateCode] = useState(!isEditing);

  function onNameChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<JustificationRegisterFormData, 'name'>,
  ) {
    field.onChange(e);

    if (!shouldGenerateCode) return;

    const generatedCode = generateCodeByText(e.target.value);

    justificationForm.setValue('code', generatedCode);
  }

  return (
    <Container>
      <FieldDataWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Controller
              control={justificationForm.control}
              name="product"
              render={({ field }) => (
                <Autocomplete
                  label="Produto"
                  options={[
                    {
                      label: getProductName(TravelProduct.ALL),
                      value: TravelProduct.ALL,
                      icon: 'IconPackage',
                    },
                    {
                      label: getProductName(TravelProduct.HOTEL),
                      value: TravelProduct.HOTEL,
                      icon: 'IconPlane',
                    },
                    {
                      label: getProductName(TravelProduct.AIR),
                      value: TravelProduct.AIR,
                      icon: 'IconBuilding',
                    },
                    {
                      label: getProductName(TravelProduct.VEHICLE),
                      value: TravelProduct.VEHICLE,
                      icon: 'IconCar',
                    },
                  ]}
                  {...field}
                  onSelectChange={(option) => field.onChange(option?.value)}
                />
              )}
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Controller
              control={justificationForm.control}
              name="name"
              render={({ field }) => (
                <TextField
                  label="Titulo da justificativa"
                  fullWidth
                  required
                  inputProps={{
                    maxlength: 255,
                  }}
                  {...field}
                  onChange={(e) => onNameChange(e, field)}
                />
              )}
              rules={{ required: true }}
            />
          </Grid>
        </Grid>

        <Controller
          control={justificationForm.control}
          name="code"
          render={({ field }) => (
            <CodeField
              label="Código"
              required
              {...field}
              onChange={(e) => {
                field.onChange(e);

                setShouldGenerateCode(false);
              }}
            />
          )}
          rules={{ required: true }}
        />

        <Box>
          <BoxAdornment
            title="Ativar justificativa"
            description="Quando ativo, a justificativa aparece como uma seleção disponivel para o usuário ao solicitar uma viagem."
            leftAdornment={
              <Controller
                control={justificationForm.control}
                name="status"
                render={({ field }) => (
                  <Toggle
                    checked={field.value === JustificationStatus.ACTIVE}
                    onChange={(e, checked) =>
                      field.onChange(
                        checked
                          ? JustificationStatus.ACTIVE
                          : JustificationStatus.INACTIVE,
                      )
                    }
                  />
                )}
              />
            }
          />
        </Box>

        <Box>
          <BoxAdornment
            title="Descrição obrigatória"
            description="Quando ativo, o usuário deve preencher o campo de descrição da justificativa ao solicitar uma viagem."
            leftAdornment={
              <Controller
                control={justificationForm.control}
                name="requireDetails"
                render={({ field }) => (
                  <Toggle
                    checked={field.value}
                    onChange={(e, checked) => field.onChange(checked)}
                  />
                )}
              />
            }
          />
        </Box>

        <Box>
          <BoxAdornment
            title="Aplicar para Consultor"
            description="Quando ativo, o consultor também deve preencher o campo de justificativa ao solicitar uma viagem."
            leftAdornment={
              <Controller
                control={justificationForm.control}
                name="applyForConsultant"
                render={({ field }) => (
                  <Toggle
                    checked={field.value}
                    onChange={(e, checked) => field.onChange(checked)}
                  />
                )}
              />
            }
          />
        </Box>
      </FieldDataWrapper>
    </Container>
  );
};
