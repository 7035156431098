import {
  createApprovalFlowInputSchema,
  createApprovalFlowBasicInputSchema,
  createApprovalFlowCriteriaInputSchema,
} from 'bff/src/routers/approval-flows/schemas';
import { ApprovalRegisterFormStep } from '../../types';

type Props = {
  step: ApprovalRegisterFormStep;
};

export function useApprovalFormValidation(props: Props) {
  if (props.step === ApprovalRegisterFormStep.BASIC_INFORMATION) {
    return createApprovalFlowBasicInputSchema;
  }

  if (props.step === ApprovalRegisterFormStep.APPROVAL_CRITERIA) {
    return createApprovalFlowCriteriaInputSchema;
  }

  if (props.step === ApprovalRegisterFormStep.TYPE_APPROVAL) {
    return createApprovalFlowInputSchema;
  }

  return createApprovalFlowInputSchema;
}
