import { IconsProps, TabItens } from '@flash-tecnologia/hros-web-ui-v2';
import React from 'react';
import { ApprovalTable } from './Approvals/ApprovalTable';
import { CustomFieldsTable } from './CustomFields/CustomFieldsTable';
import { JustificationsTable } from './Justifications/JustificationTable';
import { PolicyTable } from './Policies/PolicyTable';
import { ReasonsTable } from './Reasons/ReasonTable';
import { ProfileTable } from './Profiles/ProfileTable';
import { TravelConfigurationTabs } from './styled';
import { Preferences } from './Settings/Preferences';

const TravelConfigurationPage: React.FC = () => {
  const onTabChanged = (index: number) => {
    console.log('onTabChanged', index);
  };

  const tabItens: TabItens[] = [
    {
      label: 'Configurações',
      component: <Preferences />,
      iconLabel: 'IconSettings' as IconsProps['name'],
    },
    {
      label: 'Políticas',
      component: <PolicyTable />,
      iconLabel: 'IconUsers' as IconsProps['name'],
    },
    {
      label: 'Aprovações',
      component: <ApprovalTable />,
      iconLabel: 'IconChartBar' as IconsProps['name'],
    },
    {
      label: 'Perfil de viagem',
      component: <ProfileTable />,
      iconLabel: 'IconUserCog' as IconsProps['name'],
    },
    {
      label: 'Justificativas',
      component: <JustificationsTable />,
      iconLabel: 'IconMessage2' as IconsProps['name'],
    },
    {
      label: 'Motivos',
      component: <ReasonsTable />,
      iconLabel: 'IconBriefcase' as IconsProps['name'],
    },
    {
      label: 'Campos',
      component: <CustomFieldsTable />,
      iconLabel: 'IconInputCheck' as IconsProps['name'],
    },
  ];

  return (
    <TravelConfigurationTabs
      tabItens={tabItens}
      onTabChanged={onTabChanged}
      variant="secondary"
    />
  );
};

export default TravelConfigurationPage;
