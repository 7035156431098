import Popup from '../../../popup/popup.component.js';
import Select from '../../../select/select.component.js';

let SearchTemplate = {
  main: ({
    showPopup,
    companiesRequestSearcherProps,
    events,
    requestSearcher,
    onRequestNumberChange,
    forceValidation,
    onSearchTypeChange,
  }) => {
    // Configuração do Select de tipo de busca
    const searchTypeOptions = {
      items: [
        { value: 'rv', label: 'Busca por RV' },
        { value: 'locator', label: 'Busca por Localizador' },
      ],
      events: {
        onSelected: (selected) => {
          if (selected && onSearchTypeChange) {
            onSearchTypeChange(selected.value);
          }
        },
      },
    };

    const searchTypeData = {
      value: requestSearcher.searchType,
      label:
        requestSearcher.searchType === 'rv'
          ? 'Busca por RV'
          : 'Busca por Localizador',
    };

    const inputLabel =
      requestSearcher.searchType === 'rv' ? 'Requisição' : 'Localizador';

    return (
      <div className="exon-requests-travel-header-input">
        {showPopup && (
          <Popup
            okButtonLabel={'Pesquisar'}
            title={''}
            closeButtonClick={events.onClosePopupclicked}
            cancelButtonClick={events.onClosePopupclicked}
            cancelButtonLabel={'Cancelar'}
            exonStyle={true}
            okButtonClick={events.onSearchByNumberClick}
          >
            <div className="exon-box-popop">
              <div>
                <h2>Buscar</h2>
              </div>
              <div className="exon-search-field">
                <label className="exon-search-label">Tipo de Pesquisa</label>
                <div className="exon-search-input">
                  <Select
                    data={searchTypeData}
                    externalOptions={searchTypeOptions}
                    placeholder="Selecione o tipo de busca"
                  />
                </div>
              </div>
              <div className="exon-search-field">
                <label className="exon-search-label">{inputLabel}</label>
                <div className="exon-search-input">
                  <input
                    placeholder={`Digite o ${requestSearcher.searchType === 'rv' ? 'nº' : 'localizador'}`}
                    className="dm-grid-filter form-control exon-input-request-number"
                    value={requestSearcher.requestNumber}
                    onKeyPress={(e) => events.onRequestKeyPress(e)}
                    onChange={(e) => onRequestNumberChange(e)}
                  />
                </div>
              </div>
              <div className="exon-search-field">
                <label className="exon-search-label">Cliente</label>
                <div className="exon-search-input">
                  <Select
                    data={companiesRequestSearcherProps.data}
                    externalOptions={companiesRequestSearcherProps.options}
                    placeholder={companiesRequestSearcherProps.placeholder}
                    required={true}
                    forceValidation={forceValidation}
                    showSearch={true}
                  />
                </div>
              </div>
            </div>
          </Popup>
        )}

        <div className="exon-field">
          <label className="exon-label-field">{inputLabel}</label>
          <input
            placeholder={`Digite o ${requestSearcher.searchType === 'rv' ? 'nº' : 'localizador'}`}
            className="dm-grid-filter form-control exon-input-request-number"
            value={requestSearcher.requestNumber}
            onKeyPress={(e) => events.onRequestKeyPress(e)}
            onChange={(e) => onRequestNumberChange(e)}
          />
        </div>
        <i
          className="exon-icon exon-requests-travel-header-icon-search exon-icon-search-unfilled_disabled exon-icon-button"
          onClick={() => events.onSearchByNumberClick()}
        />
      </div>
    );
  },
};

export default SearchTemplate;
