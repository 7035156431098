import CriteriaFields from '@components/CriteriaFields';
import { PolicyConditionField } from 'bff/src/routers/policies/enums';
import { useFormContext } from 'react-hook-form';
import { PolicyRegisterForm } from '../../../types';

export const PolicyConditions = () => {
  const { control } = useFormContext<PolicyRegisterForm>();

  return (
    <CriteriaFields
      control={control}
      name="conditions"
      fields={[
        { type: 'branch', value: PolicyConditionField.SUBSIDIARY },
        { type: 'area', value: PolicyConditionField.AREA },
        { type: 'position', value: PolicyConditionField.JOB_POSITION },
        { type: 'company', value: PolicyConditionField.COMPANY },
        { type: 'project', value: PolicyConditionField.PROJECT },
        { type: 'user', value: PolicyConditionField.USER },
      ]}
      static={{
        condition: 'and',
        operator: 'equal',
      }}
      avoidRepeatFields
      enableUserCustomFields
    />
  );
};
