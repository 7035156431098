import React from 'react';
import { TravelDataTableProps } from '@components/TravelDataTable/types';
import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';

interface TableFiltersProps<T> extends TravelDataTableProps<T> {
  onFilterChange: (filters: Record<string, string[]>) => void;
}

export function TableFilters<T>({
  filters,
  onFilterChange,
  headerEndContent,
}: TableFiltersProps<T>) {
  const handleFilterChange = (appliedFilters) => {
    const formattedFilters = appliedFilters?.reduce((acc, filter) => {
      acc[filter.name] = filter.values;
      return acc;
    }, {});
    onFilterChange(formattedFilters);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      {filters ? (
        <Table.Filters
          style={{ width: 'auto' }}
          label="Filtrar por:"
          filters={filters || []}
          onChange={handleFilterChange}
        />
      ) : (
        <div />
      )}

      {headerEndContent}
    </Box>
  );
}
