import { Spinner, Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { dispatchToast } from '@shared/toast';
import { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

type Props = {
  defaultChecked: boolean;
  onToggleStatus: (value: boolean) => Promise<unknown>;
  isLoading: boolean;
};

export const TableStatusToggle = ({
  defaultChecked,
  onToggleStatus,
  isLoading,
}: Props) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleUpdateStatus = useDebouncedCallback(async (value: boolean) => {
    try {
      await onToggleStatus(value);
    } catch (err) {
      setIsChecked(!value);

      dispatchToast({
        type: 'error',
        content:
          err?.message ||
          'Ocorreu um erro ao alterar o status. Tente novamente',
      });
    }
  }, 300);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      position="absolute"
      justifyContent="center"
      mt={-1.5}
    >
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 1,
          pointerEvents: isLoading ? 'none' : 'auto',
        }}
      >
        <Toggle
          checked={isChecked}
          onChange={async (e) => {
            setIsChecked(e.target.checked);
            handleUpdateStatus(e.target.checked);
          }}
        />
      </Box>

      {isLoading && (
        <Box position="absolute">
          <Spinner size={16} variant="primary" />
        </Box>
      )}
    </Box>
  );
};
