import { useEffect } from 'react';
import { trpc } from '@api/bff/client';
import { Resolver, useForm } from 'react-hook-form';
import { useApprovalTable } from './useApprovalTable';
import { GetApprovalFlowByIdResponse } from 'bff/src/routers/approval-flows';
import { useApprovalFormValidation } from './useApprovalFormValidation';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateApprovalFlowInput } from 'bff/src/routers/approval-flows/schemas';
import { dispatchToast } from '@shared/toast';
import { ApprovalRegisterFormStep } from '../../types';

type Props = {
  step: ApprovalRegisterFormStep;
  approvalFlow: GetApprovalFlowByIdResponse;
  onSave: () => void;
};

export function useApprovalFlowForm({ onSave, approvalFlow, step }: Props) {
  const validation = useApprovalFormValidation({ step });
  const { refetch } = useApprovalTable();

  const approvalFlowForm = useForm({
    defaultValues: {
      condition: [
        {
          conditionType: 'MANUAL',
          value: '',
          level: 1,
        },
      ],
      criteria: [
        {
          relationshipCondition: 'WHEN',
        },
      ],
      flowMode: 'SEQUENTIAL',
    },
    resolver: zodResolver(validation) as Resolver<CreateApprovalFlowInput>,
  });

  const saveApprovalFlowMutation = trpc.createApprovalFlow.useMutation();
  const updateApprovalFlowMutation = trpc.updateApprovalFlow.useMutation();

  async function onSubmit(data: CreateApprovalFlowInput) {
    try {
      const transformedData = {
        name: data.name,
        description: data.description,
        approvalType: data.approvalType,
        condition: data.condition,
        criteria: data.criteria,
        priority: data.priority,
        flowMode: data.flowMode,
      };

      if (approvalFlow?.id) {
        await updateApprovalFlowMutation.mutateAsync({
          ...transformedData,
          id: approvalFlow.id,
        });
        dispatchToast({
          type: 'success',
          content: 'O fluxo foi atualizado com sucesso',
        });
      } else {
        // Se não existe ID, está criando novo
        await saveApprovalFlowMutation.mutateAsync(transformedData);
        dispatchToast({
          type: 'success',
          content: 'O fluxo foi adicionado com sucesso',
        });
      }

      await refetch();
      onSave?.();
    } catch (error) {
      dispatchToast({
        type: 'error',
        content: `Ocorreu um erro ao ${
          approvalFlow?.id ? 'atualizar' : 'adicionar'
        } o fluxo. Tente novamente. ${error}`,
      });
    }
  }

  useEffect(() => {
    if (!approvalFlow) return;
    return approvalFlowForm.reset({
      ...approvalFlow,
    });
  }, [approvalFlow]);

  return {
    approvalFlowForm,
    onSubmit,
    isSaving:
      approvalFlowForm.formState.isSubmitting ||
      updateApprovalFlowMutation.isLoading,
  };
}
