import { useProfileColumns } from './hooks/useProfileTable';
import { useProfileConfigurationStore } from '../store/ProfileConfiguration.store';
import { Button, Tab, TabItens } from '@flash-tecnologia/hros-web-ui-v2';
import ProfileTableActions from './components/ProfileTableActions';
import { TravelDataTable } from '@components/TravelDataTable';
import { useProfileTitle } from '../hooks/useProfileTitle';
import React, { useMemo, useState, useEffect } from 'react';
import { RegisterProfile } from '../RegisterProfile';
import { Icon } from '@components/Pills/styled';
import { TravelProfileType } from '../types';
import { trpc } from '@api/bff/client';
import { Box } from '@mui/material';

export function ProfileTable() {
  const columns = useProfileColumns();
  const [showProfileRegister, setShowProfileRegister] = useState(false);
  const [currentProfileType, setCurrentProfileType] =
    useState<TravelProfileType>(TravelProfileType.FULL);
  const { setSelectedEmployeeIds, setProfileType } =
    useProfileConfigurationStore();
  const utils = trpc.useContext();

  // Pré-fetch e cache de todos os tipos de perfil
  const profileTypes = [
    TravelProfileType.FULL,
    TravelProfileType.APPROVER,
    TravelProfileType.REQUESTER,
    TravelProfileType.BASIC,
  ];

  const {
    data: companyProfiles,
    isLoading: isLoadingCompanyProfiles,
    isError: isErrorCompanyProfiles,
  } = trpc.getCompanyProfiles.useQuery(undefined, {
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 5,
  });

  const onTabChanged = (index: number) => {
    setCurrentProfileType(profileTypes[index]);
  };

  const profileTitle = useProfileTitle(currentProfileType);

  // Perfis com funcionários a partir de `companyProfiles`
  const profilesWithEmployees = useMemo(() => {
    if (
      !companyProfiles ||
      !companyProfiles[currentProfileType] ||
      !companyProfiles[currentProfileType].employees
    ) {
      return [];
    }

    const { employees } = companyProfiles[currentProfileType];

    return employees.map((employee) => ({
      type: currentProfileType,
      employeeId: employee.id,
      employeeName: employee.name,
      employeeEmail: employee.email,
      employeeStatus: employee.status,
    }));
  }, [companyProfiles, currentProfileType]);

  useEffect(() => {
    if (
      companyProfiles &&
      companyProfiles[currentProfileType] &&
      companyProfiles[currentProfileType].employees
    ) {
      const employeeIds = companyProfiles[currentProfileType].employees.map(
        (employee) => employee.id,
      );
      setSelectedEmployeeIds(employeeIds);
      setProfileType(currentProfileType);
    }
  }, [
    companyProfiles,
    currentProfileType,
    setSelectedEmployeeIds,
    setProfileType,
  ]);

  const renderTable = () => (
    <Box width="100%">
      <Box border={1} color={'#ebe6e9'} p={2} borderRadius={2}>
        <TravelDataTable
          header={{
            title: `Perfis de ${profileTitle}`,
            tagLabel: 'perfis',
          }}
          headerEndContent={
            <Button
              size="large"
              variant={'primary'}
              onClick={() => setShowProfileRegister(true)}
            >
              Adicionar integrantes <Icon name="IconPlus" />
            </Button>
          }
          search={{
            placeholder: 'Busca por pessoas atribuídas',
          }}
          columns={columns}
          rows={profilesWithEmployees}
          renderActions={({ item: profileItem }) => (
            <ProfileTableActions
              profile={profileItem}
              onRefresh={() => {
                utils.getCompanyProfiles.invalidate();
                utils.getProfileByType.invalidate({ type: currentProfileType });
              }}
            />
          )}
          isLoading={isLoadingCompanyProfiles}
          isError={isErrorCompanyProfiles}
        />
      </Box>
    </Box>
  );

  const tabItens: TabItens[] = profileTypes.map((type) => ({
    // eslint-disable-next-line react-hooks/rules-of-hooks
    label: useProfileTitle(type),
    component: renderTable(),
    counterLabel: companyProfiles?.[type]?.employees?.length || 0,
  }));

  return (
    <>
      <Tab
        tabItens={tabItens}
        onTabChanged={onTabChanged}
        variant="secondary"
      />
      {showProfileRegister && (
        <RegisterProfile
          profileType={currentProfileType}
          onSave={() => {
            setShowProfileRegister(false);
            utils.getCompanyProfiles.invalidate();
            utils.getProfileByType.invalidate({ type: currentProfileType });
          }}
          onCancel={() => setShowProfileRegister(false)}
        />
      )}
    </>
  );
}
