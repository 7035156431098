import axios from 'axios';

export const HttpService = {
  async post<T>(
    url: string,
    headers?: { [key: string]: string },
    data?: object,
  ): Promise<T> {
    const response = await axios.post<T>(url, data, {
      headers: { 'Content-Type': 'application/json', ...headers },
    });
    return response.data;
  },

  async get<T>(url: string, headers?: { [key: string]: string }): Promise<T> {
    const response = await axios.get<T>(url, {
      headers: { 'Content-Type': 'application/json', ...headers },
    });
    return response.data;
  },

  async patch<T>(
    url: string,
    headers?: { [key: string]: string },
    data?: object,
  ): Promise<T> {
    const response = await axios.patch<T>(url, data, {
      headers: { 'Content-Type': 'application/json', ...headers },
    });
    return response.data;
  },
};
