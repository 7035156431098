import { Icon } from '@components/Pills/styled';
import { Button, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

type Props = {
  onClick: () => void;
  isMaximumReached: boolean;
};

export const AddCustomFieldButton = (props: Props) => {
  const ButtonElement = (
    <Button
      size="large"
      variant={'primary'}
      onClick={() => props.onClick()}
      disabled={props.isMaximumReached}
    >
      Adicionar campo customizado <Icon name="IconPlus" />
    </Button>
  );

  return (
    <>
      {props.isMaximumReached ? (
        <div style={{ width: 310 }}>
          <Tooltip
            arrow
            title="Você só pode criar até 10 campos customizados para a empresa"
            placement="top"
          >
            <div>{ButtonElement}</div>
          </Tooltip>
        </div>
      ) : (
        ButtonElement
      )}
    </>
  );
};
