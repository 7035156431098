import {
  TravelTableColumn,
  TravelTableFilter,
} from '@components/TravelDataTable/types';
import { DateRangeField } from '@flash-tecnologia/hros-web-ui-v2';
import { SalesIntegrationListResponse } from 'bff/src/routers/travel-backoffice/schemas';
import { format } from 'date-fns';

export type SalesIntegration = SalesIntegrationListResponse[number];
export function useSalesIntegrationColumns(): TravelTableColumn<SalesIntegration>[] {
  return [
    {
      accessorKey: 'company',
      header: 'Empresa',
    },
    {
      accessorKey: 'internalSaleId',
      header: 'Venda interna',
    },
    {
      accessorKey: 'externalSaleId',
      header: 'Venda externa',
    },
    {
      accessorKey: 'type',
      header: 'Tipo de venda',
      cell: ({ row }) => {
        switch (row.original.type) {
          case 'TICKET':
            return 'Bilhete';
          case 'SERVICE':
            return 'Serviço';
          default:
            return '';
        }
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Última atualização',
      cell: ({ row }) =>
        format(new Date(row.original.createdAt), 'dd/MM/yyyy HH:mm'),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 100,
      cell: ({ row }) => {
        switch (row.original.status) {
          case 'ERROR':
            return 'Erro';
          case 'PENDING':
            return 'Pendente';
          case 'SUCCESS':
            return 'Replicado';
          default:
            break;
        }
      },
    },
  ];
}

export type DateRange = { from: Date; to: Date };
export function useSalesIntegrationFilter(
  range: DateRange,
  setDateRange: (range: DateRange) => void,
): TravelTableFilter[] {
  return [
    {
      name: 'status',
      label: 'Status',
      options: [
        { label: 'Pendente', value: 'PENDING' },
        { label: 'Erro', value: 'ERROR' },
        { label: 'Sucesso', value: 'SUCCESS' },
      ],
    },
    <DateRangeField
      label="Período"
      fullWidth
      initialValue={range}
      onChange={(e) => {
        setDateRange({ from: e?.from, to: e?.to });
      }}
    />,
  ];
}
