import { trpc } from '@api/bff/client';
import { useMemo } from 'react';
import { CriteriaFieldsProps } from '../types';
import {
  ConditionFieldConfig,
  useDefaultFieldConfiguration,
} from './useDefaultFieldConfiguration';

type Props = Pick<
  CriteriaFieldsProps,
  'fields' | 'enableCompanyCustomFields' | 'enableUserCustomFields'
>;

export function useConditionFields({
  fields,
  enableCompanyCustomFields,
  enableUserCustomFields,
}: Props) {
  const defaultFieldConfig = useDefaultFieldConfiguration();

  const customFields = trpc.getCriteriaFields.useQuery({
    includeTravelCustomFields: enableCompanyCustomFields,
    includeUserCustomFields: enableUserCustomFields,
  });

  const conditionFields = useMemo<ConditionFieldConfig[]>(() => {
    const defaultFields = fields?.map((field) => ({
      ...defaultFieldConfig[field.type],
      key: field.value,
    }));

    return [
      ...(defaultFields || []),
      ...(customFields.data || []),
    ] as ConditionFieldConfig[];
  }, [defaultFieldConfig, customFields.data, fields]);

  const conditionsOptions = useMemo(() => {
    return conditionFields.map((field) => ({
      label: field.label,
      value: field.key,
    }));
  }, [conditionFields]);

  return {
    conditionsOptions,
    conditionFields,
    customFields,
  };
}
