import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import * as SC from './styled';

interface IProps {
  columns: {
    accessor: string;
    content: React.ReactNode;
  }[];
  rows: object[];
  gridIdentifier: string;
  onChange: (data) => void;
}

export function ReorderingGrid({
  columns,
  rows,
  gridIdentifier,
  onChange,
}: IProps) {
  const [currentRows, updateCurrentRows] = useState(rows);
  const [inMotion, setInMotion] = useState(false);

  function handleOnDragEnd(result) {
    setInMotion(false);
    if (!result.destination) return;

    const items = Array.from(currentRows);
    items.splice(
      result.destination.index,
      0,
      items.splice(result.source.index, 1)[0],
    );

    onChange(items);
    updateCurrentRows(items);
  }

  function onDragStart() {
    setInMotion(true);
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId={`reordering-grid-${gridIdentifier}`}>
        {(provider) => (
          <SC.Container>
            <table style={{ width: '100%' }}>
              <SC.TheadTable>
                <tr>
                  <SC.ThTable></SC.ThTable>
                  {columns.map(({ content }, index) => {
                    return (
                      <SC.ThTable key={`reordering-grid-key${index}`}>
                        <SC.DescriptionHeader variant="body3">
                          {content ?? ''}
                        </SC.DescriptionHeader>
                      </SC.ThTable>
                    );
                  })}
                </tr>
              </SC.TheadTable>

              <tbody {...provider.droppableProps} ref={provider.innerRef}>
                {currentRows.map((row, index) => (
                  <Draggable
                    key={`reordering-grid-draggable-key${index}`}
                    draggableId={`${index}-${
                      row['id'] ?? Object.keys(row)?.[0]
                    }`}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        key={`reordering-grid-tr-key${index}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SC.TdTable>
                          <SC.IconGripVertical
                            name="IconGripVertical"
                            size={20}
                          />
                        </SC.TdTable>

                        {columns.map(({ accessor }, index) => {
                          return (
                            <SC.TdTable key={`reordering-grid-tb-key${index}`}>
                              <SC.DescriptionBody variant="body3">
                                {row[accessor] ?? ''}
                              </SC.DescriptionBody>
                            </SC.TdTable>
                          );
                        })}
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
            </table>
            {inMotion && <SC.EmptySpace />}
          </SC.Container>
        )}
      </Droppable>
    </DragDropContext>
  );
}
