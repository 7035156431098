import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

var PopupTemplate = {
  main: ({
    children,
    title,
    exonStyle,

    okButtonLabel,
    disabledOkButton,
    cancelButtonLabel,

    okButtonClick,
    cancelButtonClick,
    closeButtonClick,
  }) => (
    <div
      id="modal-form"
      className={
        'modal exon-adm-modal fade in ' + (exonStyle ? 'exon-popup' : '')
      }
      aria-hidden="false"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div
        className={
          'modal-dialog exon-adm-modal-dialog ' +
          (exonStyle ? 'exon-popup-dialog' : '')
        }
      >
        <div className="modal-content exon-adm-modal-content exon-popup-content">
          <div className="modal-header exon-popup-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeButtonClick}
            >
              <span>x</span>
            </button>
            <Typography
              className={
                'expenseon-green bigger modal-title-text exon-popup-title'
              }
              variant="headline8"
            >
              {title}
            </Typography>
          </div>

          <PopupTemplate.contents children={children} />

          <div
            className={
              'modal-footer exon-adm-modal-footer exon-popup-footer' +
              (okButtonLabel || cancelButtonLabel ? '' : ' hide')
            }
          >
            <button
              type="button"
              className={
                'btn btn-sm btn-round btn-primary Form-ok' +
                (okButtonLabel ? '' : ' hide')
              }
              onClick={okButtonClick}
              disabled={disabledOkButton}
            >
              <i className="icon-ok ace-icon fa fa-check"></i>
              <span className="label-ok">{okButtonLabel}</span>
            </button>

            <button
              type="button"
              className={
                'btn btn-sm btn-round Form-cancel' +
                (cancelButtonLabel ? '' : ' hide')
              }
              data-dismiss="modal"
              onClick={cancelButtonClick}
            >
              <i className="icon-cancel ace-icon fa fa-times"></i>
              <span className="label-cancel">{cancelButtonLabel}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in modal-stack exon-adm-modal-backdrop"></div>
    </div>
  ),

  contents: ({ children }) => (
    <div className="modal-body exon-popup-body">{children}</div>
  ),
};

export default PopupTemplate;
