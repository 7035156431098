import { trpc } from '@api/bff/client';
import { dispatchToast } from '@shared/toast';
import { ReasonStatus } from 'bff/src/routers/reasons/enums';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ReasonRegisterFormData } from '../types';

type Props = {
  reasonId?: string;
  onSave?: () => void;
};

export function useReasonForm({ reasonId, onSave }: Props) {
  const reason = trpc.getReasonById.useQuery(
    { id: reasonId },
    {
      enabled: !!reasonId,
      refetchOnWindowFocus: false,
    },
  );

  const saveReason = trpc.saveReason.useMutation();

  const reasonForm = useForm<ReasonRegisterFormData>({
    defaultValues: {
      code: '',
      name: '',
      requireDetails: false,
      status: ReasonStatus.ACTIVE,
    },
  });

  useEffect(() => {
    if (reason.data) {
      reasonForm.reset(reason.data);
    }
  }, [reason.data]);

  async function onSubmit(form: ReasonRegisterFormData) {
    try {
      await saveReason.mutateAsync({ id: reasonId, ...form });

      dispatchToast({
        type: 'success',
        content: `O motivo de viagem foi salvo com sucesso.`,
      });

      return onSave?.();
    } catch (err) {
      dispatchToast({
        type: 'error',
        content:
          'Ocorreu um erro ao salvar o motivo de viagem. Tente novamente.',
      });
    }
  }

  return {
    isLoading: !!reasonId && reason.isFetching,
    reason,
    reasonForm,
    onSubmit,
  };
}
