import { FieldFactory } from '@components/FieldFactory';
import { FieldFactoryType } from '@components/FieldFactory/types';
import { Control, useWatch } from 'react-hook-form';
import { CustomFieldRegisterForm } from '../../types';

type Props = {
  control: Control<CustomFieldRegisterForm>;
};

const CustomFieldPreview = ({ control }: Props) => {
  const values = useWatch({ control });

  return (
    <FieldFactory
      name={values.name}
      options={
        values.options?.map(({ value }) => ({
          label: value as string,
          value: value as string,
        })) || []
      }
      type={(values?.type || 'text').toLowerCase() as FieldFactoryType}
      required={values.required}
    />
  );
};

export default CustomFieldPreview;
