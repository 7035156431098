import React, { useState, useEffect } from 'react';
import * as SC from './styled';
import { useTracking } from '@shared/hooks/useTracking';
import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  TRACKING_DISCLAIMER_CLICK,
  TRACKING_DISCLAIMER_PAGE,
} from '@shared/constants/Tracking.constants';
import contentData from './content.json';
import parse, {
  HTMLReactParserOptions,
  Element,
  domToReact,
  DOMNode,
} from 'html-react-parser';

export default function NewFunctionsFlash() {
  const [currentInfoIndex, setCurrentInfoIndex] = useState(0);
  const { trackEvent, trackPage } = useTracking();
  const infoNewFunctions = contentData.infos;

  useEffect(() => {
    trackPage({
      name: TRACKING_DISCLAIMER_PAGE,
    });
  }, [trackPage]);

  useEffect(() => {
    const paginaAtual = window.location.pathname;
    if (
      paginaAtual === '/travel/sale' ||
      paginaAtual === '/travel/list' ||
      paginaAtual === '/travel/approvals'
    ) {
      trackEvent({
        name: `Carregamento da Página: ${paginaAtual}`,
      });
    }
  }, [trackEvent]);

  const handleUrlClick = (url: string) => {
    if (!url) return;

    trackEvent({
      name: TRACKING_DISCLAIMER_CLICK,
      params: { url },
    });
    window.open(url, '_blank');
  };

  useEffect(() => {
    const fetchIndex = async () => {
      const index = parseInt(localStorage.getItem('currentInfoIndex'), 10) || 0;
      setCurrentInfoIndex(index);
      const nextIndex = (index + 1) % infoNewFunctions.length;
      localStorage.setItem('currentInfoIndex', nextIndex.toString());
    };

    fetchIndex();
  }, [infoNewFunctions.length]);

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.name === 'a' &&
        'attribs' in domNode
      ) {
        const url = domNode.attribs.href;
        return (
          <SC.Link
            onClick={(e) => {
              e.preventDefault();
              handleUrlClick(url);
            }}
          >
            {domToReact(domNode.children as DOMNode[], options)}
          </SC.Link>
        );
      }
    },
  };

  return (
    <SC.NewFunctionsContanier>
      <SC.Icon>
        <ShapeIcon
          data-testid="IconSparkles"
          name="IconSparkles"
          size={45}
          color="#fb2a81"
          variant="default"
        />
      </SC.Icon>
      <div key={currentInfoIndex}>
        <SC.TitleNewsFunctions>
          <Typography variant="body3" weight={500}>
            Novidade:
          </Typography>
        </SC.TitleNewsFunctions>
        <Typography variant="body4" weight={500}>
          <b>{infoNewFunctions[currentInfoIndex].title}</b>{' '}
          {parse(infoNewFunctions[currentInfoIndex].content, options)}
        </Typography>
      </div>
    </SC.NewFunctionsContanier>
  );
}
