import * as Yup from 'yup';

const findDuplicates = (arr) =>
  arr.filter((item, index) => arr.indexOf(item) !== index);

const paymentFormsSchema = Yup.object().optional();
export function travelFormSchema(
  isAgency,
  isDescriptionReasonRequired,
  hasCostCenter,
  enableTravelersLimit,
) {
  return Yup.object().shape({
    number_travelers: Yup.number()
      .required('Escolha a quantidade de viajantes')
      .min(
        enableTravelersLimit ? 1 : 1,
        'O número de viajantes deve ser pelo menos 1',
      ) // Ajusta o mínimo conforme a flag
      .max(
        enableTravelersLimit ? 1 : 7,
        'O número de viajantes deve ser no máximo 7',
      ), // Ajusta o máximo conforme a flag
    travelers: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('O nome do viajante é obrigatório'),
          socialName: Yup.boolean().optional(),
          travelerInfo: Yup.object().optional(),
        }),
      )
      .required('Informações dos viajantes são obrigatórias')
      .test(
        'nome-unico',
        'Não podem haver viajantes duplicados',
        (travelers) => {
          if (!travelers) return true;
          const names = travelers.map((viajante) => viajante.name);
          const duplicates = findDuplicates(names);
          return duplicates.length === 0;
        },
      ),
    reason_travel: Yup.object()
      .shape({
        value: Yup.number().required('O valor é obrigatório'),
        label: Yup.string().required('O label é obrigatório'),
        reason: Yup.object()
          .shape({
            Id: Yup.number().required('O Id do motivo é obrigatório'),
            Name: Yup.string().required('O nome do motivo é obrigatório'),
            IsDescriptionRequired: Yup.boolean().required(
              'É necessário informar se a descrição é obrigatória',
            ),
          })
          .required('O motivo é obrigatório'),
      })
      .required('O campo motivo é obrigatório'),
    description_reason_travel: isDescriptionReasonRequired
      ? Yup.string().required('O campo de descrição do motivo é obrigatório')
      : Yup.object().notRequired(),
    cost_center: hasCostCenter
      ? Yup.object()
          .shape({
            value: Yup.number().required('O valor é obrigatório'),
            label: Yup.string().required('O label é obrigatório'),
            code: Yup.string().required('O code é obrigatório'),
          })
          .required('O campo centro de custo é obrigatório')
      : Yup.object().notRequired(),
    paymentForms: paymentFormsSchema,
    campany: Yup.object().optional(),
    requester: isAgency
      ? Yup.object({
          name: Yup.string().required('O name é obrigatório'),
          userId: Yup.number().required('O userId é obrigatório'),
        }).required('O solicitante é obrigatório')
      : Yup.object().notRequired(),
  });
}

export default travelFormSchema;
