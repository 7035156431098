import { Icons, IconTypes, Menu } from '@flash-tecnologia/hros-web-ui-v2';
import { useGenerateFile } from '@shared/hooks/useGenerateFile';
import { SalesIntegration } from './hooks/useSalesIntegrationTable';
import { useSalesReplicationAudit } from './hooks/useSalesReplicationAudit';
import { Container, IconDots, StyledText } from './styled';

export type ActionProps = {
  icon: IconTypes;
  description: string;
  onclick(): void;
};

export type SalesGridActionProps = {
  item: SalesIntegration;
};
export const SalesGridActions = ({ item }: SalesGridActionProps) => {
  const { downloadFile } = useSalesReplicationAudit(item.internalSaleId);
  const download = useGenerateFile();
  const actions: ActionProps[] = [
    {
      onclick: () => {
        download(item.sale, 'Venda');
      },
      icon: 'IconCloudDownload',
      description: 'Baixar Venda',
    },
    {
      onclick: downloadFile,
      icon: 'IconCloudDownload',
      description: 'Baixar xml integração externa',
    },
  ];

  return (
    <Menu
      type="default"
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      options={actions.map((action, i) => {
        return {
          onClick: action.onclick,
          children: (
            <Container key={i}>
              <Icons name={action.icon} fill="transparent" />
              <StyledText variant="body4">{action.description}</StyledText>
            </Container>
          ),
        };
      })}
    >
      <IconDots />
    </Menu>
  );
};
