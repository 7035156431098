import React from 'react';
import { Box } from '@mui/material';
import {
  GenericProfilePicture,
  Typography,
  Tag,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Employee } from '../../pages/TravelConfiguration/Profiles/types';

interface EmployeeInformationProps {
  employee: Employee;
  showProfileTag?: boolean;
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return `${text.slice(0, maxLength)}...`;
};

export const EmployeeInformation: React.FC<EmployeeInformationProps> = ({
  employee,
  showProfileTag = false,
}) => {
  return (
    <Box flexGrow={1}>
      <Typography variant="headline9">
        <Box display="flex" alignItems="center" gap={2}>
          <GenericProfilePicture name={employee.name} size={40} />
          <Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Box color={'#6B5B66'}>{employee.name}</Box>
              <Box>
                {showProfileTag && employee.currentProfile && (
                  <Tag variant={'disabled'} size={'extra-small'}>
                    {employee.currentProfile.label}
                  </Tag>
                )}
              </Box>
            </Box>
            <Typography variant="body3">
              {truncateText(employee.email, 34)}
            </Typography>
          </Box>
        </Box>
      </Typography>
    </Box>
  );
};
