import React, { useState, useCallback } from 'react';
import { LinkButton, Modal, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import { ReorderingGrid } from '../ReorderingGrid/ReorderingGrid';
import { useApprovalFlows } from '../../hooks/useApprovalFlows';
import { dispatchToast } from '@shared/toast';
import { trpc } from '@api/bff/client';
import { ISummaryApprovalFlow } from '../../../types';
import { useLoading } from '../../../../../../contexts/LoadingContext';

export function ModalReorderingList() {
  const { showLoading, hideLoading } = useLoading();
  const [approvalAdvances, setApprovalAdvances] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { approvalItems } = useApprovalFlows();

  const updateApprovalFlowPriority =
    trpc.updateApprovalFlowPriority.useMutation();

  const fetchApprovalAdvances = useCallback(async () => {
    setIsLoading(true);
    try {
      showLoading();
      setApprovalAdvances(approvalItems);
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
      setIsLoading(false);
    }
  }, [approvalItems, showLoading, hideLoading]);

  const handleChange = useCallback((newRows: ISummaryApprovalFlow[]) => {
    setApprovalAdvances(
      newRows.map((row, index) => ({
        ...row,
        priority: index + 1,
      })),
    );
  }, []);

  const handleOpenModal = useCallback(async () => {
    await fetchApprovalAdvances();
    setModalOpen(true);
  }, [fetchApprovalAdvances]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      setModalOpen(false);
      const priorityArray = approvalAdvances.map((advance) => advance.id);
      await updateApprovalFlowPriority.mutateAsync({
        priority: priorityArray,
      });
    } catch (erro) {
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao salvar a prioridade. Tente novamente',
      });
    }
  }, [approvalAdvances, updateApprovalFlowPriority]);

  const columns = [
    { accessor: 'priority', content: 'Prioridade' },
    { accessor: 'name', content: 'Nome' },
    { accessor: 'type', content: 'Tipo' },
  ];

  return (
    <div>
      <LinkButton onClick={handleOpenModal} variant={'default'}>
        Reordenar fluxo
      </LinkButton>
      <Modal.Root open={isModalOpen} onClose={handleCloseModal} size="md">
        <>
          <Modal.Header title="Lista de Fluxos de Aprovação" />
          <Modal.Content>
            {isLoading ? (
              <Spinner size={24} />
            ) : (
              <ReorderingGrid
                gridIdentifier="approval-flow-custom"
                columns={columns}
                rows={approvalAdvances}
                onChange={handleChange}
              />
            )}
          </Modal.Content>
          <Modal.Footer
            cancelText="Fechar"
            onCancel={handleCloseModal}
            onConfirm={handleConfirm}
            confirmText="Confirmar"
          />
        </>
      </Modal.Root>
    </div>
  );
}
