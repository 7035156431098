import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { ArrayPath, useFieldArray, useWatch } from 'react-hook-form';
import ConditionRow from './components/ConditionRow';
import { useConditionFields } from './hooks/useConditionFields';
import { CriteriaFieldsProps, CriteriaFormValues } from './types';

const CriteriaFields = <T,>({ control, ...props }: CriteriaFieldsProps<T>) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: props.name as ArrayPath<T>,
  });

  const { conditionsOptions, conditionFields } = useConditionFields(props);

  const watchCriteria = useWatch({
    name: props.name,
    control,
  });

  const addedConditionFields = (watchCriteria as Array<CriteriaFormValues>).map(
    (item) => item.field,
  );

  return (
    <Box>
      {fields.map((field, index) => (
        <ConditionRow
          key={field.id}
          name={`${props.name}.${index}`}
          index={index}
          fixed={props.static}
          conditionFieldOptions={conditionsOptions}
          conditionFieldsConfig={conditionFields}
          addedConditionFields={addedConditionFields}
          avoidRepeatFields={props.avoidRepeatFields}
          formKeys={props.formKeys}
          onRemove={() => remove(index)}
        />
      ))}

      <LinkButton
        variant={'default'}
        onClick={() =>
          append({
            condition: props.static?.condition || undefined,
            operator: props.static?.operator || undefined,
          } as any)
        }
      >
        Adicionar novo critério
      </LinkButton>
    </Box>
  );
};

export default CriteriaFields;
