'use strict';

import React from 'react';
import SearchTemplate from './search.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';

export default class DashboardSearchComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      forceValidation: false,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <SearchTemplate.main
          showPopup={this.state.showPopup}
          companiesRequestSearcherProps={
            this.props.companiesRequestSearcherProps
          }
          requestSearcher={this.props.requestSearcher}
          events={this.events}
          onRequestNumberChange={this.props.onRequestNumberChange}
          forceValidation={this.state.forceValidation}
          onSearchTypeChange={this.props.onSearchTypeChange} // passar diretamente
        />
      </ErrorBoundary>
    );
  }

  events = {
    onSearchByNumberClick: () => {
      if (!this.props.isAgency) this.props.onSeachByNumberClick();

      if (this.props.isAgency && this.state.showPopup) {
        if (this.validateFild()) {
          this.props.onSeachByNumberClick();
          this.setState({ showPopup: false });
        }
      }

      if (this.props.isAgency && !this.state.showPopup)
        this.setState({ showPopup: true });
    },

    onRequestKeyPress: (e) => {
      if (event.charCode === 13) {
        if (!this.props.isAgency) this.props.onRequestKeyPress(e);

        if (this.props.isAgency && this.state.showPopup) {
          if (this.validateFild()) {
            this.props.onRequestKeyPress(e);
            this.setState({ showPopup: false });
          }
        }

        if (this.props.isAgency && !this.state.showPopup)
          this.setState({ showPopup: true });
      }
    },

    onClosePopupclicked: () => {
      this.setState({ showPopup: false });
    },

    onSearchTypeChange: (value) => {
      if (this.props.onSearchTypeChange) {
        this.props.onSearchTypeChange(value);
      }
    },
  };

  validateFild() {
    if (this.props.companiesRequestSearcherProps.data.value == 0) {
      this.setState({ forceValidation: true });
      return false;
    }
    return true;
  }
}
