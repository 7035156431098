import { FieldFactoryConfiguration } from '@components/FieldFactory/types';
import { SavePolicyRequest } from 'bff/src/routers/policies';

export enum PolicyRegisterFormStep {
  BASIC_INFORMATION = 0,
  POLICY_PROPERTIES = 1,
  RESUME = 2,
}

export type PolicyRegisterForm = SavePolicyRequest & {
  document: {
    file?: File;
  };
};

export type PolicyRegisterBasicInformation = Pick<
  PolicyRegisterForm,
  'description' | 'name' | 'document'
>;

export type PolicyRegisterProperties = Pick<PolicyRegisterForm, 'conditions'>;

export type PolicyRuleConfigurationField = FieldFactoryConfiguration & {
  key: keyof PolicyRegisterForm['rules'][number]['parameters'][number];
};

export type PolicyRuleConfiguration = {
  code: string;
  description: string;
  fields: Array<PolicyRuleConfigurationField>;
};
