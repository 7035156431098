import { Box } from '@mui/material';
import { Section } from '@components/Section';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';

export function PreferencesSkeleton() {
  return (
    <Box width="100%">
      <Section
        title="Configurações Gerais"
        caption="Edite as configurações gerais que interferem nas viagens corporativas"
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Box>
            <Skeleton
              variant="text"
              width={100}
              height={20}
              style={{ marginBottom: '8px' }}
            />
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <Skeleton variant="rectangular" width={34} height={14} />
            <Skeleton variant="text" width={200} height={20} />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Skeleton
              variant="rectangular"
              width={120}
              height={36}
              style={{ borderRadius: '4px' }}
            />
          </Box>
        </Box>
      </Section>
    </Box>
  );
}
