import { trpc } from '@api/bff/client';
import { TableStatusToggle } from '@components/TravelDataTable/components/TableStatusToggle';
import { JustificationStatus } from 'bff/src/routers/justifications/enums';

type Props = {
  justification: { id?: string; status?: JustificationStatus };
};

export const ToggleJustificationStatus = ({ justification }: Props) => {
  const updateJustificationStatus =
    trpc.updateJustificationStatus.useMutation();

  return (
    <TableStatusToggle
      defaultChecked={justification.status === JustificationStatus.ACTIVE}
      isLoading={updateJustificationStatus.isLoading}
      onToggleStatus={(value) =>
        updateJustificationStatus.mutateAsync({
          id: justification.id,
          status: value
            ? JustificationStatus.ACTIVE
            : JustificationStatus.INACTIVE,
        })
      }
    />
  );
};
