import {
  findTravelers,
  findLocations,
  insertTraveler,
  updateTraveler,
  getLocation,
  findCostCenter,
  getTravelReasons,
  getCustomFields,
  getPaymentForms,
  getPurchaseConfiguration,
  getTravelJustifications,
  findRequesters,
  getUserProfileData,
  getCompanySettings,
} from '../../api/connectOn/connectOn.service';

import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import { travelerResolver } from '@legacy-utils/resolvers/travelerResolver';
import { paymentResolver } from '@legacy-utils/resolvers/paymentResolver';
import { justificationResolver } from '@legacy-utils/resolvers/justificationResolver';
import { UserProfileData } from '@shared/types';
import { ILoader } from '@shared/loader';
import { messageUtils } from './utils';

const captalizeResponseObject = (obj) => {
  if (obj && Array.isArray(obj)) {
    obj.forEach((_) => {
      if (typeof _ == 'object') captalizeResponseObject(_);
    });
  } else {
    Object.keys(obj).map((key) => {
      const captalized = key.charAt(0).toUpperCase() + key.slice(1);
      if (captalized !== key) {
        if (typeof obj[key] == 'object') captalizeResponseObject(obj[key]);

        obj[captalized] = obj[key];
        delete obj[key];
      }
    });
  }
};

let _loader: ILoader;
export const setLoader = (loader: ILoader) => {
  _loader = loader;
};
const toggleModalLoading = (show: boolean) => {
  _loader.toggleLoading(show);
};

export const dataSources = {
  travelers: {
    load: async (loadOptions) => {
      try {
        const response = await findTravelers(loadOptions);

        const listItems = [];
        response.travelers.forEach((apiTraveler) => {
          captalizeResponseObject(apiTraveler);

          const traveler =
            travelerResolver.convertApiTravelerToComponent(apiTraveler);

          const listItem = listItemsResolver.resolveItem(
            traveler,
            'reference',
            'label',
          );
          listItem.traveler = traveler;

          listItems.push(listItem);
        });

        return listItems;
      } catch (error) {
        messageUtils.showError('Ocorreu um erro ao obter os viajantes.', error);
      }
    },
    insert: async (traveler, contextId) => {
      try {
        const request = travelerResolver.buildInsertTravelerRequest(traveler);
        const response = await insertTraveler(request, contextId);

        captalizeResponseObject(response.traveler);
        return travelerResolver.convertApiTravelerToComponent(
          response.traveler,
        );
      } catch (error) {
        console.error(error);
      }
    },
    update: async (traveler, contextId) => {
      try {
        const request = travelerResolver.buildInsertTravelerRequest(traveler);
        const response = await updateTraveler(request, contextId);

        captalizeResponseObject(response.traveler);
        return travelerResolver.convertApiTravelerToComponent(
          response.traveler,
        );
      } catch (error) {
        console.error(error);
      }
    },
    events: {
      beforeLoad: () => {},
      afterLoad: () => {},
      beforeInsert: () => {
        toggleModalLoading(true);
      },
      afterInsert: () => {
        toggleModalLoading(false);
      },
      beforeUpdate: () => {
        toggleModalLoading(true);
      },
      afterUpdate: () => {
        toggleModalLoading(false);
      },
    },
  },
  nationality: {
    load: (loadOptions) => {
      try {
        return findLocations({
          keyword: loadOptions.keywords,
          types: [2],
          scopes: [0],
        });
      } catch (error) {
        messageUtils.showError(
          'Ocorreu um erro ao obter a lista de nacionalidades.',
          error,
        );
      }
    },
    get: (id) => {
      return getLocation(id);
    },
    events: {
      beforeLoad: () => {},
      afterLoad: () => {},
    },
  },
  costCenter: {
    load: async (loadOptions) => {
      try {
        const response = await findCostCenter(
          loadOptions.keywords,
          loadOptions.contextId,
        );

        const costCenters = response.costCenters.map((_) => {
          return {
            Id: _.id,
            Reference: _.code,
            Description: _.name,
          };
        });

        const listItems = [];
        costCenters.forEach((cc) => {
          const listItem = listItemsResolver.resolveItem(
            cc,
            'Id',
            'Description',
          );
          listItem.label += ' (' + cc.Reference + ')';
          listItem.code = cc.Reference;

          listItems.push(listItem);
        });

        return listItems;
      } catch (error) {
        messageUtils.showError('Erro ao obter os centros de custo.', error);
      }
    },
  },
  travelReason: {
    load: async (loadOptions) => {
      try {
        const response = await getTravelReasons(loadOptions.contextId);
        captalizeResponseObject(response.reasons);

        const listItems = [];
        response.reasons.forEach((reason) => {
          const listItem = listItemsResolver.resolveItem(reason, 'Id', 'Name');
          listItem.reason = reason;

          listItems.push(listItem);
        });

        return listItems;
      } catch (error) {
        messageUtils.showError('Erro ao obter os motivos de viagem. ', error);
      }
    },
  },
  customField: {
    load: async (loadOptions) => {
      try {
        const response = await getCustomFields(loadOptions.contextId);
        captalizeResponseObject(response.fields);

        return response.fields;
      } catch (error) {
        messageUtils.showError(
          'Erro ao obter os campos personalizados. ',
          error,
        );
      }
    },
    events: {
      beforeLoad: () => {
        toggleModalLoading(true);
      },
      afterLoad: (loadOptions) => {
        toggleModalLoading(false);
      },
    },
  },
  paymentForms: {
    load: async (loadOptions) => {
      try {
        const response = await getPaymentForms(loadOptions);
        captalizeResponseObject(response.paymentForms);

        const listItems = paymentResolver.getPaymentFormsListItems(
          response.paymentForms,
        );
        return listItems;
      } catch (error) {
        messageUtils.showError('Erro ao obter os tipos de pagamentos.', error);
      }
    },
    loadAllowedPaymentTypes: async (contextId) => {
      const response = await getPurchaseConfiguration(contextId);
      captalizeResponseObject(response.purchaseConfigurations);

      return response.purchaseConfigurations;
    },
    events: {
      beforeLoad: () => {
        toggleModalLoading(true);
      },
      afterLoad: (loadOptions) => {
        toggleModalLoading(false);
      },
    },
  },
  justificationsList: {
    load: async (loadOptions) => {
      const response = await getTravelJustifications(loadOptions);
      captalizeResponseObject(response.justifications);

      const listItems = justificationResolver.resolve(response.justifications);

      return listItems;
    },
  },
  companies: {
    load: async () => Promise.resolve(null),
    events: {
      beforeLoad: () => {
        toggleModalLoading(true);
      },
      afterLoad: () => {
        toggleModalLoading(false);
      },
    },
  },
  companySettings: {
    load: async (contextId: string) => {
      return getCompanySettings(contextId);
    },
  },
  requesters: {
    load: async (keywords, contextId) => {
      const response = await findRequesters(keywords, contextId);
      return response.requesters.map((_: any) => {
        return {
          RequesterId: _.userId,
          Email: _.email,
          Name: _.name,
        };
      });
    },
    events: {
      beforeLoad: () => {
        toggleModalLoading(true);
      },
      afterLoad: () => {
        toggleModalLoading(false);
      },
    },
  },
};

export const resolveCompanies = (userProfile: UserProfileData) => {
  const contexts = userProfile.accessContexts.filter(
    (_) =>
      !_.subsidiaryId &&
      (!userProfile.company.isAgency || _.id != userProfile.company.contextId),
  );
  return Promise.resolve(
    listItemsResolver.resolve(contexts, 'id', 'companyName'),
  );
};

export const getUserProfile = () => {
  return getUserProfileData();
};
