import { Tag, Dot } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';

type Props = {
  employeeStatus: 'ACTIVE' | 'INACTIVE';
};

export const TagProfileStatus = ({ employeeStatus }: Props) => {
  const isActive = employeeStatus === 'ACTIVE';

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      alignItems="center"
      width={75}
    >
      <Tag variant={isActive ? 'green' : 'primary'}>
        <Dot variant={isActive ? 'green' : 'primary'} />{' '}
        {isActive ? 'Ativo' : 'Inativo'}
      </Tag>
    </Box>
  );
};
