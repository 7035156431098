import { trpc } from '@api/bff/client';
import { TableBaseActions } from '@components/TravelDataTable';
import { dispatchToast } from '@shared/toast';
import { GetCustomFieldsResponse } from 'bff/src/routers/custom-fields';
import { useState } from 'react';

export type Props = {
  customField: GetCustomFieldsResponse['customFields'][number];
  onRefresh: () => Promise<unknown> | unknown;
  onEdit: () => void;
  disableDuplication: boolean;
};

const CustomFieldTableActions = ({
  customField,
  onRefresh,
  onEdit,
  disableDuplication,
}: Props) => {
  const duplicateCustomField = trpc.duplicateCustomField.useMutation();
  const deleteCustomField = trpc.deleteCustomField.useMutation();

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDuplicate() {
    try {
      setIsDuplicating(true);

      await duplicateCustomField.mutateAsync({ id: customField.id });

      await onRefresh?.();

      dispatchToast({
        type: 'success',
        content: `O campo customizado ${customField.name} foi duplicado`,
      });
    } catch (err) {
      onRefresh?.();

      dispatchToast({
        content:
          err.message ||
          `Erro ao duplicar o campo customizado. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDuplicating(false);
    }
  }

  async function handleDelete() {
    try {
      setIsDeleting(true);

      await deleteCustomField.mutateAsync({ id: customField.id });

      await onRefresh?.();

      dispatchToast({
        type: 'success',
        content: `O campo customizado ${customField.name} foi excluído com sucesso`,
      });
    } catch (err) {
      onRefresh?.();

      dispatchToast({
        content:
          err?.message ||
          `Erro ao excluir o campo customizado. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <TableBaseActions
      delete={{
        title: 'Excluir campo customizado',
        message:
          'Deseja realmente excluir esse campo customizado? Essa ação não poderá ser desfeita',
        status: 'error',
        primaryButtonLabel: 'Excluir',
        loading: isDeleting,
      }}
      duplicate={{
        loading: isDuplicating,
        disabled: disableDuplication,
      }}
      onDelete={handleDelete}
      onDuplicate={handleDuplicate}
      onEdit={onEdit}
      actions={['edit', 'delete', 'duplicate']}
    />
  );
};

export default CustomFieldTableActions;
