import {
  DatePicker,
  SelectField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { useTravelItemStore } from '../../../../store/RequestTravelItem.store';
import { useEffect } from 'react';
// eslint-disable-next-line no-duplicate-imports
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

interface AgencyReserveProps {
  control: Control;
  errors: FieldErrors;
}

export function AgencyReserve({ control }: AgencyReserveProps) {
  const carRentals = useTravelItemStore((state) => state.carRentals);
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    const systemAgencyReserve = getValues('system_agency_reserve');
    if (systemAgencyReserve) {
      setValue('vehicle.rentalCompany', systemAgencyReserve, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [getValues, setValue]);

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div style={{ width: '50%' }}>
          <Controller
            control={control}
            name="location_agency_reserve"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                {...({} as any)}
                style={{ width: '100%' }}
                onChange={onChange}
                label="Localizador"
                value={value}
                helperText={
                  <DisplayErrorFormValidation
                    errors={error}
                    fieldName={'location'}
                  />
                }
              />
            )}
          />
        </div>
        <div style={{ width: '50%' }}>
          <Controller
            control={control}
            name="system_agency_reserve"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectField
                fullWidth
                onSelectChange={(event, selectedItem) => {
                  onChange(selectedItem);
                  setValue('vehicle.rentalCompany', selectedItem, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                value={value}
                options={carRentals}
                label={'Sistema'}
                error={!!error}
                helperText={
                  <DisplayErrorFormValidation
                    errors={error}
                    fieldName="number_travelers"
                  />
                }
              />
            )}
          />
        </div>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div style={{ width: '50%' }}>
          <Controller
            name={'date_agency_reserve'}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                onDateChange={(date) => field.onChange(date)}
                label="Data de expiração"
                error={!!error}
                helperText={error ? error.message : null}
                disabledDate={(date) =>
                  dayjs(date).isBefore(dayjs().add(1, 'day'), 'day')
                }
              />
            )}
          />
        </div>
        <div style={{ width: '50%' }}>
          <Controller
            name={'time_agency_reserve'}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...(field as any)}
                type="time"
                fullWidth
                label="Hora de expiração"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
