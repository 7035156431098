import {
  Typography,
  Modal,
  Button,
  Icons,
} from '@flash-tecnologia/hros-web-ui-v2';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as SC from './styled';
import travelFormSchema from './validationFormSchema';
import { TravelersOffline } from '@components/RequestTravel/RequestTravelOffline/TravelersOffline';
import { TravelReason } from '@components/RequestTravel/RequestTravelOffline/TravelReason';
import { TravelCostCenter } from '@components/RequestTravel/RequestTravelOffline/TravelCostCenter';
import { TravelInfoPayment } from '@components/RequestTravel/RequestTravelOffline/TravelInfoPayment';
import { TravelSelectCompanie } from '@components/RequestTravel/RequestTravelOffline/TravelSelectCompanie';
import { TravelRequesters } from '@components/RequestTravel/RequestTravelOffline/TravelRequesters';
import { TravelersQuantity } from '@components/RequestTravel/RequestTravelOffline/TravelersQuantity';
import { convertDateStringToDateObject } from '@shared/utils/date.utils';
import { CustomFields } from '@components/RequestTravel/RequestTravelOffline/CustomFields';
import { saveServiceRequest } from '@api/connectOn/connectOn.service';
import { useLoading } from '../../../contexts/LoadingContext';
import { useTravelItemStore } from '../../../store/RequestTravelItem.store';
import { useNavigate } from 'react-router-dom';
import { useTravelStore } from '../../../store/RequestTravel.store';
import useModalStore from '../../../store/Modal.store';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';
import { Box } from '@mui/material';
import { FormStateDebug } from '@components/FormStateDebug';

interface RequestTravelOfflineProps {
  isListItem?: boolean;
  debug?: boolean;
}

export default function RequestTravelOffline({
  isListItem = false,
  debug,
}: RequestTravelOfflineProps) {
  const [isDescriptionReasonRequired, setIsDescriptionReasonRequired] =
    useState<boolean>(false);
  const { showLoading, hideLoading } = useLoading();
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const updateID = useTravelItemStore((state) => state.updateID);
  const setModalOpen = useTravelItemStore((state) => state.setModalOpen);
  const contextId = useTravelStore((state) => state.contextId);
  const isAgency = useTravelStore((state) => state.isAgency);
  const requester = useTravelStore((state) => state.requester);
  const hasCostCenter = useTravelStore((state) => state.hasCostCenter);
  const numberTravelers = useTravelStore((state) => state.numberTravelers);
  const updateIsModalRV = useTravelStore((state) => state.updateIsModalRV);
  const isModalRV = useTravelStore((state) => state.isModalRV);
  const updateTravelersQuantity = useTravelStore(
    (state) => state.updateTravelersQuantity,
  );
  const navigate = useNavigate();
  const { enableTravelersLimit } = useTravelPermissions();

  const handleOpenModal = () => {
    updateIsModalRV(!isModalRV);
  };

  const requestTravelForm = useForm({
    defaultValues: { number_travelers: numberTravelers },
    resolver: yupResolver(
      travelFormSchema(
        isAgency,
        isDescriptionReasonRequired,
        hasCostCenter,
        enableTravelersLimit,
      ),
    ),
  });

  useEffect(() => {
    if (enableTravelersLimit) {
      requestTravelForm.setValue('number_travelers', 1);
      updateTravelersQuantity(1); // Atualiza a store também
    }
  }, [
    enableTravelersLimit,
    requestTravelForm.setValue,
    updateTravelersQuantity,
  ]);

  const onCheckFormAndSubmit = async (data) => {
    const travelersInfo = data.travelers.map((traveler) => {
      const infos = traveler.travelerInfo as any;

      return {
        ...infos,
        ...infos?.name,
        birthDate: convertDateStringToDateObject(infos?.birthDate),
        gender: infos?.gender === 1 ? 'M' : 'F',
      };
    });

    const paymentForm = data.paymentForms.paymentForm;
    let creditCardData;

    if (paymentForm.creditCard.id) {
      // Cartão já adicionado
      creditCardData = {
        creditCard: {
          id: paymentForm.creditCard.id,
          companyId: paymentForm.companyId,
          number: paymentForm.creditCard.number,
          expireYear: paymentForm.creditCard.expireYear,
          expireMonth: paymentForm.creditCard.expireMonth,
          printedName: paymentForm.creditCard.holderName,
          cardName: paymentForm.creditCard.holderName,
          securityCode: paymentForm.creditCard.securityCode,
          provider: paymentForm.creditCard.provider,
        },
        paymentType: paymentForm.paymentType,
        storedCreditCard: paymentForm.storedCreditCard,
        productType: paymentForm.productType,
      };
    } else if (paymentForm.type === 1) {
      // Novo cartão
      creditCardData = {
        type: paymentForm.type, // 1: 'Cartão de Credito',
        creditCard: {
          number: paymentForm.creditCard.number,
          expireYear: paymentForm.creditCard.expireYear,
          expireMonth: paymentForm.creditCard.expireMonth,
          printedName: paymentForm.creditCard.printedName,
          cardName: paymentForm.creditCard.cardName,
          securityCode: paymentForm.creditCard.securityCode,
          provider: paymentForm.creditCard.provider,
        },
        productType: paymentForm.productType,
      };
    }

    const dataRequest = {
      serviceRequest: {
        action: null,
        travelers: travelersInfo,
        paymentForms: [creditCardData],
        policyViolationAlertsJustified: null,
        confirmationAlerts: null,
        costCenter: null,
        reason: {
          id: data.reason_travel.value,
          name: data.reason_travel.label,
          description: data.description_reason_travel || null,
        },
        customFieldValues: [],
        requester: requester,
      },
    };

    try {
      showLoading();
      const requestObj = {
        ...dataRequest,
        contextId: contextId,
      };
      const response = await saveServiceRequest(requestObj);

      openModal({
        title: `Requisição de viajem: [RV ${response.serviceRequest.id}] criada com sucesso!`,
        subTitle:
          'Agora você já pode pedir itens para serem reservados pela agência. É só clicar em ”Adicionar item” dentro da Requisição.',
        primaryButtonLabel: 'Adicionar Item',
        primaryButtonAction: () => {
          updateID(response.serviceRequest.id);
          closeModal();
          updateIsModalRV(false);
          setModalOpen(true);
        },
        secondaryButtonLabel: 'Ver requisição',
        secondaryButtonAction: () => {
          closeModal();
          updateIsModalRV(false);
          const queryStringParameters = `?requestId=${response.serviceRequest.id}`;
          navigate(`/travel/list${queryStringParameters}`);
        },
        status: 'success',
      });
      requestTravelForm.reset();
    } catch (e) {
      openModal({
        title: 'Ocorreu uma falha no cadastro de requisição de viagem!',
        subTitle: 'Estamos verificando o que pode ter ocorrido, aguarde!',
        primaryButtonLabel: 'Fechar',
        primaryButtonAction: () => {
          closeModal();
        },
        secondaryButtonLabel: '',
        status: 'error',
      });
    } finally {
      hideLoading();
    }
  };

  const renderButton = () => {
    if (isListItem) {
      return <Box onClick={handleOpenModal}>Requisição via formulário</Box>;
    } else {
      return (
        <Button size={'medium'} onClick={handleOpenModal} variant="secondary">
          Requisição via formulário <Icons name="Add" />
        </Button>
      );
    }
  };

  return (
    <Box>
      <Box>{renderButton()}</Box>
      <Modal.Root open={isModalRV} onClose={handleOpenModal} size={'md'}>
        <>
          <Modal.Header title={'Requisição via formulário'}></Modal.Header>
          <Modal.Content>
            <SC.RequestContainer>
              <FormProvider {...requestTravelForm}>
                <Box sx={{ paddingBottom: 2 }}>
                  <Typography variant={'body3'} weight={400}>
                    Crie uma requisição para solicitar à Agência serviços que
                    ainda não estão disponíveis para cotação dentro da
                    plataforma. No momento é possível solicitar o serviço de{' '}
                    <b>Aluguel de Carros.</b>
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  {isAgency && (
                    <>
                      <Box flexGrow={1}>
                        <TravelSelectCompanie />
                      </Box>
                      <Box flexGrow={1}>
                        <TravelRequesters />
                      </Box>
                    </>
                  )}
                  <Box flexGrow={1}>
                    <TravelersQuantity />
                  </Box>
                </Box>
                <Box>
                  <TravelersOffline />
                </Box>
                {numberTravelers >= 1 && (
                  <Box>
                    <TravelReason
                      handleIsDescription={(isDescription) =>
                        setIsDescriptionReasonRequired(isDescription)
                      }
                    />
                    {hasCostCenter && <TravelCostCenter />}
                    <TravelInfoPayment />
                    <CustomFields />
                  </Box>
                )}
                {debug && <FormStateDebug />}
              </FormProvider>
            </SC.RequestContainer>
          </Modal.Content>
          <Modal.Footer
            cancelText={'Cancelar'}
            onCancel={handleOpenModal}
            onConfirm={requestTravelForm.handleSubmit(onCheckFormAndSubmit)}
            confirmText={'Criar requisição'}
          ></Modal.Footer>
        </>
      </Modal.Root>
    </Box>
  );
}
