import { z } from 'zod';

// Base Schema
const preferencesSchema = z.object({
  travelCoordinatorId: z
    .string()
    .min(1, 'Responsável é obrigatório')
    .nullable(),
  sendReservationSummaryEmail: z.boolean().default(false),
});

// Response Schema
export const getPreferencesResponseSchema = preferencesSchema;
export const updatePreferencesRequestSchema = preferencesSchema;
export const updatePreferencesResponseSchema = preferencesSchema;

// Export types
export type GetPreferencesResponse = z.infer<
  typeof getPreferencesResponseSchema
>;
export type UpdatePreferencesRequest = z.infer<
  typeof updatePreferencesRequestSchema
>;
export type UpdatePreferencesResponse = z.infer<
  typeof updatePreferencesResponseSchema
>;

// Re-export para uso no cliente
export type PreferencesFormData = UpdatePreferencesRequest;
