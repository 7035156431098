import React from 'react';
import { FieldErrors, FieldError } from 'react-hook-form';
import * as SC from './styled';

interface DisplayErrorFormValidationProps {
  errors: FieldErrors | FieldError;
  fieldName: string;
}

export default function DisplayErrorFormValidation({
  errors,
  fieldName,
}: DisplayErrorFormValidationProps) {
  function getErrorMessages(errors, path) {
    const parts = path.split('.');
    let current = errors;
    for (const part of parts) {
      if (!current) return [];
      current = current[part];
    }

    if (!current) return [];

    // Se tiver subpropriedades com mensagens de erro
    if (current.name?.message || current.userId?.message) {
      return [current.name?.message, current.userId?.message].filter(Boolean); // Remove valores null/undefined
    }

    return current.message ? [current.message] : [];
  }

  const errorMessages = getErrorMessages(errors, fieldName);

  return (
    <>
      {errorMessages.map((message, index) => (
        <SC.DisplayErrorContainer key={index}>
          <span>{message}</span>
        </SC.DisplayErrorContainer>
      ))}
    </>
  );
}
