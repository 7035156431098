import { TravelProduct } from 'bff/src/routers/policies/enums';

export function getProductName(level: TravelProduct) {
  const levelMap: Record<TravelProduct, string> = {
    AIR: 'Aéreo',
    ALL: 'Todos',
    HOTEL: 'Hotel',
    VEHICLE: 'Veículo',
  };

  return levelMap[level];
}
