import { useState, useEffect } from 'react';
import { trpc } from '@api/bff/client';
import { TravelProfileType } from '../../types';

interface Employee {
  id: string;
  name: string;
  email: string;
  status:
    | 'ACTIVE'
    | 'INACTIVE'
    | 'INVITATION_SENT'
    | 'INVITATION_EXPIRED'
    | 'INVITATION_PENDING'
    | 'INVITATION_SCHEDULED';
}

interface TravelProfile {
  type: TravelProfileType;
  employees: Employee[];
}

interface UserOption {
  id: string;
  name: string;
  email: string;
  status: string;
}

interface CompanyProfile {
  count: number;
  employees: Employee[];
}

interface UseProfilesResult {
  profileData: TravelProfile | undefined;
  userOptions: UserOption[];
  companyProfiles: Record<TravelProfileType, CompanyProfile>;
  isLoading: boolean;
  isLoadingProfile: boolean;
  isLoadingUsers: boolean;
  isLoadingCompanyProfiles: boolean;
  isErrorProfile: boolean;
  isErrorUsers: boolean;
  isErrorCompanyProfiles: boolean;
  errorProfile: any;
  errorUsers: any;
  errorCompanyProfiles: any;
  refetchProfile: () => void;
  refetchUsers: () => void;
  refetchCompanyProfiles: () => void;
  utils: {
    changeProfileEmployees: {
      mutate: (data: {
        type: TravelProfileType;
        employeeIds: string[];
      }) => Promise<void>;
      mutateAsync: (data: {
        type: TravelProfileType;
        employeeIds: string[];
      }) => Promise<void>;
    };
    getCompanyProfiles: {
      invalidate: () => Promise<void>;
    };
    getProfileByType: {
      invalidate: (filters: { type: TravelProfileType }) => Promise<void>;
    };
  };
}

const defaultCompanyProfiles: Record<TravelProfileType, CompanyProfile> = {
  [TravelProfileType.BASIC]: { count: 0, employees: [] },
  [TravelProfileType.REQUESTER]: { count: 0, employees: [] },
  [TravelProfileType.FULL]: { count: 0, employees: [] },
  [TravelProfileType.APPROVER]: { count: 0, employees: [] },
};

export function useProfiles(type?: TravelProfileType): UseProfilesResult {
  // Obtendo o utils do tRPC
  const trpcUtils = trpc.useUtils();

  // Usando a nova API do tRPC para mutations
  const changeProfileEmployeesMutation =
    trpc.changeProfileEmployees.useMutation({
      onSuccess: () => {
        // Invalidando caches após mutação bem-sucedida
        trpcUtils.getCompanyProfiles.invalidate();
        if (type) {
          trpcUtils.getProfileByType.invalidate({ type });
        }
      },
    });

  const {
    data: profileData,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
    refetch: refetchProfile,
  } = trpc.getProfileByType.useQuery(
    { type },
    {
      refetchOnWindowFocus: false, // Habilitado para manter dados sincronizados
      refetchOnMount: true, // Habilitado para recarregar ao montar
      refetchOnReconnect: true, // Habilitado para recarregar ao reconectar
      staleTime: 0, // Dados sempre serão considerados stale
    },
  );

  const {
    data: userData,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    error: errorUsers,
    refetch: refetchUsers,
  } = trpc.searchCompanyEmployees.useQuery(
    { search: '' },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: 0,
    },
  );

  const {
    data: companyProfilesData,
    isLoading: isLoadingCompanyProfiles,
    isError: isErrorCompanyProfiles,
    error: errorCompanyProfiles,
    refetch: refetchCompanyProfiles,
  } = trpc.getCompanyProfiles.useQuery(undefined, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
  });

  const [profile, setProfile] = useState<TravelProfile | undefined>(undefined);
  const [userOptions, setUserOptions] = useState<UserOption[]>([]);
  const [companyProfiles, setCompanyProfiles] = useState<
    Record<TravelProfileType, CompanyProfile>
  >(defaultCompanyProfiles);

  useEffect(() => {
    if (profileData) {
      setProfile({
        type: profileData.type,
        employees: profileData.employees.map((employee) => ({
          id: employee.id,
          name: employee.name,
          email: employee.email,
          status: employee.status as Employee['status'],
        })),
      });
    }
  }, [profileData]);

  useEffect(() => {
    if (userData) {
      setUserOptions(
        userData.map((user) => ({
          id: user.id,
          name: user.name,
          email: user.email,
          status: user.status,
        })),
      );
    }
  }, [userData]);

  useEffect(() => {
    if (companyProfilesData) {
      const updatedProfiles = { ...defaultCompanyProfiles };

      // Itera sobre os dados recebidos e atualiza apenas os perfis existentes
      Object.entries(companyProfilesData).forEach(([key, value]) => {
        if (key in TravelProfileType) {
          const profileType = key as TravelProfileType;
          updatedProfiles[profileType] = {
            count: value.count,
            employees: value.employees.map((emp) => ({
              id: emp.id,
              name: emp.name,
              email: emp.email,
              status: emp.status as Employee['status'],
            })),
          };
        }
      });

      setCompanyProfiles(updatedProfiles);
    }
  }, [companyProfilesData]);

  // Criando o objeto utils com as operações necessárias usando o trpcUtils
  const profileUtils = {
    changeProfileEmployees: {
      mutate: async (data: {
        type: TravelProfileType;
        employeeIds: string[];
      }) => {
        await changeProfileEmployeesMutation.mutateAsync(data);
      },
      mutateAsync: async (data: {
        type: TravelProfileType;
        employeeIds: string[];
      }) => {
        await changeProfileEmployeesMutation.mutateAsync(data);
      },
    },
    getCompanyProfiles: {
      invalidate: async () => {
        await trpcUtils.getCompanyProfiles.invalidate();
      },
    },
    getProfileByType: {
      invalidate: async (filters: { type: TravelProfileType }) => {
        await trpcUtils.getProfileByType.invalidate(filters);
      },
    },
  };

  // Estado de loading combinado
  const isLoading =
    isLoadingProfile || isLoadingUsers || isLoadingCompanyProfiles;

  return {
    profileData: profile,
    userOptions,
    companyProfiles,
    isLoadingProfile,
    isLoadingUsers,
    isLoadingCompanyProfiles,
    isLoading,
    isErrorProfile,
    isErrorUsers,
    isErrorCompanyProfiles,
    errorProfile,
    errorUsers,
    errorCompanyProfiles,
    refetchProfile,
    refetchUsers,
    refetchCompanyProfiles,
    utils: profileUtils,
  };
}
