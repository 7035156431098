import { trpc } from '@api/bff/client';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { dispatchToast } from '@shared/toast';
import { useProfileConfigurationStore } from '../../store/ProfileConfiguration.store';
import { TravelProfileType } from '../../types';

type Props = {
  onSave?: () => void;
};

export function useProfileForm({ onSave }: Props) {
  const { selectedEmployeeIds, profileType } = useProfileConfigurationStore();
  const utils = trpc.useContext();

  const profileForm = useForm({
    defaultValues: {
      type: profileType,
      employeeIds: selectedEmployeeIds,
    },
  });

  const changeProfileEmployeesMutation =
    trpc.changeProfileEmployees.useMutation({
      onSuccess: () => {
        // Invalidate and refetch relevant queries
        utils.getCompanyProfiles.invalidate();
        utils.getProfileByType.invalidate({ type: profileType });
      },
    });

  // Query otimizada para companyProfiles
  const { data: companyProfiles, isFetching: isFetchingCompanyProfiles } =
    trpc.getCompanyProfiles.useQuery(undefined, {
      // Desabilita refetch automático no foco da janela
      refetchOnWindowFocus: false,
      // Mantém os dados em cache por 5 minutos
      staleTime: 5 * 60 * 1000,
      // Só refetch se os dados estiverem mais velhos que 10 minutos
      cacheTime: 10 * 60 * 1000,
    });

  useEffect(() => {
    profileForm.setValue('type', profileType);
    profileForm.setValue('employeeIds', selectedEmployeeIds);
  }, [profileType, selectedEmployeeIds, profileForm]);

  async function submitProfile(data: {
    employeeIds: string[];
    type: TravelProfileType;
  }) {
    try {
      if (!companyProfiles) return;

      const profileUpdates = new Map<TravelProfileType, string[]>();

      Object.entries(companyProfiles).forEach(([type, profile]) => {
        if (type === data.type) return;

        const remainingEmployees = profile.employees
          .filter((emp) => !data.employeeIds.includes(emp.id))
          .map((emp) => emp.id);

        if (remainingEmployees.length !== profile.employees.length) {
          profileUpdates.set(type as TravelProfileType, remainingEmployees);
        }
      });

      // Atualiza os perfis anteriores
      const updates = Array.from(profileUpdates.entries());
      for (const [type, employeeIds] of updates) {
        await changeProfileEmployeesMutation.mutateAsync({
          type: type as TravelProfileType,
          employeeIds,
        });
      }

      // Atualiza o perfil atual
      await changeProfileEmployeesMutation.mutateAsync({
        type: data.type,
        employeeIds: data.employeeIds,
      });

      onSave?.();
      dispatchToast({
        type: 'success',
        content: `Os perfis foram atualizados com sucesso`,
      });
    } catch (error) {
      dispatchToast({
        type: 'error',
        content: `Ocorreu um erro ao atualizar um perfil de viagem. Tente novamente. ${error}`,
      });
    }
  }

  return {
    profileForm,
    onSubmit: profileForm.handleSubmit(submitProfile),
    submitProfile,
    isFetchingCompanyProfiles,
    isSaving: profileForm.formState.isSubmitting,
  };
}
