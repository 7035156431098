import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';
import Popup from '../../popup/popup.component.js';
import Select from '../../select/select.component.js';
import CreditCard from '../credit-card/credit-card.component.js';

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.colors.primary};
`;

const Template = {
  main: ({ select, popup, card, disabled }) => {
    return (
      <div className="exon-payment-selector">
        <Select
          data={select.props.data}
          placeholder={'Selecione a forma de pagamento...'}
          externalOptions={{
            items: select.props.items,
            events: {
              onSelected: select.events.onChange,
            },
          }}
          required={select.props.required}
          isZeroValid={true}
          forceValidation={select.props.forceValidation}
          disabled={disabled}
        />
        {select?.props?.data?.paymentForm?.creditCard?.provider === 1 &&
          <StyledTypography variant='caption' weight={700}>
            ATENÇÃO: confira se o cartão Flash possui saldo antes de prosseguir
          </StyledTypography>
        }

        {popup.props.show && (
          <div className="exon-box-popop-card">
            <Popup
              okButtonLabel={'OK'}
              title={'Dados do cartão'}
              cancelButtonClick={popup.events.onCancelButtonClicked}
              exonStyle={popup.exonStyle}
              okButtonClick={popup.events.onOkButtonClicked}
            >
              <CreditCard
                data={card.props.data}
                forceValidation={card.props.forceValidation}
                onCardHolderChange={card.events.onCardHolderChanged}
                onCardNumberChange={card.events.onCardNumberChanged}
                onCardBrandCodeChange={card.events.onCardBrandCodeChanged}
                onCvvChange={card.events.onCardCvvChanged}
                onExpirationDateChange={card.events.onCardExpirationDateChanged}
              />
            </Popup>
          </div>
        )}
      </div>
    );
  },
};

export default Template;
