import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { CustomFieldType } from 'bff/src/routers/custom-fields/enums';
import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { getCustomFieldTypeName } from '../../../CustomFieldsTable/utils/getCustomFieldTypeName';
import { CustomField, CustomFieldRegisterForm } from '../../types';

type Props = {
  control: Control<CustomFieldRegisterForm>;
  customField?: CustomField;
};

export default function CustomFieldTypeSelect({ control, customField }: Props) {
  const actionType = useMemo<'add' | 'edit'>(
    () => (customField ? 'edit' : 'add'),
    [customField],
  );

  const fieldTypes = useMemo(() => Object.values(CustomFieldType), []);

  return (
    <Box>
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <SelectField
            label={'Tipo do campo'}
            fullWidth
            disabled={actionType === 'edit'}
            options={fieldTypes.map((field) => ({
              value: field,
              label: getCustomFieldTypeName(field),
            }))}
            value={field.value}
            onSelectChange={(e, selected) => field.onChange(selected.value)}
          />
        )}
        rules={{ required: true }}
      />
    </Box>
  );
}
