import { FormModal } from '@components/FormModal';
import { CustomFieldForm } from './components/CustomFieldForm';
import { useCustomFieldForm } from './hooks/useCustomFieldForm';

type Props = {
  customFieldId?: string;
  onCancel: () => void;
  onSave: () => void;
};

export const CustomFieldRegister = ({
  onCancel,
  onSave,
  customFieldId,
}: Props) => {
  const { customFieldForm, customField, onSubmit, isLoading } =
    useCustomFieldForm({
      customFieldId,
      onSave,
    });

  return (
    <FormModal
      title="Adicionar campo customizado"
      description="Campos customizados permitem adaptar a viagem às necessidades dos viajantes."
      isSaving={customFieldForm.formState.isSubmitting}
      onSaveClick={customFieldForm.handleSubmit(onSubmit)}
      onCancel={onCancel}
    >
      <CustomFieldForm
        customFieldForm={customFieldForm}
        customField={customField.data}
        isLoading={isLoading}
      />
    </FormModal>
  );
};
