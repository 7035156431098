import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const AddOptionsWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs2};
  flex-direction: column;
`;

export const OptionWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs2};
  flex-direction: row;
  align-items: center;
`;

export const OptionTitle = styled(Typography)`
  font-size: 16px !important;
`;
