import { Spinner, SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BaseSelectOption,
  BaseSelectProps,
} from '@shared/types/form/select.types';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const LoaderContainer = styled('div')({
  position: 'absolute',
  top: '26px',
  left: '16px',
});

export const SelectWrapper: React.FC<BaseSelectProps> = ({
  name,
  options: initialOptions = [],
  loadOptions,
  onChangeValue,
  transform = (item) => item,
  defaultValue = null,
  showLoader = true,
  additionalOptions = [],
  valueAsNumber = false,
  ...selectProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [options, setOptions] = useState<BaseSelectOption[]>(initialOptions);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (additionalOptions.length > 0) {
      setOptions((currentOptions) => {
        const firstOption = currentOptions[0];
        const restOptions = currentOptions.slice(1);
        const filteredOptions = restOptions.filter(
          (opt) =>
            !additionalOptions.some((addOpt) => addOpt.value === opt.value),
        );
        return firstOption
          ? [firstOption, ...filteredOptions, ...additionalOptions]
          : [...filteredOptions, ...additionalOptions];
      });
    }
  }, [additionalOptions]);

  const defaultOptionValue = useMemo(() => {
    if (valueAsNumber && typeof defaultValue === 'number') {
      const option = options.find((opt) => opt.value === defaultValue);
      return option || null;
    }
    return defaultValue;
  }, [defaultValue, options, valueAsNumber]);

  const handleLoadOptions = useCallback(async () => {
    if (loadOptions && options.length === 0) {
      setIsLoading(true);
      try {
        const data = await loadOptions();
        setOptions(data.map(transform));
      } catch (error) {
        console.error('Error loading options:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [loadOptions, options.length, transform]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={valueAsNumber ? defaultValue : defaultOptionValue}
      render={({ field }) => (
        <Box position="relative">
          <SelectField
            {...selectProps}
            variant="outlined"
            onSelectChange={(_, selectedItem) => {
              const value = valueAsNumber
                ? selectedItem?.value ?? null
                : selectedItem || null;
              field.onChange(value);
              onChangeValue?.(selectedItem);
            }}
            onFocus={handleLoadOptions}
            options={options}
            disabled={isLoading}
            value={
              valueAsNumber
                ? options.find((opt) => opt.value === field.value) || null
                : field.value
            }
            error={!!errors[name]}
            helperText={
              <DisplayErrorFormValidation errors={errors} fieldName={name} />
            }
          />
          {showLoader && isLoading && (
            <LoaderContainer>
              <Spinner size={16} variant="secondary" />
            </LoaderContainer>
          )}
        </Box>
      )}
    />
  );
};
