import { useState, useEffect, useCallback } from 'react';
import { getApprovers } from '@api/expenseGateway/expenseGateway.service';

export const useApprovers = () => {
  const [approvers, setApprovers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchApprovers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getApprovers();
      setApprovers(
        response.map((approver) => ({
          value: approver.id,
          label: approver.name,
          caption: approver.email,
        })),
      );
    } catch (err) {
      setError('Erro ao buscar aprovadores.');
      console.error('Erro ao buscar aprovadores:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchApprovers();
  }, [fetchApprovers]);

  return {
    approvers,
    isLoading,
    error,
    refetch: fetchApprovers,
  };
};
