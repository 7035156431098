import { Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

interface FormSectionProps {
  title: string;
  children: ReactNode;
  spacing?: number;
  className?: string;
  titleVariant?: 'headline8' | 'headline7' | 'headline6';
}

const ContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'spacing',
})<{ spacing?: number }>(({ theme, spacing = 2 }) => ({
  marginTop: theme.spacing(spacing),
}));

export function FormSection({
  title,
  children,
  spacing,
  className,
  titleVariant = 'headline8',
}: FormSectionProps) {
  return (
    <Box className={className}>
      <Typography variant={titleVariant}>{title}</Typography>
      <ContentWrapper spacing={spacing}>{children}</ContentWrapper>
    </Box>
  );
}
