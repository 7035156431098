import { CustomFieldType } from 'bff/src/routers/custom-fields/enums';

export function getCustomFieldTypeName(type: CustomFieldType) {
  const levelMap: Record<CustomFieldType, string> = {
    TEXT: 'Texto',
    DATE: 'Data',
    NUMBER: 'Número',
    OPTIONS: 'Opções',
  };

  return levelMap[type];
}
