import {
  getFromLS,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { useCallback, useMemo } from 'react';

type FlashOSModuleContract =
  | 'FLASH_APP_SAAS_SERVICE_CONTRACT'
  | 'FLASH_APP_CONTRACT_CORPORATE_CARD';

export function useCheckExpenseActive() {
  const company = useSelectedCompany();

  const getActiveModules = useCallback((): FlashOSModuleContract[] => {
    const companyId = company.selectedCompany.id;

    const keyLS = `expensesAcceptanceTermsCompanyId-${companyId}`;

    return getFromLS(keyLS) || [];
  }, [company?.selectedCompany?.id]);

  const isExpenseActive = useMemo(() => {
    return getActiveModules().length > 0;
  }, [getActiveModules]);

  return {
    isExpenseActive,
  };
}
