import { FormModal } from '@components/FormModal';
import { JustificationForm } from './components/JustificationForm';
import { useJustificationForm } from './hooks/useJustificationForm';

type Props = {
  justificationId?: string;
  onCancel: () => void;
  onSave: () => void;
};

export const JustificationRegister = ({
  onCancel,
  onSave,
  justificationId,
}: Props) => {
  const { justificationForm, onSubmit, isLoading } = useJustificationForm({
    justificationId: justificationId,
    onSave,
  });

  return (
    <FormModal
      title="Adicionar justificativa"
      description="Permite personalizar solicitações de viagem com justificativas de viagem, atendendo às necessidades individuais dos viajantes."
      isSaving={justificationForm.formState.isSubmitting}
      onSaveClick={justificationForm.handleSubmit(onSubmit)}
      onCancel={onCancel}
      isLoading={isLoading}
    >
      <JustificationForm
        justificationForm={justificationForm}
        isEditing={!!justificationId}
      />
    </FormModal>
  );
};
