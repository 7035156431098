import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { TableBatchActionsContainer } from './components/TableBatchActionsContainer';
import { TableFilters } from './components/TableFilters';
import { TableHeader } from './components/TableHeader';
import { TablePagination } from './components/TablePagination';
import { useTravelDataTable } from './hooks/useTravelDataTable';
import { TravelDataTableProps } from './types';

export function TravelDataTable<T>(props: TravelDataTableProps<T>) {
  const {
    filteredItems,
    pagination,
    search,
    isLoading,
    table,
    handleSearch,
    setPagination,
    handleFilters,
  } = useTravelDataTable(props);

  const [localFilteredItems, setLocalFilteredItems] = useState(filteredItems);

  useEffect(() => {
    setLocalFilteredItems(filteredItems);
  }, [filteredItems]);

  return (
    <Box width="100%">
      <Table.Root>
        <TableHeader
          header={props.header}
          headerEndContent={props.headerEndContent}
          filteredItemsCount={localFilteredItems.length}
        />

        <Table.Search
          label={props.search?.placeholder || 'Buscar por um item...'}
          onChange={handleSearch}
          value={search}
        />

        <TableFilters {...props} onFilterChange={handleFilters} />

        <Table.Grid.Root
          key={JSON.stringify(localFilteredItems)}
          loading={isLoading}
        >
          {table.selected.allSelected || table.selected.selected.length > 0 ? (
            <TableBatchActionsContainer
              table={table}
              renderBatchActions={props.renderBatchActions}
            />
          ) : (
            <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          )}

          {table.rows.map((row) => (
            <Table.Grid.Row key={row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <TablePagination
          filteredItemsCount={localFilteredItems.length}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Table.Root>
    </Box>
  );
}

export { TableBaseActions } from './components/BaseActions/TableBaseActions';
export { TableBaseBatchActions } from './components/BaseActions/TableBaseBatchActions';
