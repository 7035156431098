import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacings.xs3};
`;
