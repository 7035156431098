import { trpc } from '@api/bff/client';
import { Icon } from '@components/Pills/styled';
import { TravelDataTable } from '@components/TravelDataTable';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { useState } from 'react';
import { JustificationRegister } from '../JustificationRegister';
import JustificationTableActions from './components/JustificationTableActions';
import JustificationTableBatchActions from './components/JustificationTableBatchActions';
import {
  useJustificationsColumns,
  useJustificationsFilters,
} from './hooks/useJustificationsTable';

const ONE_MINUTE = 60000;

export function JustificationsTable() {
  const { data, isError, isLoading, refetch } = trpc.getJustification.useQuery(
    null,
    { staleTime: ONE_MINUTE },
  );

  const columns = useJustificationsColumns();
  const filters = useJustificationsFilters();

  const [editingJustificationId, setEditingJustificationId] = useState<
    string | null
  >(null);

  const [showJustificationRegister, setShowJustificationRegister] =
    useState(false);

  return (
    <Box width="100%">
      <Box border={1} color={'#ebe6e9'} p={2} borderRadius={2}>
        <TravelDataTable
          header={{ title: 'Justificativas', tagLabel: 'justificativas' }}
          headerEndContent={
            <Button
              size="large"
              variant={'primary'}
              onClick={() => setShowJustificationRegister(true)}
            >
              Adicionar justificativa <Icon name="IconPlus" />
            </Button>
          }
          search={{
            placeholder: 'Busca por nome ou código da justificativa',
          }}
          columns={columns}
          filters={filters}
          rows={data?.justifications || []}
          renderActions={({ item: justification }) => (
            <JustificationTableActions
              justification={justification}
              onRefresh={refetch}
              onEdit={() => {
                setEditingJustificationId(justification.id);
                setShowJustificationRegister(true);
              }}
            />
          )}
          renderBatchActions={(table) => (
            <JustificationTableBatchActions table={table} onRefresh={refetch} />
          )}
          checkboxSelection
          isLoading={isLoading}
          isError={isError}
        />
      </Box>

      {showJustificationRegister && (
        <JustificationRegister
          justificationId={editingJustificationId}
          onCancel={() => {
            setEditingJustificationId(null);
            setShowJustificationRegister(false);
          }}
          onSave={() => {
            refetch?.();

            setShowJustificationRegister(false);
            setEditingJustificationId(null);
          }}
        />
      )}
    </Box>
  );
}
