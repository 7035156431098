import { trpc } from '@api/bff/client';
import { BooleanTag } from '@components/BooleanTag';
import { TableStatusToggle } from '@components/TravelDataTable/components/TableStatusToggle';
import {
  TravelTableColumn,
  TravelTableFilter,
} from '@components/TravelDataTable/types';
import { Box } from '@mui/material';
import { GetCustomFieldsResponse } from 'bff/src/routers/custom-fields';
import {
  CustomFieldStatus,
  CustomFieldType,
} from 'bff/src/routers/custom-fields/enums';
import { PolicyStatus } from 'bff/src/routers/policies/enums';
import { format } from 'date-fns';
import { getCustomFieldTypeName } from '../utils/getCustomFieldTypeName';

type CustomField = GetCustomFieldsResponse['customFields'][number];

export function useCustomFieldsColumns(): TravelTableColumn<CustomField>[] {
  return [
    {
      accessorKey: 'name',
      header: 'Nome do campo',
    },
    {
      accessorKey: 'type',
      header: 'Tipo',
      cell: ({ row }) => getCustomFieldTypeName(row.original.type),
    },
    {
      accessorKey: 'required',
      header: 'Obrigatório',
      cell: ({ row }) => (
        <Box display="flex" justifyContent="center" alignItems="center">
          <BooleanTag value={row.original.required} />
        </Box>
      ),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Última atualização',
      cell: ({ row }) =>
        format(new Date(row.original.updatedAt), 'dd/MM/yyyy HH:mm'),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      sticky: 'right',
      size: 30,
      cell: ({ row }) => {
        const mutation = trpc.updateCustomFieldStatus.useMutation();

        return (
          <TableStatusToggle
            defaultChecked={row.original.status === CustomFieldStatus.ACTIVE}
            isLoading={mutation.isLoading}
            onToggleStatus={(value) =>
              mutation.mutateAsync({
                id: row.original.id,
                status: value
                  ? CustomFieldStatus.ACTIVE
                  : CustomFieldStatus.INACTIVE,
              })
            }
          />
        );
      },
    },
  ];
}

export function useCustomFieldFilters(): TravelTableFilter[] {
  return [
    {
      name: 'type',
      label: 'Tipo',
      options: Object.values(CustomFieldType).map((type) => ({
        label: getCustomFieldTypeName(type),
        value: type,
      })),
    },
    {
      name: 'status',
      label: 'Status',
      options: [
        { label: 'Ativo', value: PolicyStatus.ACTIVE },
        { label: 'Inativo', value: PolicyStatus.INACTIVE },
      ],
    },
  ];
}
