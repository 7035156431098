import React from 'react';
import { CreateApprovalFlowInput } from 'bff/src/routers/approval-flows';
import { RadioButtonCard } from '@flash-tecnologia/hros-web-ui-v2';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ActionSelectorContainer } from './styled';
import { ECustomApprovalType } from '../../../../../types';

const ApprovalTypeField = () => {
  const theme = useTheme();
  const isMdOrDown = useMediaQuery(theme.breakpoints.down('md'));
  const { control } = useFormContext<CreateApprovalFlowInput>();

  return (
    <Box>
      <Controller
        name="approvalType"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'A opção de câmbio é obrigatória', // Mensagem personalizada
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Box mb={4}>
            <ActionSelectorContainer isFull={isMdOrDown}>
              <RadioButtonCard
                title="Manual"
                description="Permite configurar níveis e opções de aprovação personalizadas, como selecionar responsáveis pelo centro de custo, usar a hierarquia do aprovador ou escolher aprovadores manualmente."
                icon="IconAutomaticGearbox"
                checked={field.value === ECustomApprovalType.CUSTOM}
                onClick={() => field.onChange(ECustomApprovalType.CUSTOM)}
              />
              <RadioButtonCard
                title="Automática"
                description="O sistema aprova ou reprova automaticamente com base na política de viagens da empresa. Em caso de violação, um alerta é enviado aos usuários e/ou administradores."
                icon="IconSettingsAutomation"
                checked={field.value === ECustomApprovalType.AUTO}
                onClick={() => field.onChange(ECustomApprovalType.AUTO)}
              />
            </ActionSelectorContainer>
            {error ? (
              <Box mt={2} style={{ color: '#fea034' }}>
                {error.message}
              </Box>
            ) : null}
          </Box>
        )}
      />
    </Box>
  );
};

export default ApprovalTypeField;
