import {
  SelectField,
  Typography,
  Radio,
  Checkbox,
  NotificationCard,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Control, Controller } from 'react-hook-form';
import React from 'react';
import * as SC from '../styled';
import { SelectTravelers } from '@components/RequestTravel/RequestTravelItemOffline/DetailsVehicle/SelectTravelers';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import { useTravelItemStore } from '../../../../store/RequestTravelItem.store';

interface TravelReasonProps {
  control: Control;
}

export function DetailsVehicle({ control }: TravelReasonProps) {
  const isAgency = useTravelStore((state) => state.isAgency);
  const carRentals = useTravelItemStore((state) => state.carRentals);

  const typeCar = [
    { label: 'Duas Portas', value: 'two_doors' },
    { label: 'Duas ou quatro portas', value: 'two_or_four_doors' },
    { label: 'Quatro Portas', value: 'four_doors' },
    { label: 'Com mala ampla', value: 'with_large_trunk' },
    { label: 'Van', value: 'van' },
    { label: 'Limousine', value: 'limousine' },
    { label: 'Esporte', value: 'sport' },
    { label: 'Conversível', value: 'convertible' },
    { label: 'Com tração nas quatro rodas', value: 'four_wheel_drive' },
    {
      label: 'Para qualquer tipo de terreno',
      value: 'all_terrain',
    },
    { label: 'Pick-up cabine normal', value: 'pickup_regular_cab' },
    { label: 'Pick-up cabine estendida', value: 'pickup_extended_cab' },
    { label: 'Caminhão', value: 'truck' },
    { label: 'Recreacional', value: 'recreational' },
    { label: 'Especial', value: 'special' },
  ];

  const categoryCar = [
    {
      label: 'Economico',
      value: 'economy',
    },
    {
      label: 'Intermediário',
      value: 'intermediate',
    },
    {
      label: 'Superior',
      value: 'premium',
    },
    {
      label: 'Compacto',
      value: 'compact',
    },
    {
      label: 'Especial',
      value: 'special',
    },
    {
      label: 'Minivan',
      value: 'minivan',
    },
  ];

  if (!isAgency) {
    carRentals.push({
      label: 'Sem preferência',
      value: 'sem_preferência',
    });
  }

  return (
    <SC.ItemFormContainer>
      <div style={{ marginBottom: '10px' }}>
        <Typography variant={'headline8'} variantColor={'#3B2E37'}>
          Detalhes do veículo
        </Typography>
      </div>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <div style={{ width: '50%' }}>
          <SelectTravelers control={control} />
        </div>

        <div style={{ width: '50%' }}>
          <Controller
            control={control}
            name="vehicle.rentalCompany"
            render={({ field, fieldState: { error } }) => (
              <SelectField
                onSelectChange={(event, selectedItem) =>
                  field.onChange(selectedItem)
                }
                fullWidth
                value={field.value}
                options={carRentals}
                label={'Locadora preferencial'}
                error={!!error}
                helperText={error ? error.message : null}
                disabled={isAgency}
                {...field}
              />
            )}
          />
        </div>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div style={{ width: '50%' }}>
          <Controller
            control={control}
            name="vehicle.category"
            render={({ field, fieldState: { error } }) => (
              <SelectField
                onSelectChange={(event, selectedItem) =>
                  field.onChange(selectedItem)
                }
                fullWidth
                value={field.value}
                options={categoryCar}
                label={'Categoria'}
                error={!!error}
                helperText={error ? error.message : null}
                {...field}
              />
            )}
          />
        </div>
        <div style={{ width: '50%' }}>
          <Controller
            control={control}
            name="vehicle.type"
            render={({ field, fieldState: { error } }) => (
              <SelectField
                onSelectChange={(event, selectedItem) =>
                  field.onChange(selectedItem)
                }
                fullWidth
                value={field.value}
                options={typeCar}
                label={'Tipo'}
                error={!!error}
                helperText={error ? error.message : null}
                {...field}
              />
            )}
          />
        </div>
      </div>

      <div
        className={'OptionsItens'}
        style={{ display: 'flex', marginTop: '20px', gap: '20px' }}
      >
        <div style={{ marginBottom: '10px' }}>
          <Typography variant={'body3'} weight={600} variantColor={'#53464F'}>
            Câmbio
          </Typography>
          <Controller
            name="vehicle.transmission"
            control={control}
            rules={{ required: 'A opção de câmbio é obrigatória' }}
            render={({ field, fieldState: { error } }) => (
              <div>
                <Radio
                  checked={field.value === 'manual'}
                  onChange={field.onChange}
                  value="manual"
                  name={field.name}
                  error={!!error}
                />
                Manual
                <Radio
                  checked={field.value === 'automatic'}
                  onChange={field.onChange}
                  value="automatic"
                  name={field.name}
                  error={!!error}
                />
                Automático
                {error ? (
                  <div style={{ color: '#fea034' }}>{error.message}</div>
                ) : null}
              </div>
            )}
          />
        </div>

        <div style={{ marginBottom: '10px' }}>
          <Typography variant={'body3'} weight={600} variantColor={'#53464F'}>
            Adicionais
          </Typography>
          <Controller
            control={control}
            name="vehicle.hasGPS"
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                name={field.name}
              />
            )}
          />
          GPS
          <Controller
            control={control}
            name="vehicle.hasAirbag"
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                name={field.name}
              />
            )}
          />
          Airbag
          <Controller
            control={control}
            name="vehicle.hasAC"
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                name={field.name}
              />
            )}
          />
          Ar Condicionado
        </div>
      </div>

      <div>
        <NotificationCard.Root type={'error'} variant={'outlined'}>
          <NotificationCard.WrapperIconWithText>
            <NotificationCard.Icon iconName={'IconAlertTriangle'} />
            <NotificationCard.WrapperTexts>
              <NotificationCard.Subtitle>
                A locadora não confirma o tipo de carro na reserva, somente a
                categoria.
              </NotificationCard.Subtitle>
            </NotificationCard.WrapperTexts>
          </NotificationCard.WrapperIconWithText>
        </NotificationCard.Root>
      </div>
    </SC.ItemFormContainer>
  );
}
