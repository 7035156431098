import { Tag, Icons, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

export const SearchStatistics = ({ data }) => {
  const executionStatistics = Array.isArray(data) ? data : data.executionStatistics;
  const isInternational = data.isInternational;

  const filteredData = isInternational !== undefined
    ? executionStatistics.filter(item => {
        return item.source !== 'Sabre' || isInternational === true;
      })
    : executionStatistics;

  const formatTime = (totalMilliseconds) => {
    const totalSeconds = Math.max(1, Math.floor(totalMilliseconds / 1000));
    const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const sourcesStatistics = filteredData.map((stat) => ({
    source: stat.source,
    resultsCount: stat.resultsCount,
    formattedTime: formatTime(stat.totalTime),
  }));

  return (
    <div className="exon-source-tag">
      {sourcesStatistics.map((stat, index) => (
        stat.source && (
          <Tooltip
            key={index}
            title={stat.resultsCount > 0 ? `Resultados: ${stat.resultsCount}` : 'Nenhum resultado encontrado'}
            arrow
            placement="top"
          >
            <Tag
              size="small"
              variant={stat.resultsCount > 0 ? "green" : "yellow"}
            >
              <Icons
                name={stat.resultsCount > 0 ? "IconCircleCheck" : "IconCircleX"}
                style={{ backgroundColor: 'transparent' }}
              />
              {`${stat.source} - ${stat.formattedTime} seg`}
            </Tag>
          </Tooltip>
        )
      ))}
    </div>
  );
};
