import { TravelDataTableProps } from '@components/TravelDataTable/types';
import { Tag } from '@flash-tecnologia/hros-web-ui-v2';
import { Table } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types';
import { Box, Checkbox } from '@mui/material';

type Props = {
  table: Table<any>;
} & Pick<TravelDataTableProps, 'renderBatchActions'>;

export const TableBatchActionsContainer = ({
  table,
  renderBatchActions,
}: Props) => {
  return (
    <tr className="data-grid-table-header-bulk-actions-container" role="row">
      <th className="data-grid-table-header-bulk-actions">
        <Checkbox
          onChange={() => table.setAllSelected(!table.selected.allSelected)}
          indeterminate={table.selected.selected.length > 0}
          checked={table.selected.allSelected}
          aria-label="column row checkbox"
        />
      </th>

      <th className="data-grid-table-header-bulk-actions">
        <Box display="flex" gap={3} alignItems="center">
          <Tag variant="primary" onClick={() => {}}>
            {table.selected.allSelected
              ? 'Todos'
              : table.selected.selected.length}{' '}
            selecionado{table.selected.selected.length === 1 ? '' : 's'}
          </Tag>

          {renderBatchActions?.(table)}
        </Box>
      </th>
    </tr>
  );
};
