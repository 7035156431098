import styled from 'styled-components';

export const NewFunctionsContanier = styled.div`
  border: 1px solid #fb2a81;
  background: #fff5fa;
  border-radius: 10px;
  padding: 30px;
  margin: 0 26px 18px 26px;
  display: flex;
  align-items: center;
`;
export const TitleNewsFunctions = styled.div`
  color: #fb2a81;
  margin-bottom: 10px;
`;
export const Link = styled.a`
  color: #fb2a81;
  font-weight: 600;
  cursor: pointer;
`;
export const Icon = styled.div`
  margin-right: 20px;
`;
