import { SelectWrapper } from '@shared/components/Form/SelectWrapper';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';

export function TravelersQuantity() {
  const { setValue } = useFormContext();
  const updateTravelersQuantity = useTravelStore(
    (state) => state.updateTravelersQuantity,
  );
  const { enableTravelersLimit } = useTravelPermissions();

  const travelersOptions = useMemo(() => {
    const maxTravelers = enableTravelersLimit ? 1 : 7;
    return Array.from({ length: maxTravelers }, (_, i) => ({
      label: `${i + 1}`,
      value: i + 1,
    }));
  }, [enableTravelersLimit]);

  useEffect(() => {
    if (enableTravelersLimit) {
      setValue('number_travelers', 1);
      updateTravelersQuantity(1);
    }
  }, [enableTravelersLimit, updateTravelersQuantity, setValue]);

  return (
    <SelectWrapper
      name="number_travelers"
      label="Quantidade de viajantes"
      options={travelersOptions}
      defaultValue={enableTravelersLimit ? 1 : undefined}
      onChangeValue={(selected) => {
        if (selected) {
          updateTravelersQuantity(selected.value);
        }
      }}
      disabled={enableTravelersLimit}
      valueAsNumber={true}
      fullWidth
    />
  );
}
