import { Icons, IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import {
  FareFamily,
  TravelProduct,
  TravelSource,
  TravelType,
} from 'bff/src/routers/policies/enums';
import {
  PolicyRuleConfiguration,
  PolicyRuleConfigurationField,
} from '../../../../types';

const getIcon = (name: IconsProps['name']) => (
  <Icons name={name} fill="transparent" size={24} />
);

export function usePolicyRules(): PolicyRuleConfiguration[] {
  const productField: PolicyRuleConfigurationField = {
    name: 'Produto',
    key: 'product',
    type: 'options',
    helperText:
      'Escolha o tipo de produto (ex: aéreo, hotel, veículo) para o qual esta regra será aplicada.',
    options: [
      {
        value: TravelProduct.ALL,
        label: 'Todos',
        icon: getIcon('IconPackage'),
        caption: 'Inclui todos os tipos de produtos de viagem, sem distinção.',
      },
      {
        value: TravelProduct.AIR,
        label: 'Aéreo',
        icon: getIcon('IconPlane'),
        caption: 'Refere-se a passagens aéreas e serviços relacionados a voos.',
      },
      {
        value: TravelProduct.HOTEL,
        label: 'Hotel',
        icon: getIcon('IconBuilding'),
        caption: 'Inclui opções de hospedagem, como hotéis e pousadas.',
      },
      {
        value: TravelProduct.VEHICLE,
        label: 'Veículo',
        icon: getIcon('IconCar'),
        caption:
          'Refere-se a aluguéis de veículos, como carros, motos e similares.',
      },
    ],
  };

  const travelTypeField: PolicyRuleConfigurationField = {
    name: 'Tipo de Viagem',
    key: 'travelType',
    type: 'options',
    helperText:
      'Determine se essa regra se aplica a viagens nacionais ou internacionais, ou ambos os tipos.',
    options: [
      {
        label: 'Todos',
        value: TravelType.ALL,
        icon: getIcon('IconGlobe'),
      },
      {
        label: 'Nacional',
        value: TravelType.NATIONAL,
        icon: getIcon('IconHome'),
      },
      {
        label: 'Internacional',
        value: TravelType.INTERNATIONAL,
        icon: getIcon('IconWorld'),
      },
    ],
  };

  const maxValueField: PolicyRuleConfigurationField = {
    name: 'Tolerância de Valor',
    key: 'maxValue',
    type: 'currency',
    helperText:
      'Especifique o limite de valor permitido com base na diferença entre o menor preço disponível e o valor permitido.',
  };

  const daysInAdvanceField: PolicyRuleConfigurationField = {
    name: 'Dias de Antecedência',
    key: 'daysInAdvance',
    type: 'number',
    helperText:
      'Informe o número mínimo de dias de antecedência exigidos para a aprovação da viagem.',
  };

  // Outros Campos
  const minutesInAdvanceField: PolicyRuleConfigurationField = {
    name: 'Antecedência',
    key: 'minutesInAdvance',
    type: 'time',
    helperText:
      'Defina o tempo mínimo que a aprovação deve ocorrer antes da expiração da reserva.',
  };

  const minFlightDurationField: PolicyRuleConfigurationField = {
    name: 'Menor tempo de Voo',
    key: 'minFlightDuration',
    type: 'toggle',
    helperText:
      'Ative esta opção se quiser priorizar voos com menor duração, independentemente do preço.',
  };

  const fareFamilyField: PolicyRuleConfigurationField = {
    name: 'Família',
    key: 'fareFamily',
    type: 'options',
    helperText:
      'Defina a faixa tarifária que deve ser considerada na regra, de tarifas promocionais a tarifas com mais benefícios.',
    options: [
      {
        label: 'Tarifa 1 (Promocional)',
        value: FareFamily.FAMILY_1,
        caption:
          'Tarifa com o menor preço, sem benefícios como bagagem inclusa ou marcação de assento.',
        icon: getIcon('IconDiscount'),
      },
      {
        label: 'Tarifa 2 (Light)',
        value: FareFamily.FAMILY_2,
        caption:
          'Tarifa econômica, mas com algumas vantagens em relação à tarifa promocional, como marcação de assento mediante taxa.',
        icon: getIcon('IconPlaneDeparture'),
      },
      {
        label: 'Tarifa 3 (Plus, MaisAzul)',
        value: FareFamily.FAMILY_3,
        caption:
          'Tarifa com 1 bagagem inclusa e marcação de assento sem custo adicional.',
        icon: getIcon('IconLuggage'),
      },
      {
        label: 'Tarifa 4 (Max, Top)',
        value: FareFamily.FAMILY_4,
        caption:
          'Tarifa mais completa, com 2 bagagens inclusas e diversos benefícios, como marcação de assento gratuita.',
        icon: getIcon('IconTrophy'),
      },
    ],
  };

  const localityField: PolicyRuleConfigurationField = {
    name: 'Localidade',
    key: 'locality',
    type: 'place',
    helperText:
      'Informe a região ou cidade onde a regra de menor preço se aplicará.',
  };

  const sourceField: PolicyRuleConfigurationField = {
    name: 'Fornecedor',
    key: 'source',
    type: 'options',
    helperText:
      'Selecione de qual fornecedor as opções de hospedagem serão avaliadas, como OmniBees ou B2B.',
    options: [
      { value: TravelSource.B2B, label: 'B2B' },
      { value: TravelSource.OMNIBEES, label: 'OmniBees' },
      { value: TravelSource.ITERPEC, label: 'Iterpec' },
      { value: TravelSource.OFFLINE, label: 'HotelOffline' },
    ],
  };

  const hotelIdsField: PolicyRuleConfigurationField = {
    name: 'Nome dos hotéis',
    key: 'hotelIds',
    type: 'list',
    helperText:
      'Insira os nomes ou IDs dos hotéis que devem ser considerados na regra. Separe cada um dos nomes por vírgula.',
  };

  const dailyLimitField: PolicyRuleConfigurationField = {
    name: 'Limite da Diária',
    key: 'dailyLimit',
    type: 'currency',
    helperText: 'Estabeleça o valor máximo permitido para a diária de hotel.',
  };

  // Definição das Regras
  const p1: PolicyRuleConfiguration = {
    code: 'P1',
    description: 'Prazo mínimo para aprovação antes da expiração da reserva',
    fields: [productField, minutesInAdvanceField],
  };

  const p2: PolicyRuleConfiguration = {
    code: 'P2',
    description: 'Validação do menor preço para passagens aéreas (padrão)',
    fields: [],
  };

  const p3: PolicyRuleConfiguration = {
    code: 'P3',
    description:
      'Validação do menor preço para passagens aéreas (personalizado)',
    fields: [
      minFlightDurationField,
      travelTypeField,
      fareFamilyField,
      maxValueField,
    ],
  };

  const p4: PolicyRuleConfiguration = {
    code: 'P4',
    description: 'Validação do menor preço para hospedagem (padrão)',
    fields: [],
  };

  const p5: PolicyRuleConfiguration = {
    code: 'P5',
    description: 'Validação do menor preço para hospedagem (personalizado)',
    fields: [localityField, sourceField, hotelIdsField, dailyLimitField],
  };

  const p6: PolicyRuleConfiguration = {
    code: 'P6',
    description: 'Prazo mínimo de antecedência para viagens nacionais',
    fields: [productField, daysInAdvanceField],
  };

  const p7: PolicyRuleConfiguration = {
    code: 'P7',
    description: 'Prazo mínimo de antecedência para viagens internacionais',
    fields: [productField, daysInAdvanceField],
  };

  const p8: PolicyRuleConfiguration = {
    code: 'P8',
    description:
      'Valor máximo permitido com base no menor preço por categoria de produto',
    fields: [productField, travelTypeField, maxValueField],
  };

  return [p1, p2, p3, p4, p5, p6, p7, p8];
}
