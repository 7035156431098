export default {
  translations: {
    pages: {
      sale: {
        title: 'Pesquisa de Viagem',
      },
      list: {
        title: 'Solicitações Realizadas',
      },
      approvals: {
        title: 'Aprovações',
      },
      monitoring: {
        title: 'Monitoramento das Viagens',
      },
      reports: {
        main: {
          title: 'Relatórios de Viagens',
        },
        travelRequests: {
          title: 'Solicitações Realizadas',
        },
        bookings: {
          title: 'Itens de Requisição de Viagem',
        },
        airTickets: {
          title: 'Bilhetes Aéreos',
        },
        salesIntegration: {
          title: 'Integração de Vendas',
        },
      },
      configuration: {
        profiles: {
          full: 'Consultor/Auditor',
          approver: 'Aprovador',
          requester: 'Solicitante',
          basic: 'Viajante',
        },
      },
    },
  },
};
