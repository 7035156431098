import { trpc } from '@api/bff/client';
import { dispatchToast } from '@shared/toast';
import {
  CustomFieldStatus,
  CustomFieldType,
} from 'bff/src/routers/custom-fields/enums';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CustomFieldRegisterForm } from '../types';

type Props = {
  customFieldId?: string;
  onSave?: () => void;
};

export function useCustomFieldForm({ customFieldId, onSave }: Props) {
  const customField = trpc.getCustomFieldById.useQuery(
    { id: customFieldId },
    {
      enabled: !!customFieldId,
      refetchOnWindowFocus: false,
    },
  );

  const saveCustomField = trpc.saveCustomField.useMutation();

  const customFieldForm = useForm<CustomFieldRegisterForm>({
    defaultValues: {
      type: '' as CustomFieldType,
      status: CustomFieldStatus.ACTIVE,
      required: false,
      options: [{ value: '' }],
    },
  });

  useEffect(() => {
    if (customField.data) {
      customFieldForm.reset({
        ...customField.data,
        options: customField.data?.options?.map((value) => ({ value })),
      });
    }
  }, [customField.data]);

  async function onSubmit(form: CustomFieldRegisterForm) {
    try {
      await saveCustomField.mutateAsync({ id: customFieldId, ...form });

      dispatchToast({
        type: 'success',
        content: `O campo customizado foi salvo com sucesso.`,
      });

      return onSave?.();
    } catch (err) {
      dispatchToast({
        type: 'error',
        content:
          'Ocorreu um erro ao salvar o campo customizado. Tente novamente.',
      });
    }
  }

  return {
    isLoading: !!customFieldId && customField.isFetching,
    customField,
    customFieldForm,
    onSubmit,
  };
}
