import { useFormState, useWatch } from 'react-hook-form';
import { Box, Popover } from '@mui/material';
import { Typography, Button } from '@flash-tecnologia/hros-web-ui-v2';
import React, { useState } from 'react';

export function FormStateDebug() {
  const formState = useFormState();
  const values = useWatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box position={'absolute'} bottom={10} left={10}>
      <Button variant="primary" onClick={handleClick}>
        Show Form Debug
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2} width={'100%'}>
          <Typography variant="headline10">Debug: Form Values</Typography>
          <pre>{JSON.stringify(values, null, 2)}</pre>
          <Typography variant="headline10">Debug: Errors</Typography>
          <pre>{JSON.stringify(formState.errors, null, 2)}</pre>
        </Box>
      </Popover>
    </Box>
  );
}
