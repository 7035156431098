import { TRACKING_LANDING_PAGE } from '@shared/constants/Tracking.constants';
import { gritter } from '@legacy-utils/utils.js';
import { useTracking } from '@shared/hooks/useTracking';
import { useUserAuth } from '@shared/hooks/useUserAuth';
import { useEffect, useState } from 'react';
import hero from '../../assets/travel-1.png';
import { SlideItem } from './components/slider';
import { useSolutionFeatures } from './hooks';
import { useCreateLead } from './hooks/useCreateLead';
import { ContactRequestedModal } from './sections/ContactRequestedModal';
import { Hero } from './sections/Hero';
import { LandingPageLoading } from './sections/Loading';
import { Solutions } from './sections/Solutions';
import * as SC from './styled';

const Page = () => {
  const user = useUserAuth();
  const solutions: SlideItem[] = useSolutionFeatures();
  const tracking = useTracking();
  const { sendCreateLead } = useCreateLead(user);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    tracking.trackPage({
      name: TRACKING_LANDING_PAGE,
    });
    setShowLoading(false);
  }, [tracking]);

  const callToAction = async () => {
    setShowLoadingButton(true);
    if (!(await sendCreateLead()))
      gritter.Error(
        'Não foi possível registrar o seu interesse. Por favor, tente mais tarde.',
      );
    setShowLoadingButton(false);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showLoading ? (
        <LandingPageLoading />
      ) : (
        <SC.PageContainer>
          <Hero
            img={hero}
            greeting="Solução de Viagens Corporativas da Flash!"
            title="Estamos em reformulação! 🚀"
            subtitle="Estamos trabalhando em melhorias no nosso produto Travel para oferecer uma experiência ainda mais completa e eficiente. Durante este período, gostaríamos de continuar em contato com você!"
            buttonText="Deixar Contato!"
            onClickButton={callToAction}
            loadingButton={showLoadingButton}
          />
          <Solutions
            title="Soluções para a sua empresa decolar com a Flash"
            solutions={solutions}
          />
          {/*Todo: Comentamos enquanto não resolve todo o fluxo de travel.*/}
          {/*<BigNumbers*/}
          {/*  items={numbers}*/}
          {/*  buttonText="Contrate agora!"*/}
          {/*  onClickButton={callToAction}*/}
          {/*  loadingButton={showLoadingButton}*/}
          {/*>*/}
          {/*  Conte com uma ferramenta que otimiza os{' '}*/}
          {/*  <SC.HighlightedText>números</SC.HighlightedText> da sua empresa*/}
          {/*</BigNumbers>*/}
          {/*<FutureFeaturesSection*/}
          {/*  title="E ainda tem mais! Confira o que vem por aí:"*/}
          {/*  buttonText="Quero saber mais"*/}
          {/*  features={nearFeatures}*/}
          {/*  onClickButton={callToAction}*/}
          {/*  loadingButton={showLoadingButton}*/}
          {/*/>*/}
        </SC.PageContainer>
      )}

      <ContactRequestedModal
        open={showModal}
        onActionClick={closeModal}
        userName={user.name}
      />
    </div>
  );
};

export default Page;
