import { Box } from '@mui/material';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import {
  useApprovalColumns,
  useApprovalFilters,
  useApprovalTable,
} from '../RegisterApprovalFlow/hooks/useApprovalTable';
import { RegisterApprovalFlow } from '../RegisterApprovalFlow';
import { useState } from 'react';
import { Icon } from '@components/Pills/styled';
import { TravelDataTable } from '@components/TravelDataTable';
import ApprovalTableActions from './components/ApprovalTableActions';

export function ApprovalTable() {
  const { filteredItems, isLoading, isError, refetch } = useApprovalTable();

  const columns = useApprovalColumns();
  const filters = useApprovalFilters();

  const [editingApprovalId, setEditingApprovalId] = useState<string | null>(
    null,
  );
  const [showApprovalRegister, setShowApprovalRegister] = useState(false);

  return (
    <Box width="100%">
      <Box border={1} color={'#ebe6e9'} p={2} borderRadius={2}>
        <TravelDataTable
          header={{
            title: 'Fluxo de Aprovação',
            tagLabel: 'fluxos de aprovação',
          }}
          headerEndContent={
            <Button
              size="large"
              variant={'primary'}
              onClick={() => setShowApprovalRegister(true)}
            >
              Adicionar fluxo <Icon name="IconPlus" />
            </Button>
          }
          search={{
            placeholder: 'Busca por nome, descrição do fluxo',
          }}
          columns={columns}
          filters={filters}
          rows={filteredItems || []}
          renderActions={({ item: approvalItem }) => (
            <ApprovalTableActions
              approvalFlow={approvalItem}
              onRefresh={refetch}
              onEdit={() => {
                setEditingApprovalId(approvalItem.id);
                setShowApprovalRegister(true);
              }}
            />
          )}
          isLoading={isLoading}
          isError={isError}
        />
      </Box>
      <Box>
        {showApprovalRegister && (
          <RegisterApprovalFlow
            approvalId={editingApprovalId}
            onSave={() => {
              setShowApprovalRegister(false);
            }}
            onCancel={() => setShowApprovalRegister(false)}
          />
        )}
      </Box>
    </Box>
  );
}
