'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import AirSearchResultTemplate from './air-search-result.template.js';
import ErrorBoundary from '../common/error-boundary/error-boundary.component.js';

export default class AirSearchResultComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.itensOrder, this);
    componentUtil.react.bindMethods(this.tripsManagement, this);

    this.state = {
      fareIndexSelected: 0,
      tripPartsIndexSelected: 0,
      tripParts: this.props.tripParts,
      tripItems: [],
      tripsSelectedManagement: [],
      tripItensSelected: {},
      listOrder: {},
      newOrder: false,
      displayPopupAlert: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    state.tripItensSelected = props.clearTripsSelected
      ? {}
      : state.tripItensSelected;

    return {
      tripParts: props.tripParts,
      showFaresIndexes: props.showFaresIndexes,
      tripItems: AirSearchResultComponent.getTripItems(
        props.tripParts,
        state.tripItensSelected,
      ),
      tripIndeSelected: props.tripIndeSelected,
      displayLoaderTariffedPrice: props.displayLoaderTariffedPrice
        ? props.displayLoaderTariffedPrice
        : false,
    };
  }

  render() {
    let tripPartSelected =
      this.state.tripParts[this.state.tripPartsIndexSelected];
    let showFaresIndexes = this.state.showFaresIndexes
      ? this.state.showFaresIndexes[this.state.tripIndeSelected]
      : [];

    return (
      <ErrorBoundary>
        <AirSearchResultTemplate.main
          tripPartsSelected={tripPartSelected}
          tripType={tripPartSelected ? tripPartSelected.tripPartType : null}
          separeResults={this.props.separeResults}
          tripItems={this.state.tripItems}
          fareIndexSelected={this.state.fareIndexSelected}
          showFaresIndexes={showFaresIndexes}
          executionStatistics={this.props.executionStatistics}
          isInternational={this.props.isInternational}
          orderList={this.state.orderList}
          numberOfItems={
            this.props.numberOfItems ? this.props.numberOfItems : 0
          }
          apiReturn={this.props.apiReturn ? this.props.apiReturn : false}
          tariffedPriceFareResult={this.props.tariffedPriceFareResult}
          displayPopupAlert={this.state.displayPopupAlert}
          popupAlert={this.state.popupAlert}
          displayLoaderTariffedPrice={this.state.displayLoaderTariffedPrice}
          tripPartsIndexSelected={this.state.tripPartsIndexSelected}
          onOpenFaresClicked={this.events.onOpenFaresClicked}
          onSelectTripClicked={this.events.onSelectTripClicked}
          onSelectMultipleTripClick={this.events.onSelectMultipleTripClicked}
          onIncludeInCartClicked={this.events.onIncludeInCartClicked}
          onSelectFareClicked={this.events.onSelectFareClicked}
          onOrderClicked={this.events.onOrderClicked}
          onTripClicked={this.events.onTripClicked}
          onClearTripClicked={this.events.onClearTripClicked}
          onIncludeInCartTripsManagementClick={
            this.events.onIncludeInCartTripsManagementClicked
          }
          onTryAgainTariffedPriceMultipleTripClick={
            this.tripsManagement.onTryAgainTariffedPriceMultipleTripClicked
          }
          listOrderSelect={this.listOrderSelect.getProps()}
        />
      </ErrorBoundary>
    );
  }

  events = {
    onOpenFaresClicked: function (index) {
      if (this.props.onShowFaresIndexesClick)
        this.props.onShowFaresIndexesClick(index);
    },

    onSelectFareClicked: function (indexFlight, fareIndex, includShoppingCart) {
      switch (includShoppingCart) {
        case true:
          this.tripsManagement.add(indexFlight, fareIndex, includShoppingCart);
          break;

        case false:
          let flightOptions =
            this.state.tripParts[this.state.tripPartsIndexSelected]
              .flightOptions;
          let flightSelected = { ...flightOptions[indexFlight] };
          let fareSelected = flightOptions[indexFlight].fareOptions[fareIndex];

          flightSelected.fareOptions = fareSelected;
          flightSelected.selected = false;

          let selectedTrips = [flightSelected];

          if (this.props.onSendSelectedProductCheckoutAirResultClick)
            this.props.onSendSelectedProductCheckoutAirResultClick(
              selectedTrips,
              null,
              this.props.searchIdentifier
            );
      }
    },

    onSelectTripClicked: function (indexFlight, fareIndex, sendToCheckout) {
      switch (sendToCheckout) {
        case false:
          this.tripsManagement.add(indexFlight, fareIndex);
          break;

        case true:
          let flightOptions =
            this.state.tripParts[this.state.tripPartsIndexSelected]
              .flightOptions;
          let flightSelected = { ...flightOptions[indexFlight] };
          let fareSelected = flightOptions[indexFlight].fareOptions[fareIndex];

          flightSelected.fareOptions = fareSelected;
          flightSelected.selected = false;

          let selectedTrips = [flightSelected];

          if (this.props.onSendSelectedProductCheckoutAirResultClick)
            this.props.onSendSelectedProductCheckoutAirResultClick(
              selectedTrips,
              null,
              this.props.searchIdentifier
            );
      }
    },

    onSelectMultipleTripClicked: () => {
      let tripItems = this.state.tripItems;
      let selectedTripsManagement = [];

      tripItems.forEach((trips) => {
        selectedTripsManagement.push({
          trips: [trips.tripSelected.trip],
          fareOptions: trips.tripSelected.fare,
        });
      });

      if (this.props.onSendSelectedProductCheckoutAirResultClick)
        this.props.onSendSelectedProductCheckoutAirResultClick(
          selectedTripsManagement,
          null,
          this.props.searchIdentifier
        );
    },

    onIncludeInCartClicked: function (flightIndex, fareIndex) {
      let flightOptions =
        this.state.tripParts[this.state.tripPartsIndexSelected].flightOptions;
      let flightSelected = { ...flightOptions[flightIndex] };
      let fareSelected = flightOptions[flightIndex].fareOptions[fareIndex];

      flightSelected.fareOptions = fareSelected;
      flightSelected.selected = false;

      let selectedTrips = [flightSelected];

      if (this.props.onIncludeInCartClicked)
        this.props.onIncludeInCartClicked(selectedTrips);
    },

    onIncludeInCartTripsManagementClicked: () => {
      let tripItems = this.state.tripItems;
      let selectedTripsManagement = [];

      tripItems.forEach((trips) => {
        selectedTripsManagement.push({
          trips: [trips.tripSelected.trip],
          fareOptions: trips.tripSelected.fare,
        });
      });

      if (this.props.onIncludeInCartClicked)
        this.props.onIncludeInCartClicked(selectedTripsManagement, true);
    },

    onOrderClicked: function () {
      let newOrder = !this.state.newOrder;
      let order = newOrder ? 'ascending' : 'descending';

      this.setState({
        orderList: order,
        newOrder: newOrder,
      });

      if (this.props.onOrderClick) this.props.onOrderClick(order);
    },

    onTripClicked: function (index) {
      this.setState({ tripPartsIndexSelected: index });

      if (this.props.onTripClicked) this.props.onTripClicked(index);
    },

    onClearTripClicked: function (index) {
      this.tripsManagement.changeClearTripClicked(index);
    },

    onClosePopupClicked: () => {
      this.setState({ displayPopupAlert: false });
    },

    onOkPopupAlertClicked: () => {
      this.setState({ displayPopupAlert: false });
    },
  };
  listOrderSelect = {
    getProps: () => {
      return {
        data: this.state.listOrder,
        placeholder: 'Ordenar por',
        options: {
          items: [
            { checked: false, value: 1, label: 'Menor preço' },
            { checked: false, value: 2, label: 'Menor duração' },
            { checked: false, value: 3, label: 'Menor preço e menor duração' },
            { checked: false, value: 4, label: 'Maior preço' },
            { checked: false, value: 5, label: 'Horário de saida' },
          ],
          events: {
            onSelected: (selectedItem) => {
              this.setState({
                orderList: selectedItem,
              });

              if (this.props.onOrderClick)
                this.props.onOrderClick(selectedItem.value);
            },
          },
        },
      };
    },
  };

  static getTripItems(tripsList, tripItemsSelected) {
    let tripItems = tripsList.map((trip, index) => {
      return {
        label: trip.placeholderIdentifier,
        tripType: trip.tripPartType,
        tripSelected: tripItemsSelected[index],
      };
    });

    return tripItems;
  }

  tripsManagement = {
    setMemory: function () {
      let tripItems = AirSearchResultComponent.getTripItems(
        this.state.tripParts,
      );
      this.setState({
        tripItems: tripItems,
      });
    },

    add: function (flightIndex, fareOptionIndex) {
      let tripItensSelected = this.state.tripItensSelected;
      let tripPartsIndex = this.state.tripPartsIndexSelected;
      let tripSelected =
        this.state.tripParts[tripPartsIndex].flightOptions[flightIndex]
          .trips[0];
      let fareSelected =
        this.state.tripParts[tripPartsIndex].flightOptions[flightIndex]
          .fareOptions[fareOptionIndex];

      if (tripSelected.airLine.code == 'G3') {
        let golTrips = 0;
        let keys = Object.keys(tripItensSelected);

        for (let i = 0; keys.length > i; i++) {
          let tripItem = tripItensSelected[keys[i]];
          let code = tripItem ? tripItem.trip.airLine.code : null;

          if (code == 'G3') golTrips += 1;

          if (golTrips >= 2) {
            this.tripsManagement.popupGolAlert();
            return;
          }
        }
      }

      if (tripItensSelected[tripPartsIndex]) {
        this.tripsManagement.popupAlertConfig();
      } else {
        tripItensSelected[tripPartsIndex] = {
          trip: tripSelected,
          fare: fareSelected,
        };
        this.setState({ tripItensSelected: tripItensSelected });

        let allSelected = true;
        for (let i = 0; i < this.state.tripItems.length; i++) {
          allSelected = !(
            Object.keys(tripItensSelected).length <
              this.state.tripItems.length || tripItensSelected[i] == undefined
          );
          if (!allSelected) break;
        }

        if (allSelected) {
          if (this.props.onAllTripsSelect)
            this.props.onAllTripsSelect(
              tripItensSelected,
              this.props.searchIdentifier,
            );
        }
      }

      this.tripsManagement.openNextTrip();
    },

    openNextTrip: () => {
      let tripPartsIndexSelected = this.state.tripPartsIndexSelected;
      let tripItemsAmount = this.state.tripItems.length;
      let tripItensSelected = this.state.tripItensSelected;

      if (
        tripPartsIndexSelected < tripItemsAmount - 1 &&
        !tripItensSelected[tripPartsIndexSelected + 1]
      ) {
        tripPartsIndexSelected = tripPartsIndexSelected + 1;
        this.setState({ tripPartsIndexSelected });
      } else {
        for (let i = 0; i < this.state.tripItems.length; i++) {
          if (!tripItensSelected[i]) {
            tripPartsIndexSelected = i;
            break;
          }
        }
      }

      this.setState({ tripPartsIndexSelected });
      if (this.props.onTripClicked)
        this.props.onTripClicked(tripPartsIndexSelected);
    },

    onTryAgainTariffedPriceMultipleTripClicked: () => {
      let tripItensSelected = this.state.tripItensSelected;

      if (this.props.onTryAgainTariffedPriceMultipleTripClick)
        this.props.onTryAgainTariffedPriceMultipleTripClick(
          tripItensSelected,
          this.props.searchIdentifier,
        );
    },

    changeClearTripClicked: function (index) {
      let tripItensSelected = this.state.tripItensSelected;

      delete tripItensSelected[index];

      this.setState({ tripItensSelected: tripItensSelected });

      if (this.props.onClearTripMaganerItemClick)
        this.props.onClearTripMaganerItemClick();
    },

    changeTripsListResults: function (tripIndex) {
      this.setState({
        tripPartsIndexSelected: tripIndex,
      });
    },

    tripsSelectedList: (index) => {
      tripsSelectedManagement = this.state.tripsSelectedManagement;
    },

    popupAlertConfig: () => {
      this.setState({
        popupAlert: {
          okButtonLabel: 'Entendi',
          title: 'Aviso',
          onClosePopupClick: this.events.onClosePopupClicked,
          onOkPopupAlertClick: this.events.onOkPopupAlertClicked,
          messages: [
            'Trecho já selecionado!',
            'Exclua o trecho já selecinado para adicionar outro.',
          ],
        },
        displayPopupAlert: true,
      });
    },
    popupGolAlert: () => {
      this.setState({
        popupAlert: {
          okButtonLabel: 'Entendi',
          title: 'Aviso',
          onClosePopupClick: this.events.onClosePopupClicked,
          onOkPopupAlertClick: this.events.onOkPopupAlertClicked,
          messages: [
            'Só é possivel ter 2 trechos da companhia Gol',
            'Favor escolher outra companhia aérea.',
          ],
        },
        displayPopupAlert: true,
      });
    },
  };
}
