import { FieldFactory } from '@components/FieldFactory';
import { PolicyRuleConfigurationField } from '../../types';

type Props = {
  settings: PolicyRuleConfigurationField;
  value: unknown;
  onChange: (value: unknown) => void;
};

export function PolicyRuleField({ settings, value, onChange }: Props) {
  return <FieldFactory {...settings} value={value} onChange={onChange} />;
}

export default PolicyRuleField;
