import { Modal } from '@components/Modal';
import { CircularProgress, TextArea } from '@flash-tecnologia/hros-web-ui-v2';
import { useMemo, useState } from 'react';
import { PaymentFormsList } from './PaymentFormsList';
import { ApprovalStatus, CardStatus, FrameworkErrorsCodes } from './models';
import * as SC from './styled';

export interface PaymentFormsProps {
  type: number;
  storedCreditCardId: string;
  creditCard: {
    number: string;
    printedName: string;
    cardName: string;
  };
  flashPayment?: {
    balance: number;
  };
  productType?: number;
  success?: boolean;
  status?: CardStatus;
}

function sortByDesc(a: PaymentFormsProps, b: PaymentFormsProps) {
  return b.type - a.type;
}
export interface FrameworkError {
  messageCode: string;
  errorMessage: string;
  friendlyMessage: string;
  source: number;
}

export type onApprovalClickResponse = {
  successful: boolean;
  paymentForms?: PaymentFormsProps[];
  errors?: FrameworkError[];
};

interface ApprovalRequestedModalProps {
  type: 'refuse' | 'cancel' | 'approve';
  data?: string;
  descriptionJustification?: string;
  isOpen: boolean;
  onCloseClick?: () => void;
  onCancelClick?: () => void;
  onApprovalSuccess?: () => void;
  onApprovalClick?: (
    useDefaultPayment?: boolean,
  ) => Promise<onApprovalClickResponse>;
  setDescriptionJustification?: (value: any) => void;
  onRepprovalClick?: () => void;
}

export function ApprovalRequestedModal({
  type,
  data,
  onCloseClick,
  onCancelClick,
  onApprovalClick,
  onRepprovalClick,
  isOpen,
  descriptionJustification,
  setDescriptionJustification,
}: ApprovalRequestedModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [approvalCompleted, setApprovalCompleted] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState<ApprovalStatus | null>(
    null,
  );
  const [paymentForms, setPaymentForms] = useState<PaymentFormsProps[]>(null);
  const [subtitleText, setSubtitleText] = useState<string | null>(null);
  const [useDefaultPayment, setUseDefaultPayment] = useState<boolean>(false);
  const [frameworkError, setFrameworkError] = useState<string>('');

  const isCardExpiredOrBlockedError = useMemo(
    () =>
      frameworkError === FrameworkErrorsCodes.expiredCard ||
      frameworkError === FrameworkErrorsCodes.blockedCard,
    [frameworkError],
  );

  const handleApprovalClick = async () => {
    try {
      setIsLoading(true);

      const response = await onApprovalClick(useDefaultPayment);

      if (response) {
        setApprovalStatus(
          response.successful ? ApprovalStatus.Success : ApprovalStatus.Error,
        );
        if (response.successful) {
          setSuccessfulStates(response);
        } else {
          setFailureStates(response);
        }
      }
    } catch (error) {
      handleApprovalError(error);
    } finally {
      setIsLoading(false);
    }

    function handleApprovalError(error) {
      setApprovalStatus(ApprovalStatus.Error);

      if (error.errors && error.errors.length > 0) {
        setSubtitleText(error.errors[0].message);
        if (error.errors[0].code === 'BR-SYS-009') {
          onCancelClick();
          return;
        }
      }

      setUseDefaultPayment(true);
      setPaymentForms(error.paymentForms || null);
    }

    function setFailureStates(response: onApprovalClickResponse) {
      setApprovalStatus(ApprovalStatus.Error);
      setUseDefaultPayment(true);
      const processedPaymentForms = response.paymentForms
        .map((paymentForm): PaymentFormsProps => {
          const error = response.errors.find(
            (error) => error.source === paymentForm.productType,
          );
          if (error) {
            if (frameworkError !== FrameworkErrorsCodes.expiredCard)
              setFrameworkError(error.messageCode);
            return {
              ...paymentForm,
              status: parseFrameworkErrorToCardStatus(error.messageCode),
              success: false,
            };
          }
          return {
            ...paymentForm,
            status: CardStatus.generated,
            success: true,
          };
        })
        .sort(sortByDesc);
      setPaymentForms(processedPaymentForms || null);
    }

    function setSuccessfulStates(response: onApprovalClickResponse) {
      setApprovalCompleted(true);

      if (response?.paymentForms != undefined)
        setPaymentForms(
          response.paymentForms
            .map((payment) => {
              return {
                ...payment,
                success: true,
                status: CardStatus.generated,
              };
            })
            .sort(sortByDesc) || null,
        );

      setSubtitleText(
        'A requisição será encaminhada para a próxima pessoa do fluxo de aprovação.',
      );
    }
  };

  const titleMessages = {
    [ApprovalStatus.Loading]: 'Processando a sua aprovação...',
    [ApprovalStatus.Success]: 'Requisição aprovada com sucesso!',
    [ApprovalStatus.Error]: 'Houve um erro ao aprovar a requisição',
  };

  const buttonLabels = {
    [ApprovalStatus.Loading]: 'Carregando...',
    [ApprovalStatus.Success]: 'Concluir',
    [ApprovalStatus.Error]: 'Usar pagamento padrão',
    refuse: 'Confirmar',
  };

  const errorsInstructions = {
    [FrameworkErrorsCodes.blockedCard]: (
      <>
        <p>
          Para seguir com a aprovação, você deve trocar a forma de pagamento
          para o padrão da empresa.
        </p>
        <p>Você também pode optar por reprovar essa requisição.</p>
      </>
    ),
    [FrameworkErrorsCodes.expiredCard]: (
      <>
        <p>
          Para seguir com a aprovação, você deve trocar a forma de pagamento
          para o padrão da empresa.
        </p>
        <p>Você também pode optar por reprovar essa requisição.</p>
      </>
    ),
    [FrameworkErrorsCodes.withoutBalanceCard]: (
      <>
        Você pode tentar novamente mais tarde ou trocar as formas de pagamento
        destes itens para o padrão da empresa.
      </>
    ),
  };
  const renderTitleMessage = () => titleMessages[approvalStatus] || data;
  const renderButtonLabel = () => {
    if (type === 'refuse') {
      return buttonLabels.refuse;
    }
    return buttonLabels[approvalStatus] || 'Confirmar';
  };

  const iconConfig = {
    isLoading: {
      component: CircularProgress,
      props: { variant: 'indeterminate', size: 48 },
    },
    success: {
      component: Modal.Header.Icon,
      props: { icon: 'IconCheck', label: '', color: '#2ED9B8' },
    },
    error: {
      component: Modal.Header.Icon,
      props: { icon: 'IconX', label: '', color: '#C96C01' },
    },
    default: {
      component: Modal.Header.Icon,
      props: { icon: 'IconExclamationCircle', label: '' },
    },
  };

  const { component: IconComponent, props: iconProps } =
    iconConfig[isLoading ? 'isLoading' : approvalStatus] || iconConfig.default;

  return (
    <Modal.Root open={isOpen} size="sm">
      <Modal.Header.Root
        onCloseClick={onCloseClick}
        onCancelClick={onCancelClick}
        showDivider={false}
        collapse={true}
        hasDismissButton={true}
      >
        <SC.IconApprovalContainer>
          <IconComponent {...iconProps} />
        </SC.IconApprovalContainer>
        <Modal.Header.Title textAlign="center">
          {renderTitleMessage()}
        </Modal.Header.Title>
        {subtitleText && (
          <Modal.Header.Subtitle textAlign="center">
            {subtitleText}
          </Modal.Header.Subtitle>
        )}
      </Modal.Header.Root>

      {paymentForms && (
        <Modal.Body collapse={true}>
          <PaymentFormsList
            paymentForms={paymentForms}
            approvalStatus={approvalStatus}
          />
          <SC.Text variant="body3">
            {errorsInstructions[frameworkError]}
          </SC.Text>
        </Modal.Body>
      )}

      {type === 'refuse' && (
        <Modal.Body collapse={true}>
          <TextArea
            value={descriptionJustification}
            onChange={(event) =>
              setDescriptionJustification(
                (event.target as HTMLTextAreaElement).value,
              )
            }
          />
        </Modal.Body>
      )}

      <Modal.Footer.Root>
        {isCardExpiredOrBlockedError && (
          <Modal.Footer.ActionButton
            variant="secondary"
            variantType="error"
            onActionClick={onRepprovalClick}
          >
            Reprovar
          </Modal.Footer.ActionButton>
        )}
        {!approvalCompleted && !isCardExpiredOrBlockedError && (
          <Modal.Footer.DismissButton onCancelClick={onCancelClick}>
            {approvalStatus == 'error' ? 'Tentar mais tarde' : 'Cancelar'}
          </Modal.Footer.DismissButton>
        )}

        <Modal.Footer.ActionButton
          onActionClick={handleApprovalClick}
          actionDisabled={
            isLoading ||
            (type === 'refuse' &&
              (!descriptionJustification ||
                descriptionJustification.trim() === ''))
          }
        >
          {renderButtonLabel()}
        </Modal.Footer.ActionButton>
      </Modal.Footer.Root>
    </Modal.Root>
  );
}
function parseFrameworkErrorToCardStatus(messageCode: string): CardStatus {
  switch (messageCode) {
    case FrameworkErrorsCodes.blockedCard:
      return CardStatus.blocked;
    case FrameworkErrorsCodes.expiredCard:
      return CardStatus.expired;
    case FrameworkErrorsCodes.withoutBalanceCard:
      return CardStatus.withoutBalance;
    default:
      return CardStatus.generated;
  }
}
