import React, { useState, useEffect, useCallback } from 'react';
import { TransferList } from '@flash-tecnologia/hros-web-ui-v2';
import { useProfileConfigurationStore } from '../../store/ProfileConfiguration.store';
import { useTransferConfirmationModal } from './TransferConfirmationModal';
import { TravelProfileType, Employee } from '../../types';
import { useProfiles } from '../../ProfileTable/hooks/useProfiles';
import { DataType } from '@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/types';
import { EmployeeInformation } from '@components/EmployeeInformation';

type PropsTransferList = {
  profileType: TravelProfileType;
};

const getProfileLabel = (type: TravelProfileType): string => {
  const labels = {
    [TravelProfileType.BASIC]: 'Viajante',
    [TravelProfileType.REQUESTER]: 'Solicitante',
    [TravelProfileType.FULL]: 'Consultor/Auditor',
    [TravelProfileType.APPROVER]: 'Aprovador',
  };
  return labels[type];
};

const TransferListEmployees: React.FC<PropsTransferList> = ({
  profileType,
}) => {
  const { userOptions, profileData, companyProfiles, isLoadingProfile } =
    useProfiles(profileType);

  const { showTransferConfirmation } = useTransferConfirmationModal();
  const { setSelectedEmployeeIds, setProfileType } =
    useProfileConfigurationStore();

  // Adicionar estados para armazenar os termos de busca
  const [leftSearchTerm, setLeftSearchTerm] = useState('');
  const [rightSearchTerm, setRightSearchTerm] = useState('');

  // Adicionar estados para armazenar os resultados filtrados
  const [filteredAvailableEmployees, setFilteredAvailableEmployees] = useState<
    Employee[]
  >([]);
  const [filteredSelectedEmployees, setFilteredSelectedEmployees] = useState<
    Employee[]
  >([]);

  const [availableEmployees, setAvailableEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [checkedAvailableIds, setCheckedAvailableIds] = useState<string[]>([]);
  const [checkedSelectedIds, setCheckedSelectedIds] = useState<string[]>([]);

  // Função auxiliar para ordenar funcionários
  const sortEmployees = useCallback((employees: Employee[]) => {
    return [...employees].sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  // Modificar a função transformEmployeesToTransferList para adicionar o estado allChecked
  const transformEmployeesToTransferList = useCallback(
    (
      employees: Employee[],
      checkedIds: string[],
      filteredEmployees: Employee[],
    ) => {
      const allFilteredChecked =
        filteredEmployees.length > 0 &&
        filteredEmployees.every((emp) => checkedIds.includes(emp.id));

      return {
        data: employees.map((employee) => ({
          _id: employee.id,
          checked: checkedIds.includes(employee.id),
          hidden: false,
          name: employee.name,
          email: employee.email,
          currentProfile: employee.currentProfile?.label,
          ...employee,
        })),
        allChecked: allFilteredChecked,
      };
    },
    [],
  );

  const columns = [
    {
      Header: 'Nome',
      accessor: 'name',
      Cell: ({ row }: any) => (
        <EmployeeInformation employee={row.original} showProfileTag />
      ),
    },
  ];

  // Função para filtrar funcionários com base no termo de busca
  const filterEmployees = useCallback(
    (employees: Employee[], searchTerm: string) => {
      if (!searchTerm.trim()) return sortEmployees(employees);

      const normalizedSearch = searchTerm.toLowerCase().trim();
      const filtered = employees.filter((employee) => {
        const nameMatch = employee.name
          .toLowerCase()
          .includes(normalizedSearch);
        const emailMatch = employee.email
          .toLowerCase()
          .includes(normalizedSearch);
        const profileMatch = employee.currentProfile?.label
          .toLowerCase()
          .includes(normalizedSearch);

        return nameMatch || emailMatch || (profileMatch ?? false);
      });

      return sortEmployees(filtered);
    },
    [sortEmployees],
  );

  useEffect(() => {
    setProfileType(profileType);
  }, [profileType, setProfileType]);

  const mapUsersWithCurrentProfiles = useCallback(
    (users: Employee[]) => {
      return users.map((user) => {
        let currentProfile:
          | { type: TravelProfileType; label: string }
          | undefined;

        Object.entries(companyProfiles).forEach(([type, profile]) => {
          if (
            type !== profileType &&
            profile.employees.some((emp) => emp.id === user.id)
          ) {
            currentProfile = {
              type: type as TravelProfileType,
              label: getProfileLabel(type as TravelProfileType),
            };
          }
        });

        return { ...user, currentProfile };
      });
    },
    [companyProfiles, profileType],
  );

  // Modificar o useEffect para limpar os estados quando começar um novo carregamento
  useEffect(() => {
    if (isLoadingProfile) {
      // Limpa os estados relacionados à lista de selecionados
      setSelectedEmployees([]);
      setFilteredSelectedEmployees([]);
      setCheckedSelectedIds([]);
      return; // Retorna aqui para não executar o resto da lógica enquanto está carregando
    }

    if (!userOptions || !profileData || !companyProfiles) return;

    const selected = profileData.employees || [];
    const available = userOptions.filter(
      (user) => !selected.some((employee) => employee.id === user.id),
    );

    const mappedAvailable = mapUsersWithCurrentProfiles(available);
    const mappedSelected = mapUsersWithCurrentProfiles(selected);

    setAvailableEmployees(sortEmployees(mappedAvailable));
    setSelectedEmployees(sortEmployees(mappedSelected));
    setCheckedAvailableIds([]);
    setCheckedSelectedIds([]);
  }, [
    userOptions,
    profileData,
    companyProfiles,
    mapUsersWithCurrentProfiles,
    sortEmployees,
    isLoadingProfile, // Adicionado isLoadingProfile como dependência
  ]);

  // Modificar o useEffect do selectedEmployeeIds para não atualizar durante o carregamento
  useEffect(() => {
    if (!isLoadingProfile) {
      setSelectedEmployeeIds(selectedEmployees.map((employee) => employee.id));
    }
  }, [selectedEmployees, setSelectedEmployeeIds, isLoadingProfile]);

  const handleLeftListCheck = useCallback(
    ({ data, allChecked }: { data: DataType; allChecked?: boolean }) => {
      if (allChecked !== undefined) {
        // Se allChecked é true, seleciona todos os itens filtrados
        // Se allChecked é false, desmarca todos
        if (allChecked) {
          const allFilteredIds = filteredAvailableEmployees.map(
            (emp) => emp.id,
          );
          setCheckedAvailableIds(allFilteredIds);
        } else {
          setCheckedAvailableIds([]);
        }
      } else {
        // Atualização individual de checkboxes
        const newCheckedIds = data
          .filter((item) => item.checked)
          .map((item) => item._id);
        setCheckedAvailableIds(newCheckedIds);
      }
    },
    [filteredAvailableEmployees],
  );

  const handleRightListCheck = useCallback(
    ({ data, allChecked }: { data: DataType; allChecked?: boolean }) => {
      if (allChecked !== undefined) {
        // Se allChecked é false, desmarca todos
        if (allChecked) {
          const allFilteredIds = filteredSelectedEmployees.map((emp) => emp.id);
          setCheckedSelectedIds(allFilteredIds);
        } else {
          setCheckedSelectedIds([]);
        }
      } else {
        // Atualização individual de checkboxes
        const newCheckedIds = data
          .filter((item) => item.checked)
          .map((item) => item._id);
        setCheckedSelectedIds(newCheckedIds);
      }
    },
    [filteredSelectedEmployees],
  );

  // Modificar o handleTransfer para manter a ordenação ao transferir usuários
  const handleTransfer = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (result: {
      leftList: { allChecked: boolean; data: DataType };
      rightList: { allChecked: boolean; data: DataType };
    }) => {
      // Verificar se há itens selecionados na lista direita (para remoção)
      const employeesToRemove = selectedEmployees.filter((emp) =>
        checkedSelectedIds.includes(emp.id),
      );

      if (employeesToRemove.length > 0) {
        // Remover usuários da lista de selecionados e adicionar de volta aos disponíveis
        setSelectedEmployees((prev) =>
          sortEmployees(
            prev.filter((emp) => !checkedSelectedIds.includes(emp.id)),
          ),
        );
        setAvailableEmployees((prev) =>
          sortEmployees([...prev, ...employeesToRemove]),
        );
        setCheckedSelectedIds([]);
        return;
      }

      // Lógica para mover da esquerda para a direita
      const employeesToMove = availableEmployees.filter((emp) =>
        checkedAvailableIds.includes(emp.id),
      );

      if (employeesToMove.length > 0) {
        const employeesInOtherProfiles = employeesToMove.filter(
          (employee) => employee.currentProfile !== undefined,
        );

        if (employeesInOtherProfiles.length > 0) {
          showTransferConfirmation({
            employeeCount: employeesToMove.length,
            employeesWithProfile: employeesInOtherProfiles.length,
            profileLabel: getProfileLabel(profileType),
            onConfirm: () => {
              setSelectedEmployees((prev) =>
                sortEmployees([...prev, ...employeesToMove]),
              );
              setAvailableEmployees((prev) =>
                sortEmployees(
                  prev.filter((emp) => !checkedAvailableIds.includes(emp.id)),
                ),
              );
              setCheckedAvailableIds([]);
            },
          });
        } else {
          setSelectedEmployees((prev) =>
            sortEmployees([...prev, ...employeesToMove]),
          );
          setAvailableEmployees((prev) =>
            sortEmployees(
              prev.filter((emp) => !checkedAvailableIds.includes(emp.id)),
            ),
          );
          setCheckedAvailableIds([]);
        }
      }
    },
    [
      availableEmployees,
      selectedEmployees,
      checkedAvailableIds,
      checkedSelectedIds,
      profileType,
      showTransferConfirmation,
      sortEmployees,
    ],
  );

  const handleRemoveAll = useCallback(() => {
    setAvailableEmployees((prev) =>
      sortEmployees([...prev, ...selectedEmployees]),
    );
    setSelectedEmployees([]);
    setCheckedSelectedIds([]);
  }, [selectedEmployees, sortEmployees]);

  useEffect(() => {
    setFilteredAvailableEmployees(
      filterEmployees(availableEmployees, leftSearchTerm),
    );
  }, [availableEmployees, leftSearchTerm, filterEmployees]);

  useEffect(() => {
    setFilteredSelectedEmployees(
      filterEmployees(selectedEmployees, rightSearchTerm),
    );
  }, [selectedEmployees, rightSearchTerm, filterEmployees]);

  const handleSearch = useCallback(
    (text: string, listType: 'left' | 'right') => {
      if (listType === 'left') {
        setLeftSearchTerm(text);
      } else {
        setRightSearchTerm(text);
      }
    },
    [],
  );

  return (
    <TransferList
      columns={columns}
      disabled={false}
      onTransfer={handleTransfer}
      defaultSortBy="name"
      defaultSortOrder="asc"
      leftList={{
        title: 'Usuários Disponíveis',
        ...transformEmployeesToTransferList(
          filteredAvailableEmployees,
          checkedAvailableIds,
          filteredAvailableEmployees,
        ),
        total: availableEmployees.length,
        loading: isLoadingProfile,
        onCheck: handleLeftListCheck,
        onSearch: (text) => handleSearch(text, 'left'),
        emptyStageMessage: leftSearchTerm
          ? 'Nenhum usuário encontrado para esta busca'
          : 'Nenhum usuário disponível',
      }}
      rightList={{
        title: 'Usuários Selecionados',
        ...transformEmployeesToTransferList(
          filteredSelectedEmployees,
          checkedSelectedIds,
          filteredSelectedEmployees,
        ),
        total: selectedEmployees.length,
        loading: isLoadingProfile,
        onCheck: handleRightListCheck,
        onSearch: (text) => handleSearch(text, 'right'),
        emptyStageMessage: rightSearchTerm
          ? 'Nenhum usuário encontrado para esta busca'
          : 'Nenhum usuário selecionado',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRemoveAll: handleRemoveAll,
      }}
    />
  );
};

export default TransferListEmployees;
