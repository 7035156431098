import React from 'react';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Grid } from '@mui/material';
import useModalStore from '../../../../../store/Modal.store';

export const useTransferConfirmationModal = () => {
  const { openModal, closeModal } = useModalStore();

  const showTransferConfirmation = ({
    employeeCount,
    employeesWithProfile,
    profileLabel,
    onConfirm,
  }: {
    employeeCount: number;
    employeesWithProfile: number;
    profileLabel: string;
    onConfirm: () => void;
  }) => {
    const getMessage = () => {
      const totalText = employeeCount === 1 ? 'pessoa' : 'pessoas';
      const profileText =
        employeesWithProfile === 1 ? 'está vinculada' : 'estão vinculadas';

      if (employeeCount === employeesWithProfile) {
        return `${
          employeeCount === 1 ? 'A' : 'As'
        } ${employeeCount} ${totalText} selecionada${
          employeeCount > 1 ? 's' : ''
        } ${profileText} a ${
          employeesWithProfile === 1 ? 'outro perfil' : 'outros perfis'
        }. Esta nova atribuição removerá ${
          employeesWithProfile === 1
            ? 'a atribuição do perfil anterior'
            : 'as atribuições dos perfis anteriores'
        }.`;
      }

      return `Das ${employeeCount} ${totalText} selecionadas, ${employeesWithProfile} ${profileText} a ${
        employeesWithProfile === 1 ? 'outro perfil' : 'outros perfis'
      }. Esta nova atribuição removerá ${
        employeesWithProfile === 1
          ? 'a atribuição do perfil anterior'
          : 'as atribuições dos perfis anteriores'
      }.`;
    };

    const content = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {getMessage()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            Confirma a transferência para o perfil {profileLabel}?
          </Typography>
        </Grid>
      </Grid>
    );

    openModal({
      isOpen: true,
      title: 'Atenção: Confirmação de Transferência',
      content,
      primaryButtonLabel: 'Confirmar',
      primaryButtonAction: () => {
        onConfirm();
        closeModal();
      },
      secondaryButtonLabel: 'Cancelar',
      secondaryButtonAction: closeModal,
    });
  };

  return {
    showTransferConfirmation,
  };
};
