import { SearchField } from '@flash-tecnologia/hros-web-ui-v2';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { dataSources } from '@legacy-dataSources/travelRequests.dataSources';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import { FormSection } from '@shared/components/Form/FormSection';

export function TravelCostCenter() {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [optionsCostCenters, setOptionsCostCenters] = useState<any[]>([]);
  const contextId = useTravelStore((state) => state.contextId);
  const updateCostCenter = useTravelStore((state) => state.updateCostCenter);
  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = async (searchText) => {
    setIsLoading(true);
    try {
      const loadOptions = {
        keywords: searchText,
        contextId: contextId,
      };
      const responses = await dataSources.costCenter.load(loadOptions);
      setOptionsCostCenters(responses);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormSection title="Centro de custo">
      <Controller
        control={control}
        name={'cost_center'}
        render={({ field: { onChange, value } }) => (
          <SearchField
            {...(onChange as any)}
            onInputChange={(event, value) => {
              loadOptions(value);
            }}
            onSearchChange={(event, selectedItem) => {
              if (selectedItem) {
                updateCostCenter({
                  code: selectedItem.code,
                  name: selectedItem.label,
                  id: selectedItem.value,
                });
                onChange(selectedItem);
              }
            }}
            searchable={true}
            value={value}
            options={optionsCostCenters}
            noOptionsText={'Nenhum centro de custo encontrado'}
            label="Selecione centro de custo"
            error={!!errors.cost_center}
            loading={isLoading}
            helperText={
              <DisplayErrorFormValidation
                errors={errors}
                fieldName="cost_center"
              />
            }
          />
        )}
      />
    </FormSection>
  );
}
