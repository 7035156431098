import {
  BoxAdornment,
  TextField,
  Toggle,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import {
  Controller,
  ControllerRenderProps,
  UseFormReturn,
} from 'react-hook-form';

import { CodeField } from '@components/CodeField';
import { generateCodeByText } from '@utils/code.utils';
import { ReasonStatus } from 'bff/src/routers/reasons/enums';
import { ChangeEvent, useState } from 'react';
import { ReasonRegisterFormData } from '../../types';
import { Container, FieldDataWrapper } from './styled';

type Props = {
  reasonForm: UseFormReturn<ReasonRegisterFormData>;
  isEditing?: boolean;
};

export const ReasonForm = ({ reasonForm, isEditing }: Props) => {
  const [shouldGenerateCode, setShouldGenerateCode] = useState(!isEditing);

  function onNameChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<ReasonRegisterFormData, 'name'>,
  ) {
    field.onChange(e);

    if (!shouldGenerateCode) return;

    const generatedCode = generateCodeByText(e.target.value);

    reasonForm.setValue('code', generatedCode);
  }

  return (
    <Container>
      <FieldDataWrapper>
        <Controller
          control={reasonForm.control}
          name="name"
          render={({ field }) => (
            <TextField
              label="Titulo do motivo da viagem"
              fullWidth
              required
              inputProps={{
                maxlength: 255,
              }}
              {...field}
              onChange={(e) => onNameChange(e, field)}
            />
          )}
          rules={{ required: true }}
        />

        <Controller
          control={reasonForm.control}
          name="code"
          render={({ field }) => (
            <CodeField
              label="Código"
              required
              {...field}
              onChange={(e) => {
                field.onChange(e);

                setShouldGenerateCode(false);
              }}
            />
          )}
          rules={{ required: true }}
        />

        <Box>
          <BoxAdornment
            title="Ativar motivo de viagem"
            description="Quando ativo, o motivo de viagem aparece como uma seleção disponivel para o usuário ao solicitar uma viagem."
            leftAdornment={
              <Controller
                control={reasonForm.control}
                name="status"
                render={({ field }) => (
                  <Toggle
                    checked={field.value === ReasonStatus.ACTIVE}
                    onChange={(e, checked) =>
                      field.onChange(
                        checked ? ReasonStatus.ACTIVE : ReasonStatus.INACTIVE,
                      )
                    }
                  />
                )}
              />
            }
          />
        </Box>

        <Box>
          <BoxAdornment
            title="Descrição obrigatória"
            description="Quando ativo, o usuário deve preencher o campo de descrição do motivo ao solicitar uma viagem."
            leftAdornment={
              <Controller
                control={reasonForm.control}
                name="requireDetails"
                render={({ field }) => (
                  <Toggle
                    checked={field.value}
                    onChange={(e, checked) => field.onChange(checked)}
                  />
                )}
              />
            }
          />
        </Box>
      </FieldDataWrapper>
    </Container>
  );
};
