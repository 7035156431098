import { HTMLAttributes } from 'react';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

import { FixedMessageOption, Option } from '..';
import { AutocompleteMessageOptions } from '../AutocompleteMessageOptions';
import { FixedOptions } from '../hooks/useAutocompleteHelper';
import * as SC from './styled';

export interface LoaderProps {
  renderOptionProps?: HTMLAttributes<HTMLLIElement>;
  fixedMessageOption?: FixedMessageOption;
  option?: Option;
}

export const RenderOption = ({
  renderOptionProps,
  option,
  fixedMessageOption,
}: LoaderProps) => {
  if (option.value == FixedOptions.FIXED_MESSAGE) {
    return (
      <SC.FixedMessageContainer key={option?.value}>
        <AutocompleteMessageOptions
          {...fixedMessageOption}
          image={fixedMessageOption.image}
        />
      </SC.FixedMessageContainer>
    );
  }
  return (
    <SC.OptionContainer {...renderOptionProps} key={option?.value}>
      <SC.OptionContent>
        {Boolean(option?.icon) && (
          <Icons name={option?.icon} size={24} fill="transparent" />
        )}
        <div>
          <SC.OptionLabel variant="body3">{option?.label}</SC.OptionLabel>
          {Boolean(option?.caption) && (
            <SC.OptionCaption variant="body3">
              {option?.caption}
            </SC.OptionCaption>
          )}
        </div>
      </SC.OptionContent>
    </SC.OptionContainer>
  );
};
