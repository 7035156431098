import {
  Tag,
  TextField,
  TextFieldProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Autocomplete, Box } from '@mui/material';
import { useRef } from 'react';

type Props = Pick<TextFieldProps, 'label' | 'helperText'> & {
  value?: string[];
  onChange?: (value: string[]) => void;
};

export const TagField = ({ label, helperText, onChange, value }: Props) => {
  const inputRef = useRef<HTMLInputElement>();

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      defaultValue={value || []}
      freeSolo // allows to write and insert new options
      renderTags={(value, getTagProps) =>
        value.map((option, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });

          return (
            <Box onClick={tagProps.onDelete} mr={0.7} mb={0.2}>
              <Tag variant="gray" size="small" key={key}>
                {option}
              </Tag>
            </Box>
          );
        })
      }
      onInputChange={(event, newInputValue) => {
        // add tag when press comma
        if (newInputValue.endsWith(',')) {
          inputRef.current?.blur();
          inputRef.current?.focus();
        }
      }}
      autoSelect
      onChange={(e, newValue) => onChange?.(newValue as string[])}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          helperText={helperText}
          label={label}
          placeholder="Insira os valores"
          fullWidth
          ref={inputRef}
        />
      )}
    />
  );
};
