import { useTranslation } from '@components/I18n';
import { TravelProfileType } from '../types';

export function useProfileTitle(profileType: TravelProfileType): string {
  const { t } = useTranslation();

  const profileTitles = {
    [TravelProfileType.FULL]: t('pages.configuration.profiles.full'),
    [TravelProfileType.APPROVER]: t('pages.configuration.profiles.approver'),
    [TravelProfileType.REQUESTER]: t('pages.configuration.profiles.requester'),
    [TravelProfileType.BASIC]: t('pages.configuration.profiles.basic'),
  };

  return profileTitles[profileType] || '';
}
