import React from 'react';

export interface ApprovalItem {
  id: string;
  name: string;
  approvalType: string;
  description: string;
  priority: number;
  status: 'ACTIVE' | 'INACTIVE';
}

export interface ISummaryApprovalFlow {
  id: number;
  type: ECustomApprovalType;
  name: string;
  active: boolean;
  description: string;
  priority: number;
}

export enum ECustomApprovalType {
  AUTO = 'AUTO',
  CUSTOM = 'CUSTOM',
}

export interface EFlowPriorities {
  id: number;
  priority: number;
}

export interface IOptionsSource {
  value: string | number;
  caption?: string;
  label: React.ReactNode;
}

export enum ApprovalRegisterFormStep {
  BASIC_INFORMATION = 0,
  APPROVAL_CRITERIA = 1,
  TYPE_APPROVAL = 2,
}
