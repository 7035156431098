import {
  SelectField,
  SelectFieldProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import { UseQueryResult } from '@tanstack/react-query';

export type TRPCSelectQuery = {
  useQuery: (...args: unknown[]) => UseQueryResult<
    Array<{
      label: string;
      value: string;
    }>
  >;
};

type Props = {
  query: TRPCSelectQuery;
} & SelectFieldProps;

const ConditionQuerySelect = (props: Props) => {
  const items = props.query?.useQuery();

  return (
    <SelectField
      {...props}
      fullWidth
      options={items?.data || []}
      sx={{ width: 200, mr: 2 }}
      disabled={!items}
      noOptionsText={
        items?.isLoading ? 'Carregando...' : 'Nenhum item encontrado'
      }
    />
  );
};

export default ConditionQuerySelect;
