import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  &.warning {
    .autocomplete-custom-field {
      .MuiTextField-root {
        .MuiAutocomplete-inputRoot {
          background-color: ${(props) => props.theme.colors.feedback.error[90]};
          border: ${(props) => props.theme.borders.width.xs2} solid
            ${(props) => props.theme.colors.feedback.error[70]};
          color: ${(props) => props.theme.colors.feedback.error[90]};
          &:focus {
            border: ${(props) => props.theme.borders.width.xs2} solid
              ${(props) => props.theme.colors.feedback.error[50]};
          }

          &.Mui-focused {
            border: ${(props) => props.theme.borders.width.s} solid
              ${(props) => props.theme.colors.feedback.error[50]};
          }

          svg {
            stroke: ${(props) => props.theme.colors.feedback.error[50]};
          }
        }
      }
    }
  }

  .autocomplete-custom-field {
    .MuiTextField-root {
      label {
        z-index: 1;
      }
      .MuiAutocomplete-inputRoot {
        font-family: var(--font-family-secondary);
        height: 58px;
        border: ${(props) => props.theme.borders.width.xs2} solid
          ${(props) => props.theme.colors.neutral[70]};
        border-radius: ${(props) => props.theme.borders.radius.m};
        background-color: ${(props) => props.theme.colors.neutral[100]};
        color: ${(props) => props.theme.colors.neutral[30]};
        font-weight: 600;
        &:focus {
          border: ${(props) => props.theme.borders.width.xs2} solid
            ${(props) => props.theme.colors.neutral[70]};
        }

        &::before,
        &::after {
          content: none;
        }

        &.Mui-focused {
          border: ${(props) => props.theme.borders.width.s} solid
            ${(props) => props.theme.colors.secondary[70]};
        }
      }
    }
  }
`;

export const EmptyImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacings.xs};
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacings.xs};
`;
