import React from 'react';
import { TravelTableColumn } from '@components/TravelDataTable/types';
import { TagProfileStatus } from '../components/TagProfileStatus';
import { EmployeeInformation } from '@components/EmployeeInformation';
import { Employee } from '../../types';

export function useProfileColumns(): TravelTableColumn<any>[] {
  return [
    {
      id: 'profilePicture',
      accessorKey: 'employeeName',
      header: 'Nome',
      cell: ({ row }) => {
        const employee: Employee = {
          id: row.original.id,
          name: row.original.employeeName,
          email: row.original.employeeEmail,
        };
        return <EmployeeInformation employee={employee} />;
      },
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row }) => (
        <TagProfileStatus employeeStatus={row.original.employeeStatus} />
      ),
    },
  ];
}
