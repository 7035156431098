import { FormModal } from '@components/FormModal';
import { ReasonForm } from './components/ReasonForm';
import { useReasonForm } from './hooks/useReasonForm';

type Props = {
  reasonId?: string;
  onCancel: () => void;
  onSave: () => void;
};

export const ReasonRegister = ({ onCancel, onSave, reasonId }: Props) => {
  const { reasonForm, onSubmit, isLoading } = useReasonForm({
    reasonId: reasonId,
    onSave,
  });

  return (
    <FormModal
      title="Adicionar motivo de viagem"
      description="Permite personalizar solicitações de viagem com motivos específicos, atendendo às necessidades individuais dos viajantes."
      isSaving={reasonForm.formState.isSubmitting}
      onSaveClick={reasonForm.handleSubmit(onSubmit)}
      onCancel={onCancel}
      isLoading={isLoading}
    >
      <ReasonForm reasonForm={reasonForm} isEditing={!!reasonId} />
    </FormModal>
  );
};
