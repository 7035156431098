import {
  getAuthContextData,
  resolveAccessToken,
} from '@api/connectOn/connectOn.api';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { HROS_ACCESS_TOKEN } from '@shared/constants/Storage.constants';
import { QueryClient } from '@tanstack/react-query';
import { createTRPCReact, httpLink } from '@trpc/react-query';
import type { AppRouter } from 'bff';
import { useTravelStore } from '../../store/RequestTravel.store';

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();

export const trpcClient = trpc.createClient({
  links: [
    httpLink({
      url: `${process.env.BFF_URL}/trpc` || '',
      headers: async () => {
        const { selectedCompanyId } = getAuthContextData();

        const { contextId } = useTravelStore.getState();

        // todo: refresh token
        const flashOsAuth = getFromLS(HROS_ACCESS_TOKEN);
        const connectOnAuthAccessToken = await resolveAccessToken();

        return {
          Authorization: `Bearer ${flashOsAuth?.accessToken}`,
          'x-connect-on-authorization': `Bearer ${connectOnAuthAccessToken}`,
          'x-connect-on-context-id': contextId || undefined,
          'company-id': selectedCompanyId,
        };
      },
    }),
  ],
  transformer: undefined,
});
