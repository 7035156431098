import { trpc } from '@api/bff/client';
import { TableStatusToggle } from '@components/TravelDataTable/components/TableStatusToggle';
import { PolicyStatus } from 'bff/src/routers/policies/enums';

type Props = {
  policy: { id?: string; status?: PolicyStatus };
};

export const TogglePolicyStatus = ({ policy }: Props) => {
  const updatePolicyStatus = trpc.updatePolicyStatus.useMutation();

  return (
    <TableStatusToggle
      defaultChecked={policy.status === PolicyStatus.ACTIVE}
      isLoading={updatePolicyStatus.isLoading}
      onToggleStatus={(value) =>
        updatePolicyStatus.mutateAsync({
          id: policy.id,
          status: value ? PolicyStatus.ACTIVE : PolicyStatus.INACTIVE,
        })
      }
    />
  );
};
