import { create } from 'zustand';
import { TravelProfileType } from '../types';

type ProfileConfigurationStore = {
  selectedEmployeeIds: string[];
  setSelectedEmployeeIds: (ids: string[]) => void;
  profileType: TravelProfileType | null;
  setProfileType: (type: TravelProfileType) => void;
};

export const useProfileConfigurationStore = create<ProfileConfigurationStore>(
  (set) => ({
    selectedEmployeeIds: [],
    setSelectedEmployeeIds: (ids: string[]) =>
      set({ selectedEmployeeIds: ids }),

    profileType: null,
    setProfileType: (type: TravelProfileType) => set({ profileType: type }),
  }),
);
