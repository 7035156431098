import { Loader } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

export interface LoaderProps {
  loader?: React.ReactNode;
  description?: string;
}

export const AutocompleteLoader = ({ loader, description }: LoaderProps) => {
  return (
    <SC.Container>
      {Boolean(loader) ? (
        loader
      ) : (
        <Loader variant="primary" size="extraSmall" />
      )}
      <SC.Description variant="caption">
        {description || 'Carregando opções'}
      </SC.Description>
    </SC.Container>
  );
};
