import { trpc } from '@api/bff/client';
import { TravelDataTable } from '@components/TravelDataTable';
import { useState } from 'react';
import {
  useSalesIntegrationColumns,
  useSalesIntegrationFilter,
} from './hooks/useSalesIntegrationTable';
import { SalesGridActions } from './SalesIntegrationActions';

export function SalesIntegrationList() {
  const columns = useSalesIntegrationColumns();
  const defaultStartAt = new Date();
  defaultStartAt.setMonth(defaultStartAt.getMonth() - 1);
  defaultStartAt.setDate(1);
  const [dateRange, setDateRange] = useState<{ from: Date; to: Date }>({
    from: defaultStartAt,
    to: new Date(),
  });
  const filters = useSalesIntegrationFilter(dateRange, setDateRange);
  const { data, isLoading } = trpc.getSalesIntegrationList.useQuery(dateRange);

  return (
    <TravelDataTable
      isLoading={isLoading}
      columns={columns}
      filters={filters}
      header={{ title: 'Vendas', tagLabel: 'Vendas' }}
      rows={data || []}
      renderActions={({ item }) => <SalesGridActions item={item} />}
    />
  );
}
