import {
  TravelTableColumn,
  TravelTableFilter,
} from '@components/TravelDataTable/types';
import { GetJustificationsResponse } from 'bff/src/routers/justifications';
import { JustificationStatus } from 'bff/src/routers/justifications/enums';
import { format } from 'date-fns';
import { ToggleJustificationStatus } from '../components/ToggleJustificationStatus';
import { getProductName } from '../../../../../shared/utils/product.utils';
import { TravelProduct } from 'bff/src/routers/policies/enums';
import { BooleanTag } from '@components/BooleanTag';

type Justification = GetJustificationsResponse['justifications'][number];

export function useJustificationsColumns(): TravelTableColumn<Justification>[] {
  return [
    {
      accessorKey: 'code',
      header: 'Código',
    },
    {
      accessorKey: 'name',
      header: 'Nome da justificativa',
    },
    {
      accessorKey: 'product',
      header: 'Produto',
      cell: ({ row }) => getProductName(row.original.product),
    },
    {
      accessorKey: 'applyForConsultant',
      header: 'Aplicar ao consultor',
      cell: ({ row }) => <BooleanTag value={row.original.applyForConsultant} />,
    },
    {
      accessorKey: 'requireDetails',
      header: 'Descrição Obrigatória',
      cell: ({ row }) => <BooleanTag value={row.original.requireDetails} />,
    },
    {
      accessorKey: 'updatedAt',
      header: 'Última atualização',
      cell: ({ row }) =>
        format(new Date(row.original.updatedAt), 'dd/MM/yyyy HH:mm'),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      sticky: 'right',
      size: 100,
      cell: ({ row }) => (
        <ToggleJustificationStatus justification={row.original} />
      ),
    },
  ];
}

export function useJustificationsFilters(): TravelTableFilter[] {
  return [
    {
      name: 'status',
      label: 'Status',
      options: [
        { label: 'Ativo', value: JustificationStatus.ACTIVE },
        { label: 'Inativo', value: JustificationStatus.INACTIVE },
      ],
    },
    {
      name: 'product',
      label: 'Produto',
      options: [
        {
          label: getProductName(TravelProduct.HOTEL),
          value: TravelProduct.HOTEL,
        },
        {
          label: getProductName(TravelProduct.AIR),
          value: TravelProduct.AIR,
        },
        {
          label: getProductName(TravelProduct.VEHICLE),
          value: TravelProduct.VEHICLE,
        },
      ],
    },
  ];
}
