import { trpc } from '@api/bff/client';
import { TableBaseActions } from '@components/TravelDataTable';
import { dispatchToast } from '@shared/toast';
import { useState } from 'react';
import { ApprovalItem } from '../../../types';

export type Props = {
  approvalFlow: ApprovalItem;
  onRefresh: () => Promise<unknown> | unknown;
  onEdit: () => void;
};

const ApprovalTableActions = ({ approvalFlow, onRefresh, onEdit }: Props) => {
  const deleteApproval = trpc.deleteApprovalFlowById.useMutation();

  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    try {
      setIsDeleting(true);

      await deleteApproval.mutateAsync({ id: approvalFlow.id });

      await onRefresh?.();

      dispatchToast({
        type: 'success',
        content: `O fluxo ${approvalFlow.name} foi excluído com sucesso`,
      });
    } catch (err) {
      dispatchToast({
        content: `Erro ao excluir o fluxo. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <TableBaseActions
      delete={{
        title: 'Excluir fluxo de aprovação',
        message:
          'Deseja realmente excluir esse fluxo? Essa ação não poderá ser desfeita',
        status: 'error',
        primaryButtonLabel: 'Excluir',
        loading: isDeleting,
      }}
      duplicate={{
        loading: false,
      }}
      onDelete={handleDelete}
      onDuplicate={() => console.log('Duplicated')}
      onEdit={onEdit}
      actions={['edit', 'delete', 'duplicate']}
    />
  );
};

export default ApprovalTableActions;
