import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  Typography,
  SearchField,
} from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styled';
import { useEffect, useMemo, useState } from 'react';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { dataSources } from '@legacy-dataSources/profile.dataSources';
import DetailsTravelers from '@components/RequestTravel/RequestTravelOffline/TravelersOffline/DetailsTravelers';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import { FormSection } from '@shared/components/Form/FormSection';

export function TravelersOffline() {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'travelers',
  });

  const contextId = useTravelStore((state) => state.contextId);
  const saveTraveler = useTravelStore((state) => state.saveTraveler);
  const numberTravelers = useTravelStore((state) => state.numberTravelers);
  const [searchTerms, setSearchTerms] = useState({});
  const [travelers, setTravelers] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isSocialName, setIsSocialName] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClose = (isSuccess: boolean) => {
    if (!isSuccess && currentIndex !== null) {
      setValue(`travelers.${currentIndex}.name`, '');
    }
    setIsModalOpen(false);
    setCurrentIndex(null);
  };

  const travelerOptions = useMemo(() => {
    return fields.reduce((acc, field) => {
      acc[field.id] = travelers[field.id] || [];
      return acc;
    }, {});
  }, [fields, travelers]);

  useEffect(() => {
    if (numberTravelers > fields.length) {
      for (let i = fields.length; i < numberTravelers; i++) {
        append({ name: '' });
      }
    } else {
      for (let i = fields.length; i > numberTravelers; i--) {
        remove(i - 1);
      }
    }
  }, [numberTravelers, fields.length, append, remove]);

  useEffect(() => {
    async function updateTravelers() {
      const promises = fields.map(async (field) => {
        if (searchTerms[field.id] && searchTerms[field.id].length >= 3) {
          setIsLoading((prev) => ({ ...prev, [field.id]: true }));
          try {
            const loadOptions = {
              keywords: searchTerms[field.id],
              travelerType: '1',
              contextId: contextId,
            };
            const data = await dataSources.travelers.load(loadOptions);
            data?.forEach((travelerOption) => {
              if (!travelerOption.traveler.userId)
                travelerOption.label += ' (Externo)';
            });
            return {
              fieldId: field.id,
              data: [
                { label: '+ Adicionar Viajante', value: 'ADD_NEW_TRAVELER' },
                ...(data.length > 0
                  ? data
                  : [{ label: 'Nenhum viajante encontrado', value: '' }]),
              ],
            };
          } catch (error) {
            console.error('Erro ao carregar travelers para', field.id, error);
            return { fieldId: field.id, data: [] };
          }
        }
        return null;
      });
      const results = await Promise.all(promises);
      results.forEach((result) => {
        if (result) {
          setTravelers((prev) => ({ ...prev, [result.fieldId]: result.data }));
          setIsLoading((prev) => ({ ...prev, [result.fieldId]: false }));
        }
      });
    }

    updateTravelers();
  }, [searchTerms, fields, contextId]);

  return (
    <div>
      <DetailsTravelers handleClose={handleClose} isModal={isModalOpen} />
      {numberTravelers >= 1 && (
        <FormSection title="Viajante(s) - Adulto">
          <SC.TravelerContainer>
            {fields.map((item, index) => (
              <SC.TravelerItem key={`${item.id}-${index}`}>
                <Controller
                  control={control}
                  name={`travelers.${index}.name`}
                  render={({ field }) => (
                    <SearchField
                      {...(field as any)}
                      style={{ width: '100%' }}
                      label={`Informar viajante ${index + 1}`}
                      onInputChange={(e, value, reason) => {
                        if (reason === 'input') {
                          setSearchTerms((prevSearchTerms) => ({
                            ...prevSearchTerms,
                            [item.id]: value,
                          }));
                        }
                      }}
                      onSearchChange={(event, selectedItem) => {
                        setCurrentIndex(index);
                        if (selectedItem.value === 'ADD_NEW_TRAVELER') {
                          setIsModalOpen(true);
                        } else {
                          setValue(
                            `travelers.${index}.travelerInfo`,
                            selectedItem.traveler,
                          );
                          saveTraveler(selectedItem.traveler);
                          setIsSocialName(selectedItem.traveler.useSocialName);
                          setIsModalOpen(true);
                        }
                      }}
                      options={travelerOptions[item.id]}
                      loading={isLoading[item.id]}
                      error={
                        !!errors.travelers?.[index]?.name ||
                        errors.travelers?.root?.message
                      }
                      helperText={
                        errors.travelers?.[index]?.name && (
                          <DisplayErrorFormValidation
                            errors={errors}
                            fieldName={`travelers.${index}.name`}
                          />
                        )
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`travelers.${index}.socialName`}
                  render={({ field }) => (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Checkbox
                          {...field}
                          disabled={true}
                          checked={isSocialName}
                        />
                        <Typography variant={'body3'} weight={600}>
                          Usar nome social na reserva
                        </Typography>
                      </div>
                      <div>
                        {errors.travelers?.[index]?.socialName && (
                          <DisplayErrorFormValidation
                            errors={errors}
                            fieldName={`travelers.${index}.socialName`}
                          />
                        )}
                      </div>
                    </div>
                  )}
                />
              </SC.TravelerItem>
            ))}
            <DisplayErrorFormValidation
              errors={errors}
              fieldName={'travelers.${index}.socialName'}
            />
          </SC.TravelerContainer>
        </FormSection>
      )}
    </div>
  );
}
