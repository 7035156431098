import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { travelEnums } from '../../../utils/enums.js';
import BookingIntegrations from '../../integrations/booking-integrations.component.js';
import Popup from '../../popup/popup.component.js';
import TextBox from '../../textbox/textbox.component.js';
import AnalysisItems from '../analysis-items/analysis-items.component.js';
import BookingBox from '../booking-box/booking-box.component.js';
import BookingItemAnalysis from '../booking-item-analysis/booking-item-analysis.component.js';
import BookingPayment from '../booking-payment/booking-payment.component.js';
import BookingStatusBar from '../booking-status-bar/booking-status-bar.component.js';
import BookingTravelerList from '../booking-traveler-list/booking-traveler-list.component.js';
import BookingHotelTariffsAndFees from '../hotel-booking/booking-hotel-fariffs-and-fess/booking-hotel-fariffs-and-fess.component.js';
import BookingHotelSegments from '../hotel-booking/booking-hotel-segments/booking-hotel-segments.component.js';
import HotelBookingInfos from '../hotel-booking/hotel-booking-infos/hotel-booking-infos.component.js';

var Template = {
  main: ({
    booking,
    bookingEvents,
    editItemsEvents,
    analysisItemData,
    analysisItemEvents,
    dataSources,
    onShowEmailPopupClick,
    popupExpirationDate,
    expirationEvents,
    expirationDateValue,
    confirmedByValue,
    popupConfirmData,
    confirmDataEvents,
    popupConfirmIssuance,
    locatorValue,
    confirmIssuanceEvents,
  }) => {
    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;
    let moneyFormatted = utils.formatters.money.getFormatted;
    let bookingSystem = booking.suplierName
      ? booking.suplierName
      : booking.connectionSource
      ? booking.connectionSource
      : null;
    let expirationDate = booking.expiration
      ? getFormattedDateObjFromDateObject(booking.expiration.expireAt)
      : null;

    let room = null;
    let fare = null;
    let fareInformation = null;
    let canEdit = false;
    let canEditFares = false;
    let locatorToBeConfirmed = false;
    let isDateValueDefault = false;

    if (!booking.loading) {
      room = booking.hotel.rooms ? booking.hotel.rooms[0] : null;

      fare = room.fares ? room.fares[0] : null;

      fareInformation = fare ? fare.fareInformation : null;
      canEdit = booking.allowedActions.canEdit;
      canEditFares = booking.allowedActions.canEditFares;

      let isPendingHotelOffline =
        booking.bookingStatus == enums.bookingStatus.bookPending &&
        booking.audit.dataOrigin == 1 &&
        bookingSystem == travelEnums.hotelSources.offline;
      locatorToBeConfirmed =
        isPendingHotelOffline ||
        booking.bookingStatus == enums.bookingStatus.preBooking;
      isDateValueDefault =
        isPendingHotelOffline &&
        utils.formatters.date.isDefaultDate(booking.expiration.expireAt);
    }

    return (
      <div className="exon-hotel-booking">
        {booking.loading && (
          <div className="exon-box-hotel-loading">
            <div>
              <span className="exon-icon exon-icon-loading-hotel-unfilled_enabled"></span>
              <p>
                Por favor aguarde!
                <br />
                Estamos carregando as informações da{' '}
                <span>Reserva de hotel</span>...
              </p>
            </div>
          </div>
        )}

        {!booking.loading && (
          <div className="exon-air-booking-box">
            <div className="exon-air-booking-box-status exon-row-itens exon-row-aling-itens-center">
              <BookingStatusBar
                statusInfos={{
                  status: booking.workflow.status,
                  lock: booking.lock,
                  expirationDate: expirationDate
                    ? `${expirationDate.dateFormatted} ${expirationDate.timeFormatted}`
                    : '--/--/--',
                  ignoreExpiration: booking.expiration
                    ? booking.expiration.ignore
                    : true,
                }}
              />
              <div className="exon-actions-travel-request exon-row-itens exon-row-justify-content-space-between exon-row-aling-itens-center exon-w-100">
                <div className="exon-row-itens ">
                  <span
                    className="exon-icon exon-icon-double-update-unfilled_enabled exon-row-aling-itens-center"
                    onClick={bookingEvents.onUpdateBookingClicked}
                  ></span>
                  {booking.allowedActions.sendNotification && (
                    <span
                      className="exon-icon exon-icon-envelope-filled_enabled"
                      onClick={() =>
                        onShowEmailPopupClick(
                          booking.id,
                          booking.workflow.status.id,
                          false,
                        )
                      }
                    ></span>
                  )}
                </div>
              </div>
            </div>
            <div className="exon-row-itens">
              <BookingBox title="Hóspedes">
                <BookingTravelerList
                  travelerList={booking.travelers}
                  dataSources={dataSources}
                />
              </BookingBox>

              <BookingBox title="Reserva">
                <HotelBookingInfos
                  canEdit={canEdit}
                  dataSources={dataSources}
                  hotelBookingInfos={{
                    locator: booking.bookingLocator
                      ? booking.bookingLocator
                      : null,
                    bookingStatus: booking.bookingStatus
                      ? booking.bookingStatus
                      : null,
                    expirationDate: booking.expiration
                      ? getFormattedDateObjFromDateObject(
                          booking.expiration.expireAt,
                        ).dateTimeFormatted
                      : null,
                    bookingSystem: bookingSystem ? bookingSystem : null,
                    hotelName:
                      booking.hotel && booking.hotel.name
                        ? booking.hotel.name
                        : null,
                    confirmedBy: booking.confirmedBy
                      ? booking.confirmedBy
                      : null,
                    locatorToBeConfirmed,
                    isDateValueDefault,
                    confirmDataEvents,
                    confirmIssuanceEvents,
                    allowedActions: booking.allowedActions,
                    roomsName:
                      booking.hotel && booking.hotel.rooms
                        ? booking.hotel.rooms.map((room) => {
                            return room.name;
                          })
                        : [],
                    cancelationPolicies:
                      booking.hotel && booking.hotel.rooms
                        ? booking.hotel.rooms.map((room) => {
                            return room.cancellationPolicies
                          })
                        : [],
                  }}
                />
              </BookingBox>
              {booking.payments && (
                <BookingBox title="Garantia">
                  <BookingPayment
                    paymentForms={booking.payments
                      .filter((payment) => payment.isGuarantee)
                      .map((payment) => payment.paymentData.paymentForm)}
                  />
                </BookingBox>
              )}

              <div style={{ display: `none` }}>
                <BookingBox title="Análise dos itens">
                  <BookingItemAnalysis
                    action={
                      <a onClick={analysisItemEvents.onShowAnalysisItemClicked}>
                        Clique aqui
                      </a>
                    }
                  />
                </BookingBox>
              </div>
            </div>

            <BookingBox title="Segmentos">
              <BookingHotelSegments
                canEdit={canEdit}
                dataSources={dataSources}
                segment={
                  booking.hotel
                    ? {
                        name: booking.hotel.name,
                        city: booking.hotel.address
                          ? booking.hotel.address.city
                          : null,
                        address: booking.hotel.address
                          ? `${
                              booking.hotel.address.street
                                ? booking.hotel.address.street + ','
                                : ''
                            } ${
                              booking.hotel.address.number
                                ? booking.hotel.address.number
                                : ''
                            } - ${
                              booking.hotel.address.postalCode
                                ? booking.hotel.address.postalCode
                                : ''
                            }`
                          : null,
                        checkIn:
                          booking.hotel.rooms[0] &&
                          booking.hotel.rooms[0].fares[0] &&
                          booking.hotel.rooms[0].fares[0].fareInformation &&
                          booking.hotel.rooms[0].fares[0].fareInformation
                            .checkIn
                            ? getFormattedDateObjFromDateObject(
                                booking.hotel.rooms[0].fares[0].fareInformation
                                  .checkIn,
                              ).dateFormatted +
                              ' ' +
                              getFormattedDateObjFromDateObject(
                                booking.hotel.rooms[0].fares[0].fareInformation
                                  .checkIn,
                              ).timeFormatted
                            : null,
                        checkOut:
                          booking.hotel.rooms[0] &&
                          booking.hotel.rooms[0].fares[0] &&
                          booking.hotel.rooms[0].fares[0].fareInformation &&
                          booking.hotel.rooms[0].fares[0].fareInformation
                            .checkOut
                            ? getFormattedDateObjFromDateObject(
                                booking.hotel.rooms[0].fares[0].fareInformation
                                  .checkOut,
                              ).dateFormatted +
                              ' ' +
                              getFormattedDateObjFromDateObject(
                                booking.hotel.rooms[0].fares[0].fareInformation
                                  .checkOut,
                              ).timeFormatted
                            : null,
                        locator: booking.bookingLocator
                          ? booking.bookingLocator
                          : null,
                        voucher: booking.sourceBookingLocator
                          ? booking.sourceBookingLocator
                          : null,
                        voucherDate: booking.audit.createdAt
                          ? getFormattedDateObjFromDateObject(
                              booking.audit.createdAt,
                            ).dateFormatted
                          : null,
                        saleType: booking.saleType ? booking.saleType : null,
                        passiveLoc: booking.hotel.passiveLoc
                          ? booking.hotel.passiveLoc
                          : null,
                        bookingStatus: booking.bookingStatus
                          ? booking.bookingStatus
                          : null,
                        addressHotel: booking.hotel.address
                          ? booking.hotel.address
                          : null,
                        rooms: booking.hotel.rooms,
                        locatorToBeConfirmed,
                      }
                    : null
                }
                hotel={booking.hotel ? booking.hotel : {}}
                events={{
                  onEditSegmentsClick:
                    editItemsEvents.onEditHotelSegmentsClicked,
                }}
              />
            </BookingBox>

            <BookingBox title="Tarifas e taxas">
              <BookingHotelTariffsAndFees
                canEdit={canEdit}
                canEditFares={canEditFares}
                dataSources={dataSources}
                tariffsAndFees={
                  booking.hotel && booking.hotel.rooms
                    ? booking.hotel.rooms.map((room) => {
                        let mealOption = null;
                        if (room.fares && room.fares) {
                          let roomBoard = [];
                          room.fares.forEach((fare) => {
                            if (
                              fare.fareInformation &&
                              fare.fareInformation.board
                            )
                              roomBoard.push(
                                fare.fareInformation.board.description ||
                                  fare.fareInformation.board.name,
                              );
                          });

                          mealOption = roomBoard.join(', ');
                        }

                        let system = room.connectionSource
                          ? room.connectionSource
                          : bookingSystem
                          ? bookingSystem
                          : null;
                        let originalPrice =
                          room.fares &&
                          room.fares.lenght > 0 &&
                          room.fares.roomValue.originalPrice
                            ? room.fares.roomValue.originalPrice
                            : null;
                        let equivalentPrice =
                          room.fares &&
                          room.fares.length > 0 &&
                          room.fares[0].roomValue
                            ? room.fares[0].roomValue
                            : null;
                        let formatetedOriginalPrice = booking.isInternational
                          ? {
                              exchangeRate:
                                room.fares.lenght > 0 &&
                                room.fares.roomValue &&
                                room.fares.roomValue.exchangeRate
                                  ? room.fares.roomValue.exchangeRate.from +
                                    ' 1.00'
                                  : null,
                              dailyFare: originalPrice
                                ? originalPrice.dailyFare.currencyCode +
                                  ' ' +
                                  moneyFormatted(
                                    originalPrice.dailyFare.value,
                                    originalPrice.dailyFare.currencyCode,
                                  )
                                : null,
                              totalFare: originalPrice
                                ? originalPrice.totalFare.currencyCode +
                                  ' ' +
                                  moneyFormatted(
                                    originalPrice.totalFare.value,
                                    originalPrice.totalFare.currencyCode,
                                  )
                                : null,
                              taxes: originalPrice
                                ? originalPrice.taxes.currencyCode +
                                  ' ' +
                                  moneyFormatted(
                                    originalPrice.taxes.value,
                                    originalPrice.taxes.currencyCode,
                                  )
                                : null,
                              markup: originalPrice
                                ? originalPrice.markup.currencyCode +
                                  ' ' +
                                  moneyFormatted(
                                    originalPrice.markup.value,
                                    originalPrice.markup.currencyCode,
                                  )
                                : null,
                            }
                          : null;
                        return {
                          system: system,
                          name: room.name ? room.name : null,
                          mealOption: mealOption,
                          status: room.status
                            ? room.status
                            : booking.bookingStatus
                            ? booking.bookingStatus
                            : null,
                          days:
                            room.fares[0] && room.fares[0].fareInformation
                              ? room.fares[0].fareInformation.days
                              : null,
                          fares:
                            room.fares && room.fares.length > 0 && room.fares[0]
                              ? room.fares[0]
                              : null,
                          totalValue: booking.valueData
                            ? booking.valueData.totalValue
                            : null,
                          //fares: {
                          //    originalPrice: formatetedOriginalPrice,
                          //    equivalentPrice: {
                          //        exchangeRate: room.fares.lenght > 0 && room.fares.roomValue && room.fares.roomValue.exchangeRate ? room.fares.roomValue.exchangeRate.to + ' ' + room.fares.roomValue.exchangeRate.value :
                          //        equivalentPrice ? equivalentPrice.dailyFare.currencyCode + ' ' + moneyFormatted(1, equivalentPrice.dailyFare.currencyCode) : null,
                          //        dailyFare: equivalentPrice ? equivalentPrice.dailyFare.currencyCode + ' ' + moneyFormatted(equivalentPrice.dailyFare.value, equivalentPrice.dailyFare.currencyCode) : null,
                          //        totalFare: booking.valueData ? booking.valueData.totalValue.currencyCode + ' ' + moneyFormatted(booking.valueData.totalValue.value, booking.valueData.totalValue.currencyCode): null,
                          //        taxes: equivalentPrice ? equivalentPrice.dailyTax.currencyCode + ' ' + moneyFormatted(equivalentPrice.dailyTax.value, equivalentPrice.dailyTax.currencyCode) : null,
                          //        markup: equivalentPrice && equivalentPrice.markup ? equivalentPrice.markup.currencyCode + ' ' + moneyFormatted(equivalentPrice.markup.value, equivalentPrice.markup.currencyCode) : null
                          //    }
                          //},
                          //originalFares: {
                          //    originalPrice: originalPrice,
                          //    equivalentPrice: equivalentPrice,
                          //    totalFare: booking.valueData && booking.valueData.totalValue ? booking.valueData.totalValue.value : null
                          //}
                        };
                      })
                    : []
                }
                hotel={booking.hotel ? booking.hotel : {}}
                booking={booking}
                events={{
                  onEdittariffsAndFeesClick:
                    editItemsEvents.onEditTariffsAndFessClicked,
                }}
              />
            </BookingBox>
            {booking.allowedActions.integrations.apps.isEnabled && (
              <div className="exon-booking-box text-right">
                <BookingIntegrations
                  serviceRequestId={booking.serviceRequestId}
                  serviceRequestItemId={booking.id}
                  allowedActions={booking.allowedActions}
                  integrations={booking.integrations}
                  onDispatchClick={() =>
                    bookingEvents.onSendAppsClicked(booking.id)
                  }
                />
              </div>
            )}
          </div>
        )}

        {analysisItemData.show && (
          <div className="exon-item-analysis-box">
            <Template.analysisItems
              data={booking.analysisItem}
              events={analysisItemEvents}
            />
          </div>
        )}
        {popupExpirationDate.show && (
          <div>
            <Template.popup.editExpirationDate
              events={popupExpirationDate.events}
              data={popupExpirationDate.data}
              expirationDateValue={expirationDateValue}
            />
          </div>
        )}

        {popupConfirmData.show && (
          <div>
            <Template.popup.confirmData
              events={popupConfirmData.events}
              data={popupConfirmData.data}
              irhId={booking.id}
              expirationDateValue={expirationDateValue}
              confirmedByValue={confirmedByValue}
            />
          </div>
        )}

        {popupConfirmIssuance.show && (
          <div>
            <Template.popup.confirmIssuance
              events={popupConfirmIssuance.events}
              data={popupConfirmIssuance.data}
              irhId={booking.id}
              locatorValue={locatorValue}
            />
          </div>
        )}
      </div>
    );
  },

  analysisItems: ({ data, events }) => {
    return (
      <Popup
        okButtonLabel={null}
        title={'Analise dos itens'}
        exonStyle={true}
        cancelButtonClick={events.onHideAnalysisItemClicked}
        okButtonClick={null}
      >
        {!data.searchResults && (
          <div className="exon-box-air-loading">
            <span
              className={'exon-icon exon-icon-loading-hotel-unfilled_enabled'}
            ></span>
            <p>
              Por favor aguarde!
              <br />
              Estamos carregando os itens para analise...
            </p>
          </div>
        )}

        {data.searchResults && (
          <AnalysisItems productType={enums.products.type.hotel} data={data} />
        )}
      </Popup>
    );
  },
  popup: {
    editExpirationDate: ({ events, data, expirationDateValue }) => {
      return (
        <Popup
          okButtonLabel={'Salvar'}
          cancelButtonLabel={'Cancelar'}
          title={'Editar data de expiração'}
          exonStyle={true}
          okButtonClick={events.confirm}
          cancelButtonClick={events.cancel}
        >
          <div className="exon-field">
            <label>Data de expiração</label>
            <TextBox
              type={enums.components.textBoxType.mask}
              disabled={false}
              required={true}
              removeMask={false}
              value={expirationDateValue}
              placeholder={data.placeholder}
              mask={data.mask}
              onChange={data.onChange}
            />
          </div>
        </Popup>
      );
    },
    confirmData: ({
      events,
      data,
      irhId,
      expirationDateValue,
      confirmedByValue,
    }) => {
      return (
        <Popup
          okButtonLabel={'Salvar'}
          okButtonClick={events.confirm}
          cancelButtonLabel={'Cancelar'}
          cancelButtonClick={events.cancel}
          exonStyle={true}
          title={`Confirmar dados - IRH ${irhId}`}
        >
          <div className="exon-confirm-data-container">
            <div className="exon-confirm-data exon-confirm-data-top">
              <label>Confirmado por</label>
              <div className="exon-field">
                <TextBox
                  type={enums.components.textBoxType.default}
                  disabled={false}
                  required={true}
                  removeMask={false}
                  value={confirmedByValue}
                  placeholder={data.confirmedBy.placeholder}
                  onChange={data.confirmedBy.onChange}
                />
              </div>
            </div>
            <div className="exon-confirm-data">
              <label>Data de expiração</label>
              <div className="exon-field">
                <TextBox
                  type={enums.components.textBoxType.mask}
                  disabled={false}
                  required={true}
                  removeMask={false}
                  value={expirationDateValue}
                  placeholder={data.expirationDate.placeholder}
                  mask={data.expirationDate.mask}
                  onChange={data.expirationDate.onChange}
                />
              </div>
            </div>
          </div>
        </Popup>
      );
    },
    confirmIssuance: ({ events, data, irhId, locatorValue }) => {
      return (
        <Popup
          okButtonLabel={'Salvar'}
          okButtonClick={events.confirm}
          cancelButtonLabel={'Cancelar'}
          cancelButtonClick={events.cancel}
          exonStyle={true}
          title={`Confirmar Emissão - IRH ${irhId}`}
        >
          <div className="exon-confirm-data-container">
            <div className="exon-confirm-issuance exon-confirm-data-top">
              <label>Localizador</label>
              <div className="exon-field">
                <TextBox
                  type={enums.components.textBoxType.default}
                  disabled={false}
                  required={true}
                  removeMask={false}
                  value={locatorValue}
                  placeholder={data.locator.placeholder}
                  onChange={data.locator.onChange}
                />
              </div>
            </div>
          </div>
        </Popup>
      );
    },
  },
};

export default Template;
