import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { FormProvider } from 'react-hook-form';
import { Wizard } from '@components/Wizard';
import { useWizard } from '@components/Wizard/hooks/useWizard';
import { useApprovalFlowForm } from './hooks/useApprovalForm';
import { trpc } from '@api/bff/client';
import { ApprovalFlowBasicInformation } from './components/ApprovalFlowBasicInformation';
import { ApprovalFlowTypes } from './components/ApprovalFlowTypes';
import { ApprovalCriteriaForm } from './components/ApprovalCriteriaForm';
import { FormStateDebug } from '@components/FormStateDebug';

type Props = {
  approvalId?: string | null;
  onSave: () => void;
  onCancel: () => void;
  debug?: boolean;
};

export const RegisterApprovalFlow = ({
  onCancel,
  onSave,
  approvalId,
  debug,
}: Props) => {
  const approval = trpc.getApprovalFlowById.useQuery(
    { id: approvalId },
    {
      enabled: !!approvalId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const approvalFlowRegisterWizard = useWizard();

  const { approvalFlowForm, onSubmit, isSaving } = useApprovalFlowForm({
    onSave,
    approvalFlow: approval.data,
    step: approvalFlowRegisterWizard.activeStep,
  });

  async function handleChangeStep(step: number) {
    try {
      if (step > approvalFlowRegisterWizard.activeStep) {
        const isValid = await approvalFlowForm.trigger();

        if (!isValid) return;
      }
      return approvalFlowRegisterWizard.onStepChange(step);
    } catch (err) {
      return approvalFlowRegisterWizard.onStepChange(step);
    }
  }

  const steps = [
    <ApprovalFlowBasicInformation
      isLoading={!!approvalId && approval.isLoading}
    />,
    <ApprovalCriteriaForm />,
    <ApprovalFlowTypes />,
  ];

  return (
    <Wizard
      onCancel={onCancel}
      steps={[
        'Informações básicas',
        'Critério de aprovação',
        'Tipo de aprovação',
      ]}
      onComplete={approvalFlowForm.handleSubmit(onSubmit)}
      isSubmitting={isSaving}
      activeStep={approvalFlowRegisterWizard.activeStep}
      onStepChange={handleChangeStep}
    >
      <FormProvider {...approvalFlowForm}>
        <Box>
          <Typography variant={'headline6'}>
            Adicionar fluxo de aprovação
          </Typography>
        </Box>
        {steps[approvalFlowRegisterWizard.activeStep]}

        {debug && <FormStateDebug />}
      </FormProvider>
    </Wizard>
  );
};
