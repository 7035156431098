import { LinkButton, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useCallback } from 'react';
import useModalStore from '../../../../../store/Modal.store';
import { ButtonOptions } from '../types';

interface Props {
  delete?: ButtonOptions;
  onDelete?: () => Promise<void> | void;
}

export const TableBaseBatchActions = (props: Props) => {
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);

  const handleDeleteFlow = useCallback(() => {
    openModal({
      title: props.delete?.title || 'Deseja realmente excluir?',
      subTitle:
        props.delete?.message ||
        'Essa ação não poderá ser desfeita, deseja realmente excluir?',
      status: props.delete?.status || 'error',
      primaryButtonLabel: props.delete?.primaryButtonLabel || 'Excluir',
      primaryButtonAction: async () => {
        try {
          await props.onDelete?.();

          closeModal();
        } catch (error) {
          throw error;
        }
      },
    });
  }, [props.onDelete, props.delete]);

  return (
    <>
      <LinkButton variant="default" onClick={handleDeleteFlow} size="small">
        <Icons name="IconTrash" />
        Apagar justificativas
      </LinkButton>
    </>
  );
};
