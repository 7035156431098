import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { TravelDataTableProps } from '../types';
import { useTableColumns } from './useTableColumns';

const INITIAL_PAGINATION: PaginationState = {
  pageNumber: 1,
  pageSize: 10,
};

export function useTravelDataTable<T>(props: TravelDataTableProps<T>) {
  const [pagination, setPagination] =
    useState<PaginationState>(INITIAL_PAGINATION);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Record<string, string[]>>({});

  // Função de busca
  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value || '';
    setSearch(value);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  // Função para aplicar os filtros
  const handleFilters = useCallback((newFilters: Record<string, string[]>) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  // Filtragem de itens com base na busca e filtros
  const filteredItems = useMemo(() => {
    return props.rows.filter((item) => {
      const matchesSearch = Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase()),
      );

      const matchesFilters = Object.entries(filters).every(
        ([filterKey, filterValues]) => {
          const itemValue = item[filterKey]?.toString() ?? '';
          return filterValues.length === 0 || filterValues.includes(itemValue);
        },
      );

      return matchesSearch && matchesFilters;
    });
  }, [props.rows, search, filters]);

  // Aplica a paginação aos itens filtrados
  const paginatedItems = useMemo(() => {
    const start = (pagination.pageNumber - 1) * pagination.pageSize;
    const end = pagination.pageNumber * pagination.pageSize;
    return filteredItems.slice(start, end);
  }, [filteredItems, pagination]);

  // Cria a tabela com os itens filtrados e paginados
  const table = useTableColumns({
    data: paginatedItems,
    columns: props.columns,
    renderActions: props.renderActions,
    onPaginationChange: setPagination,
    pagination,
    checkboxSelection: props.checkboxSelection,
  });

  useEffect(() => {
    if (props.isLoading) {
      setPagination(INITIAL_PAGINATION);
    }
  }, [props.isLoading]);

  // Retorno da função para o componente TravelDataTable
  return {
    paginatedItems,
    filteredItems,
    pagination,
    search,
    isLoading: props.isLoading,
    table,
    handleSearch,
    handleFilters,
    setPagination,
    refetch: props.onRefetch,
  };
}
