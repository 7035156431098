import React from 'react';
import { CreateApprovalFlowInput } from 'bff/src/routers/approval-flows';
import { Radio } from '@flash-tecnologia/hros-web-ui-v2';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

const FlowModeType = () => {
  const { control } = useFormContext<CreateApprovalFlowInput>();

  return (
    <Box>
      <Controller
        name="flowMode"
        control={control}
        rules={{ required: 'É obrigatória a seleção de uma das opções' }}
        render={({ field, fieldState: { error } }) => (
          <Box mb={4}>
            <Radio
              checked={field.value === 'SEQUENTIAL'}
              onChange={field.onChange}
              value="SEQUENTIAL"
              name={field.name}
              error={!!error}
            />
            Sequencial
            <Radio
              checked={field.value === 'PARALLEL'}
              onChange={field.onChange}
              value="PARALLEL"
              name={field.name}
              error={!!error}
            />
            Paralela
            {error ? (
              <div style={{ color: '#fea034' }}>{error.message}</div>
            ) : null}
          </Box>
        )}
      />
    </Box>
  );
};

export default FlowModeType;
