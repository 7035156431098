import {
  Modal,
  SelectField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import FeeTariffRatesSchema from '@components/RequestTravel/FeeTariffRates/FeeTariffRatesSchema';
import * as SC from '@components/RequestTravel/RequestTravelOffline/styled';
import { useLoading } from '../../../contexts/LoadingContext';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  calculateDaysDifference,
  convertDateObjectToDate,
} from '@utils/date.utils';
import * as Yup from 'yup';
import { useFeeTariffRatesStore } from '../../../store/FeeTariffRates.store';
import { useEffect, useState, useRef } from 'react';
import IMask from 'imask';
import { dispatchToast } from '@shared/toast';
import { useTravelStore } from '../../../store/RequestTravel.store';
import { useTravelItemStore } from '../../../store/RequestTravelItem.store';
import useModalStore from '../../../store/Modal.store';
import { saveServiceRequestItem } from '@api/connectOn/connectOn.service';

export function FeeTariffRates() {
  const { showLoading, hideLoading } = useLoading();
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const isModal = useFeeTariffRatesStore((state) => state.isModalOpen);
  const toggleModal = useFeeTariffRatesStore((state) => state.toggleModal);
  const onSaveSuccess = useFeeTariffRatesStore((state) => state.onSaveSuccess);
  const [contextId] = useTravelStore((state) => [state.contextId]);

  const [currentServiceRequestItem, updateCurrentServiceRequestItem] =
    useTravelItemStore((state) => [
      state.currentServiceRequestItem,
      state.updateCurrentServiceRequestItem,
    ]);

  const checkInDate = convertDateObjectToDate(
    currentServiceRequestItem?.vehicle?.originalRequest?.checkIn,
  );
  const checkOutDate = convertDateObjectToDate(
    currentServiceRequestItem?.vehicle?.originalRequest?.checkOut,
  );

  // Calcula a diferença em dias entre as datas de check-in e check-out
  let dailyCountByDate = calculateDaysDifference(checkInDate, checkOutDate);
  if (dailyCountByDate === 0) {
    dailyCountByDate = 1; // Garante que pelo menos 1 dia seja contado
  }

  const [formValues, setFormValues] = useState({
    currency: 'BRL',
    exchangeRate: '1',
    dailyCount: dailyCountByDate,
    dailyRate: '0',
    extrasTotal: '0',
    feesTotal: '0',
    markupValue: '0',
  });

  useEffect(() => {
    if (currentServiceRequestItem) {
      setFormValues({
        currency:
          currentServiceRequestItem?.valueData?.fareValue?.currencyCode ||
          'BRL',
        exchangeRate:
          currentServiceRequestItem?.vehicle?.values?.exchangeRate?.toString() ||
          '1',
        dailyCount: dailyCountByDate,
        dailyRate:
          currentServiceRequestItem?.vehicle?.values?.dailyFare?.value.toString() ||
          '0',
        extrasTotal:
          currentServiceRequestItem?.vehicle?.values?.serviceValue?.value.toString() ||
          '0',
        feesTotal:
          currentServiceRequestItem?.valueData?.taxValue?.value.toString() ||
          '0',
        markupValue:
          currentServiceRequestItem?.vehicle?.values?.markup?.value.toString() ||
          '0',
      });
    }
  }, [currentServiceRequestItem, dailyCountByDate]);

  type FormItemDataProps = Yup.InferType<typeof FeeTariffRatesSchema>;

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm<FormItemDataProps>({
    defaultValues: formValues,
    resolver: yupResolver(FeeTariffRatesSchema),
  });

  // Atualiza o valor do campo dailyCount com base no dailyCountByDate
  useEffect(() => {
    setValue('dailyCount', dailyCountByDate);
  }, [dailyCountByDate, setValue]);

  // Atualiza os valores do formulário quando os dados do currentServiceRequestItem estão disponíveis
  useEffect(() => {
    if (currentServiceRequestItem) {
      setValue('currency', formValues.currency);
      setValue('exchangeRate', formValues.exchangeRate);
      setValue('dailyCount', formValues.dailyCount);
      setValue('dailyRate', formValues.dailyRate);
      setValue('extrasTotal', formValues.extrasTotal);
      setValue('feesTotal', formValues.feesTotal);
      setValue('markupValue', formValues.markupValue);
    }
  }, [formValues, setValue, currentServiceRequestItem]);

  // Estado para armazenar o valor total
  const [totalValue, setTotalValue] = useState(0);

  // Observar valores dos campos para cálculo do total
  const dailyCount = watch('dailyCount');
  const dailyRate = watch('dailyRate');
  const extrasTotal = watch('extrasTotal');
  const feesTotal = watch('feesTotal');
  const markupValue = watch('markupValue');

  const currencyList = [{ value: 'BRL', label: 'BRL' }];
  const exchangeRates = [{ value: '1', label: '1,00' }];

  // Função para converter string formatada para número
  function convertCurrencyToNumber(value: string): number {
    const cleanedValue = value.replace(/[^\d,]/g, '').replace(',', '.');
    return Number(cleanedValue) || 0;
  }

  // UseEffect ajustado para calcular o total corretamente
  useEffect(() => {
    const total =
      dailyCount * convertCurrencyToNumber(dailyRate) +
      convertCurrencyToNumber(extrasTotal) +
      convertCurrencyToNumber(feesTotal) +
      convertCurrencyToNumber(markupValue);
    setTotalValue(total);
  }, [dailyCount, dailyRate, extrasTotal, feesTotal, markupValue]);

  const onFeeTariffSubmit = async (data: FormItemDataProps) => {
    try {
      showLoading();
      useFeeTariffRatesStore.getState().setTariff(data);

      // Atualizar os valores do veículo
      const updatedVehicleValues = {
        currencyCode: data.currency,
        exchangeRate: data.exchangeRate,
        dailyFare: {
          value: convertCurrencyToNumber(data.dailyRate),
          currencyCode: data.currency,
        },
        dailyTax: {
          value: convertCurrencyToNumber(data.feesTotal),
          currencyCode: data.currency,
        },
        markup: {
          value: convertCurrencyToNumber(data.markupValue),
          currencyCode: data.currency,
        },
        serviceValue: {
          value: convertCurrencyToNumber(data.extrasTotal),
          currencyCode: data.currency,
        },
        total: {
          value: totalValue,
          currencyCode: data.currency,
        },
      };

      const updatedServiceRequestItem = {
        ...currentServiceRequestItem,
        vehicle: {
          ...currentServiceRequestItem.vehicle,
          values: updatedVehicleValues,
          days: dailyCountByDate,
        },
      };

      updateCurrentServiceRequestItem(updatedServiceRequestItem);

      const request = {
        contextId: contextId,
        serviceRequestItem: updatedServiceRequestItem,
      };

      const response = await saveServiceRequestItem(request);

      if (response.successful) {
        openModal({
          title: 'Taxas e tarifas adicionada com sucesso!',
          subTitle:
            'Foram adicionadas as taxas e tarifas referente ao item de veículos.',
          status: 'success',
          primaryButtonLabel: 'Continuar',
          primaryButtonAction: () => {
            closeModal();
            toggleModal();
            onSaveSuccess();
          },
        });
      }
    } catch (e) {
      dispatchToast({
        type: 'error',
        content: 'Erro ao salvar as informações de tarifas',
      });
    } finally {
      hideLoading();
    }
  };

  const currencyMask = IMask.createMask({
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ',',
    mapToRadix: ['.'],
    prepare: (str) => str.replace(/[^\d,]/g, ''),
    overwrite: true,
  });

  const dailyRateRef = useRef(null);

  useEffect(() => {
    if (isModal && dailyRateRef.current) {
      dailyRateRef.current.focus();
    }
  }, [isModal]);

  return (
    <div>
      <Modal.Root open={isModal} onClose={toggleModal} size={'md'}>
        <>
          <Modal.Header title={'Taxas e tarifas'}></Modal.Header>
          <Modal.Content>
            <SC.RequestContainer>
              <div
                style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
              >
                <div style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="currency"
                    render={({ field, fieldState: { error } }) => (
                      <SelectField
                        placeholder={'Moeda'}
                        onSelectChange={(event, selectedItem) => {
                          field.onChange(selectedItem.value);
                        }}
                        fullWidth
                        error={!!error}
                        value={field.value}
                        options={currencyList}
                        label={'Selecione a moeda'}
                        helperText={
                          <DisplayErrorFormValidation
                            errors={error}
                            fieldName={'type'}
                          />
                        }
                        {...(field as any)}
                      />
                    )}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="exchangeRate"
                    render={({ field, fieldState: { error } }) => (
                      <SelectField
                        placeholder={'Câmbio'}
                        fullWidth
                        error={!!error}
                        value={field.value}
                        options={exchangeRates}
                        label={'Selecione o câmbio'}
                        disabled={true}
                        onSelectChange={(event, selectedItem) => {
                          field.onChange(selectedItem.value);
                        }}
                        helperText={
                          <DisplayErrorFormValidation
                            errors={error}
                            fieldName={'type'}
                          />
                        }
                        {...(field as any)}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
              >
                <div style={{ width: '100%' }}>
                  <Controller
                    name="dailyCount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        style={{ width: '100%' }}
                        {...(field as any)}
                        label="Qtd. de diárias"
                        helperText={
                          <DisplayErrorFormValidation
                            errors={errors}
                            fieldName={'dailyCount'}
                          />
                        }
                        disabled
                      />
                    )}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Controller
                    name="dailyRate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        ref={dailyRateRef}
                        style={{ width: '100%' }}
                        imaskProps={currencyMask}
                        {...(field as any)}
                        label="Valor da diária"
                        helperText={
                          <DisplayErrorFormValidation
                            errors={errors}
                            fieldName={'dailyRate'}
                          />
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
              >
                <div style={{ width: '100%' }}>
                  <Controller
                    name="extrasTotal"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        style={{ width: '100%' }}
                        imaskProps={currencyMask}
                        {...(field as any)}
                        label="Total dos adicionais"
                        helperText={
                          <DisplayErrorFormValidation
                            errors={errors}
                            fieldName={'extrasTotal'}
                          />
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Controller
                    name="feesTotal"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        style={{ width: '100%' }}
                        imaskProps={currencyMask}
                        {...(field as any)}
                        label="Total das taxas"
                        helperText={
                          <DisplayErrorFormValidation
                            errors={errors}
                            fieldName={'feesTotal'}
                          />
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Controller
                    name="markupValue"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        style={{ width: '100%' }}
                        imaskProps={currencyMask}
                        {...(field as any)}
                        label="Valor de markup"
                        helperText={
                          <DisplayErrorFormValidation
                            errors={errors}
                            fieldName={'markupValue'}
                          />
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div>Valor total: R$ {totalValue.toFixed(2)}</div>
            </SC.RequestContainer>
          </Modal.Content>
          <Modal.Footer
            cancelText={'Cancelar'}
            onCancel={closeModal}
            onConfirm={handleSubmit(onFeeTariffSubmit)}
            confirmText={'Adicionar'}
          ></Modal.Footer>
        </>
      </Modal.Root>
    </div>
  );
}
