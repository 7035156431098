import { ConditionFieldConfig } from '@components/CriteriaFields/hooks/useDefaultFieldConfiguration';
import { CurrencyInput } from '@components/CurrencyInput';
import {
  DatePicker,
  SelectField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import ConditionQuerySelect from '../ConditionQuerySelect';

type Props = {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
  fieldsConfig: ConditionFieldConfig[];
  selectedField: string;
};

export const ConditionValueField = ({
  field,
  fieldState,
  selectedField,
  fieldsConfig,
}: Props) => {
  const fieldOptions = fieldsConfig.find((f) => f.key === selectedField);

  if (fieldOptions?.type === 'date') {
    return (
      <DatePicker
        {...field}
        type="date"
        fullWidth
        onChange={null}
        onDateChange={field.onChange}
        sx={{ width: 200, mr: 2 }}
        label="Insira a data"
        error={fieldState.invalid}
        helperText={fieldState.error?.message}
      />
    );
  }

  if (fieldOptions?.type === 'text') {
    return (
      <TextField
        {...field}
        fullWidth
        sx={{ width: 200, mr: 2 }}
        label="Insira um texto"
        error={fieldState.invalid}
        helperText={fieldState.error?.message}
      />
    );
  }

  if (fieldOptions?.type === 'number') {
    return (
      <TextField
        {...field}
        type="number"
        fullWidth
        sx={{ width: 200, mr: 2 }}
        label="Insira um valor"
        error={fieldState.invalid}
        helperText={fieldState.error?.message}
      />
    );
  }

  if (fieldOptions?.type === 'currency') {
    return (
      <CurrencyInput
        {...field}
        onValueChange={(d) => field.onChange(d.floatValue)}
        label="Insira um valor"
        fullWidth
        sx={{ width: 200, mr: 2 }}
        error={fieldState.invalid}
        helperText={fieldState.error?.message}
      />
    );
  }

  if (
    fieldOptions?.type === 'select' &&
    !Array.isArray(fieldOptions?.options)
  ) {
    return (
      <ConditionQuerySelect
        query={fieldOptions.options}
        value={field.value}
        onSelectChange={(_, selectedItem) => {
          field.onChange(selectedItem?.value);
        }}
        fullWidth
        label="Selecione um valor"
        sx={{ width: 200, mr: 2 }}
        error={fieldState.invalid}
        helperText={fieldState.error?.message}
      />
    );
  }

  return (
    <SelectField
      {...field}
      fullWidth
      onSelectChange={(_, selectedItem) => {
        field.onChange(selectedItem?.value);
      }}
      options={Array.isArray(fieldOptions?.options) ? fieldOptions.options : []}
      label={'Selecione um valor'}
      sx={{ width: 200, mr: 2 }}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      disabled={!fieldOptions || fieldOptions?.isDisabled}
    />
  );
};
