import { trpc } from '@api/bff/client';
import { useGenerateFile } from '@shared/hooks/useGenerateFile';
import { dispatchToast } from '@shared/toast';

export function useAppsAudit(contextId, serviceRequestItemId) {
  const download = useGenerateFile();
  const { data, isFetching, refetch, isError, isFetchedAfterMount } =
    trpc.getIntegrationAudit.useQuery(
      { contextId, serviceRequestItemId },
      { enabled: false, retry: false },
    );

  if (isError) {
    dispatchToast({
      type: 'error',
      content: 'Não foi possível obter os dados de auditoria',
    });
  }

  if (data?.request && isFetchedAfterMount) {
    download(data.request, 'request');
  }

  return { isLoading: isFetching, downloadFile: refetch };
}
