import { Section } from '@components/Section';
import { CreateApprovalFlowInput } from 'bff/src/routers/approval-flows';
import { WizardStep } from '@components/Wizard/components/WizardStep';
import ApprovalTypeField from './components/ApprovalTypeField';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import React from 'react';
import FlowModeType from './components/FlowModeType';
import ApproversFields from './components/ApproversFields';
import { ECustomApprovalType } from '../../../types';

export const ApprovalFlowTypes = () => {
  const { control } = useFormContext<CreateApprovalFlowInput>();
  const { field } = useController({ control, name: 'approvalType' });

  return (
    <WizardStep
      title="Tipo de aprovação"
      description="Selecione de que forma acontecerá a aprovação pelos aprovadores."
    >
      <Section
        title="Modo de Aprovação"
        caption="Defina, através das opções, qual será o tipo de aprovação para este fluxo."
      >
        <ApprovalTypeField />
      </Section>

      {field.value === ECustomApprovalType.CUSTOM && (
        <Section
          title="Aprovadores"
          caption="Configurar os níveis de aprovação ou permitir que o sistema aprove ou reprove automaticamente os relatórios."
        >
          <Box>
            <FlowModeType />
          </Box>
          <Box>
            <ApproversFields />
          </Box>
        </Section>
      )}
    </WizardStep>
  );
};
