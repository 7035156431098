import React, { useState } from 'react';
import { SelectWrapper } from '@shared/components/Form/SelectWrapper';
import { CreditCard } from '@components/RequestTravel/RequestTravelOffline/TravelInfoPayment/CreditCard';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import useModalStore from '../../../../store/Modal.store';
import { dataSources } from '@legacy-dataSources/profile.dataSources';
import { BaseSelectOption } from '@shared/types/form/select.types';
import { FormSection } from '@shared/components/Form/FormSection';

export function TravelInfoPayment() {
  const [newPaymentOptions, setNewPaymentOptions] = useState<
    BaseSelectOption[]
  >([]);
  const contextId = useTravelStore((state) => state.contextId);
  const costCenter = useTravelStore((state) => state.costCenter);
  const requester = useTravelStore((state) => state.requester);
  const traveler = useTravelStore((state) => state.traveler);
  const { openModal, closeModal } = useModalStore();

  const loadPaymentOptions = async () => {
    const loadOptions = {
      contextId,
      products: 1,
      supplier: 'all',
      costCenter: costCenter?.id,
      travelers: traveler?.userId,
      requester: requester.userId,
    };

    const responses = await dataSources.paymentForms.load(loadOptions);
    const defaultOption = {
      label: '+ Adicionar forma de pagamento',
      value: 'new_payment',
    };

    return [defaultOption, ...responses];
  };

  const onSaveCard = (cardData) => {
    const number = cardData.number.replace(/\s+/g, '');
    const [expireMonth, expireYear] = cardData.expiry.split('/').map(Number);

    const newPaymentOption = {
      label: `Cartão Novo Final - ${cardData.number.slice(-4)}`,
      value: `card_${cardData.number}`,
      paymentForm: {
        type: 1,
        creditCard: {
          number,
          expireYear,
          expireMonth,
          printedName: cardData.name,
          cardName: cardData.name,
          securityCode: cardData.cvc,
          provider: cardData.cardType,
        },
        instalments: 0,
        productType: 1,
      },
    };

    setNewPaymentOptions((prevOptions) => [...prevOptions, newPaymentOption]);
    closeModal();
  };

  const handleChangeValue = (selectedItem) => {
    if (selectedItem?.value === 'new_payment') {
      openModal({
        title: 'Cadastre um novo cartão',
        subTitle:
          'Preencha as informações abaixo e cadastre um novo cartão para pagamento',
        content: <CreditCard onSaveCard={onSaveCard} />,
        isAlert: false,
      });
    }
  };

  return (
    <FormSection title="Informações de pagamento">
      <SelectWrapper
        name="paymentForms"
        label="Selecione a forma de pagamento"
        loadOptions={loadPaymentOptions}
        onChangeValue={handleChangeValue}
        additionalOptions={newPaymentOptions}
        fullWidth
        searchable
        rules={{
          required: 'Forma de pagamento é obrigatória',
        }}
      />
    </FormSection>
  );
}
