import {
  SelectField,
  Skeleton,
  TextArea,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { Section } from '@components/Section';
import { ModalReorderingList } from '../ModalReorderingList';
import React, { useMemo } from 'react';
import { CreateApprovalFlowInput } from 'bff/src/routers/approval-flows';
import { WizardStep } from '@components/Wizard/components/WizardStep';
import { useApprovalTable } from '../../hooks/useApprovalTable';
import { IOptionsSource } from '../../../types';

type Props = {
  isLoading?: boolean;
};

export const ApprovalFlowBasicInformation = ({ isLoading }: Props) => {
  const approvalFlowForm = useFormContext<CreateApprovalFlowInput>();

  const { filteredItems } = useApprovalTable();

  const priorityOptions = useMemo(() => {
    // Sort filteredItems by priority
    const sortedItems = [...filteredItems].sort(
      (a, b) => a.priority - b.priority,
    );

    // Find the highest priority
    const highestPriority =
      sortedItems.length > 0 ? sortedItems[sortedItems.length - 1].priority : 0;

    // Create options including the next available priority
    const options: IOptionsSource[] = sortedItems.map((item) => ({
      value: item.priority,
      label: `${item.priority} - ${item.name}`,
    }));

    // Add the next available priority
    options.push({
      value: highestPriority + 1,
      label: `${highestPriority + 1} - Nova prioridade`,
    });

    return options;
  }, [filteredItems]);

  const step = {
    title: 'Informações básicas',
    description:
      'Dê um nome e descreva a finalidade do fluxo que está sendo criado.',
  };

  if (isLoading) {
    return (
      <WizardStep title={step.title} description={step.description}>
        <Skeleton width="100%" height="300px" />

        <Box mt={5}>
          <Skeleton width="100%" height="500px" />
        </Box>
      </WizardStep>
    );
  }

  return (
    <WizardStep title={step.title} description={step.description}>
      <Section
        title="Informações básicas"
        caption="Insira as informações básicas do fluxo de aprovação, e quais
                serão os critérios para ativação"
      >
        <Box sx={{ marginTop: 2 }}>
          <Controller
            control={approvalFlowForm.control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                label="Nome do fluxo"
                fullWidth
                required
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                inputRef={field.ref}
                {...field}
                onChange={(d) => field.onChange(d.target.value)}
              />
            )}
          />
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          my={2}
          justifyContent={'space-between'}
        >
          <Box width={'50%'}>
            <Controller
              control={approvalFlowForm.control}
              name="priority"
              render={({ field: { ref, ...field }, fieldState }) => (
                <SelectField
                  {...field}
                  fullWidth
                  inputRef={ref}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label={'Prioridade'}
                  onSelectChange={(_, data) => field.onChange(data.value)}
                  options={priorityOptions}
                />
              )}
            />
          </Box>
          <Box>
            <ModalReorderingList />
          </Box>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Controller
            name={`description`}
            render={({ field, fieldState: { error } }) => (
              <TextArea
                onChange={field.onChange}
                value={field.value}
                {...(field as any)}
                placeholder={'Descrição do fluxo'}
                errorMessage={error ? 'Campo obrigatório' : ''}
              />
            )}
          />
        </Box>
      </Section>
    </WizardStep>
  );
};
