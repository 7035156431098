import React, { useState, useMemo, useCallback } from 'react';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';
import { useTableColumns } from '../../ApprovalTable/hooks/useTableColumns';
import { useApprovalFlows } from './useApprovalFlows';
import {
  TravelTableColumn,
  TravelTableFilter,
} from '@components/TravelDataTable/types';
import { ApprovalStatus } from 'bff/src/routers/approval-flows/enums';
import { ToggleApprovalFlowStatus } from '../../ApprovalTable/components/ToggleApprovalFlowStatus';
import { ApprovalItem } from '../../types';

const INITIAL_PAGINATION: PaginationState = {
  pageNumber: 1,
  pageSize: 10,
};

export function useApprovalTable() {
  const [pagination, setPagination] =
    useState<PaginationState>(INITIAL_PAGINATION);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const { approvalItems, isLoading, refetch, isError } = useApprovalFlows();

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  const handleFilters = useCallback((newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  const filteredItems = useMemo(() => {
    return approvalItems.filter((item) => {
      // Aplicar filtros de pesquisa
      const matchesSearch = Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase()),
      );

      // Aplicar filtros específicos
      const matchesFilters = Object.entries(filters).every(([key, values]) => {
        // Acessa a propriedade correta do item e compara com os valores do filtro
        const itemValue = item[key as keyof ApprovalItem]?.toString() ?? '';

        // Verifica se o itemValue está incluído nos valores do filtro
        return values.length === 0 || values.includes(itemValue);
      });

      return matchesSearch && matchesFilters;
    });
  }, [approvalItems, search, filters]);

  const paginatedItems = useMemo(
    () =>
      filteredItems.slice(
        (pagination.pageNumber - 1) * pagination.pageSize,
        pagination.pageNumber * pagination.pageSize,
      ),
    [filteredItems, pagination],
  );

  const table = useTableColumns({
    data: paginatedItems,
    onPaginationChange: setPagination,
    pagination,
    refetch,
  });

  return {
    filteredItems,
    pagination,
    search,
    isLoading,
    table,
    handleSearch,
    handleFilters,
    setPagination,
    refetch,
    isError,
  };
}

export function useApprovalColumns(): TravelTableColumn<ApprovalItem>[] {
  return [
    {
      accessorKey: 'name',
      header: 'Nome do fluxo',
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row }) => <ToggleApprovalFlowStatus policy={row.original} />,
    },
  ];
}

export function useApprovalFilters(): TravelTableFilter[] {
  return [
    {
      name: 'priority',
      label: 'Prioridade',
      options: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      options: [
        { label: 'Ativo', value: ApprovalStatus.ACTIVE },
        { label: 'Inativo', value: ApprovalStatus.INACTIVE },
      ],
    },
  ];
}
