import { trpc } from '@api/bff/client';
import { TravelDataTable } from '@components/TravelDataTable';
import { Box } from '@mui/material';
import { useState } from 'react';
import { CustomFieldRegister } from '../CustomFieldRegister';
import { AddCustomFieldButton } from './components/AddCustomFieldButton';
import CustomFieldTableActions from './components/CustomFieldTableActions';
import {
  useCustomFieldFilters,
  useCustomFieldsColumns,
} from './hooks/useCustomFieldsTable';
import { MAX_CUSTOM_FIELDS } from './constants';

const ONE_MINUTE = 60000;

export function CustomFieldsTable() {
  const { data, isError, isLoading, refetch } = trpc.getCustomFields.useQuery(
    null,
    { staleTime: ONE_MINUTE },
  );

  const columns = useCustomFieldsColumns();
  const filters = useCustomFieldFilters();

  const [editingCustomFieldId, setEditingCustomFieldId] = useState<
    string | null
  >(null);

  const [showCustomFieldRegister, setShowCustomFieldRegister] = useState(false);

  const isCreationDisabled =
    (data?.customFields?.length || 0) >= MAX_CUSTOM_FIELDS;

  return (
    <Box width="100%">
      <Box border={1} color={'#ebe6e9'} p={2} borderRadius={2}>
        <TravelDataTable
          header={{ title: 'Campos customizados', tagLabel: 'campos' }}
          headerEndContent={
            <AddCustomFieldButton
              onClick={() => setShowCustomFieldRegister(true)}
              isMaximumReached={isCreationDisabled}
            />
          }
          search={{
            placeholder: 'Busca por nome do campo customizado',
          }}
          columns={columns}
          filters={filters}
          rows={data?.customFields || []}
          renderActions={({ item: customField }) => (
            <CustomFieldTableActions
              customField={customField}
              onRefresh={refetch}
              onEdit={() => {
                setEditingCustomFieldId(customField.id);
                setShowCustomFieldRegister(true);
              }}
              disableDuplication={isCreationDisabled}
            />
          )}
          isLoading={isLoading}
          isError={isError}
        />
      </Box>

      {showCustomFieldRegister && (
        <>
          <CustomFieldRegister
            customFieldId={editingCustomFieldId}
            onCancel={() => {
              setEditingCustomFieldId(null);
              setShowCustomFieldRegister(false);
            }}
            onSave={() => {
              refetch?.();

              setEditingCustomFieldId(null);
              setShowCustomFieldRegister(false);
            }}
          />
        </>
      )}
    </Box>
  );
}
