export enum ApprovalStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ConditionType {
  MANUAL = 'MANUAL',
  HIERARCHY = 'HIERARCHY',
  COST_CENTER_OWNER = 'COST_CENTER_OWNER',
}
