import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const FixedMessageContainer = styled.li`
  margin: ${(props) => props.theme.spacings.xs}
    ${(props) => props.theme.spacings.xs3} 0
    ${(props) => props.theme.spacings.xs};
  border-top: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[95]};
`;

export const OptionContainer = styled.li`
  margin: 6px 0;
`;

export const OptionContent = styled.div`
  color: ${(props) => props.theme.colors.neutral[30]};
  fill: ${(props) => props.theme.colors.neutral[40]};
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;

export const OptionCaption = styled(Typography)`
  color: ${(props) => props.theme.colors.neutral[60]};
  font-weight: 600 !important;
`;

export const OptionLabel = styled(Typography)`
  color: ${(props) => props.theme.colors.neutral[30]};
  font-weight: 700 !important;
`;

export const Image = styled.img`
  width: 150px;
  margin: 0 auto;
`;
