export function generateCodeByText(input: string): string {
  // Remove spaces from the beginning and the end
  let result = input.trim();

  // Remove special characters and accents
  result = result.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Remove accents
  result = result.replace(/[^a-zA-Z0-9\s-]/g, ''); // Remove special characters, but keep '-'

  // Remove duplicate spaces
  result = result.replace(/\s+/g, ' ');

  // Replace spaces with "-"
  result = result.replace(/\s/g, '-');

  // Remove duplicate hyphens
  result = result.replace(/-+/g, '-');

  // Convert to lowercase
  result = result.toLowerCase();

  return result;
}

export function sanitizeCode(code: string) {
  return code
    .trimStart() // Removes spaces at the beginning
    .normalize('NFD') // Separates the accent marks from characters
    .replace(/[\u0300-\u036f]/g, '') // Removes the accent marks
    .replace(/[^a-zA-Z0-9@#$*.\-\s]/g, '') // Keeps letters, numbers, @, #, $, *, ., -, and spaces
    .replace(/\s+/g, '-') // Replaces spaces with hyphens
    .toLowerCase();
}
