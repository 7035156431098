import { trpc } from '@api/bff/client';
import { Icon } from '@components/Pills/styled';
import { TravelDataTable } from '@components/TravelDataTable';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { useState } from 'react';
import { ReasonRegister } from '../ReasonRegister';
import ReasonTableActions from './components/ReasonTableActions';
import ReasonTableBatchActions from './components/ReasonTableBatchActions';
import { useReasonsColumns, useReasonsFilters } from './hooks/useReasonsTable';

const ONE_MINUTE = 60000;

export function ReasonsTable() {
  const { data, isError, isLoading, refetch } = trpc.getReason.useQuery(null, {
    staleTime: ONE_MINUTE,
  });

  const columns = useReasonsColumns();
  const filters = useReasonsFilters();

  const [editingReasonId, setEditingReasonId] = useState<string | null>(null);

  const [showReasonRegister, setShowReasonRegister] = useState(false);

  return (
    <Box width="100%">
      <Box border={1} color={'#ebe6e9'} p={2} borderRadius={2}>
        <TravelDataTable
          header={{ title: 'Motivos de Viagem', tagLabel: 'motivos de viagem' }}
          headerEndContent={
            <Button
              size="large"
              variant={'primary'}
              onClick={() => setShowReasonRegister(true)}
            >
              Adicionar motivo de viagem <Icon name="IconPlus" />
            </Button>
          }
          search={{
            placeholder: 'Busca por nome ou código do motivo de viagem',
          }}
          columns={columns}
          filters={filters}
          rows={data?.reasons || []}
          renderActions={({ item: reason }) => (
            <ReasonTableActions
              reason={reason}
              onRefresh={refetch}
              onEdit={() => {
                setEditingReasonId(reason.id);
                setShowReasonRegister(true);
              }}
            />
          )}
          renderBatchActions={(table) => (
            <ReasonTableBatchActions table={table} onRefresh={refetch} />
          )}
          checkboxSelection
          isLoading={isLoading}
          isError={isError}
        />
      </Box>

      {showReasonRegister && (
        <ReasonRegister
          reasonId={editingReasonId}
          onCancel={() => {
            setEditingReasonId(null);
            setShowReasonRegister(false);
          }}
          onSave={() => {
            refetch?.();

            setShowReasonRegister(false);
            setEditingReasonId(null);
          }}
        />
      )}
    </Box>
  );
}
