import { useTravelStore } from '../../../../store/RequestTravel.store';
import { SelectWrapper } from '@shared/components/Form/SelectWrapper';
import { BaseSelectOption } from '@shared/types/form/select.types';

export function TravelSelectCompanie() {
  const { rootContexts, saveCompany, updateContextId } = useTravelStore();

  const transformCompany = (company: any): BaseSelectOption => ({
    ...company,
    label: company.companyName,
    value: company.id,
  });

  const handleCompanyChange = (selected: BaseSelectOption | null) => {
    if (selected) {
      updateContextId(selected.value);
      saveCompany(selected);
    }
  };

  const loadCompanies = async (): Promise<BaseSelectOption[]> => {
    return rootContexts.map(transformCompany);
  };

  return (
    <SelectWrapper
      name="company"
      label="Selecione o cliente"
      fullWidth
      searchable
      loadOptions={loadCompanies}
      transform={transformCompany}
      onChangeValue={handleCompanyChange}
    />
  );
}
