import { PolicyLevel } from 'bff/src/routers/policies/enums';

export function getPolicyLevelName(level: PolicyLevel) {
  const levelMap: Record<PolicyLevel, string> = {
    AREA: 'Área',
    COMPANY: 'Empresa',
    JOB_POSITION: 'Cargo',
    PROJECT: 'Projeto',
    SUBSIDIARY: 'Filial',
    USER: 'Usuário',
  };

  return levelMap[level];
}
