export enum TravelProfileType {
  BASIC = 'BASIC',
  REQUESTER = 'REQUESTER',
  FULL = 'FULL',
  APPROVER = 'APPROVER',
}

export type Employee = {
  id: string;
  name: string;
  email: string;
  currentProfile?: {
    type: TravelProfileType;
    label: string;
  };
};
