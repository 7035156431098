import { trpc } from '@api/bff/client';
import { dispatchToast } from '@shared/toast';
import { JustificationStatus } from 'bff/src/routers/justifications/enums';
import { TravelProduct } from 'bff/src/routers/policies/enums';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { JustificationRegisterFormData } from '../types';

type Props = {
  justificationId?: string;
  onSave?: () => void;
};

export function useJustificationForm({ justificationId, onSave }: Props) {
  const justification = trpc.getJustificationById.useQuery(
    { id: justificationId },
    {
      enabled: !!justificationId,
      refetchOnWindowFocus: false,
    },
  );

  const saveJustification = trpc.saveJustification.useMutation();

  const justificationForm = useForm<JustificationRegisterFormData>({
    defaultValues: {
      product: TravelProduct.ALL,
      code: '',
      name: '',
      requireDetails: false,
      applyForConsultant: false,
      status: JustificationStatus.ACTIVE,
    },
  });

  useEffect(() => {
    if (justification.data) {
      justificationForm.reset(justification.data);
    }
  }, [justification.data]);

  async function onSubmit(form: JustificationRegisterFormData) {
    try {
      await saveJustification.mutateAsync({ id: justificationId, ...form });

      dispatchToast({
        type: 'success',
        content: `A justificativa foi salvo com sucesso.`,
      });

      return onSave?.();
    } catch (err) {
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao salvar a justificativa. Tente novamente.',
      });
    }
  }

  return {
    isLoading: !!justificationId && justification.isFetching,
    justification,
    justificationForm,
    onSubmit,
  };
}
