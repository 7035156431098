import { TextField, TextFieldProps } from '@flash-tecnologia/hros-web-ui-v2';
import { sanitizeCode } from '@utils/code.utils';

import { useRef } from 'react';

type Props = TextFieldProps;

export const CodeField = ({ ...props }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const shouldGenerateCode = useRef(false);

  return (
    <div>
      <TextField
        fullWidth
        inputProps={{
          maxlength: 30,
          onFocus: (e) => e.target.select(),
        }}
        {...props}
        inputRef={inputRef}
        onChange={(e) => {
          e.target.value = sanitizeCode(e.target.value);

          props.onChange?.(e);

          shouldGenerateCode.current = false;
        }}
        helperText="Insira letras, números, e caracteres especiais (@, #, $, *, ., -); acentos serão removidos."
      />
    </div>
  );
};
