import { FlashPayControllers, FlashPayOptionsList } from '@components/FlashPay';
import PaymentSelector from '@legacy-components/common/payment-selector/payment-selector.component.js';
import { useState, useEffect, useMemo } from 'react';
import * as SC from './styled';
import { PaymentItem } from '@models/PaymentItem';
import { enums } from '../utils/enums';
import {
  PaymentOption,
  parseFlashPayToPaymentOption,
  parseToPaymentOption,
} from './parsers';
import HotelGuarantee from './HotelGuarantee';
import { Product } from './models';

export type LegacyPaymentInputsProps = {
  items: any;
  required: boolean;
  showValidationsFields: boolean;
  selectorDisabled?: boolean;
  onSelect: (paymentData: PaymentOption, price: number) => void;
  onUncheck: (product: Product) => void;
  travelers: any;
  flashPayVisible?: boolean;
  travelersCount: number;
  paymentList: PaymentItem[];
  isLoading?: boolean;
  hotelEnabled?: boolean;
  isUserARequesterProfile?: boolean;
  product: Product;
};

export function LegacyPaymentInputs({
  required,
  showValidationsFields,
  onSelect,
  items,
  travelers,
  flashPayVisible = false,
  selectorDisabled = false,
  travelersCount,
  paymentList,
  isLoading = false,
  hotelEnabled = true,
  onUncheck,
  isUserARequesterProfile,
  product,
}: LegacyPaymentInputsProps) {
  const [balanceVisible, setBalanceVisible] = useState(false);
  const [balanceInsufficient, setBalanceInsufficient] = useState(false);
  const [selectorItem, setSelectorItem] = useState(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  let [differentCardOnGuarantee, setDifferentCardOnGuarantee] = useState(false);

  const isHotel = (productType: number): boolean => {
    return [
      enums.purchaseConfiguration.product.hotel,
      enums.purchaseConfiguration.product.none,
      enums.purchaseConfiguration.product.all,
    ].includes(productType);
  };

  const onSelectCard = (paymentData) => {
    setSelectorItem(paymentData);
    const data = parseToPaymentOption(paymentData.paymentForm, product.type);

    setSelectedPaymentOption(data);
    onSelect(data, product.price);
    if (!differentCardOnGuarantee && isHotel(product.type))
      product.guaranteeOptions.onSelected(data);
  };

  const onSelectFlashPay = (paymentData: PaymentItem) => {
    const flashPaymentOption = parseFlashPayToPaymentOption(
      paymentData,
      product.type,
    );

    setSelectedPaymentOption(flashPaymentOption);
    onSelect(flashPaymentOption, product.price);

    if (!differentCardOnGuarantee && isHotel(product.type))
      product.guaranteeOptions.onSelected(flashPaymentOption);
  };

  useEffect(() => {
    if (!paymentList) return;

    const hasInsufficientBalance =
      !paymentList?.length ||
      !paymentList.some((payment) => product.price <= payment.balance);

    setBalanceInsufficient(hasInsufficientBalance);
  }, [JSON.stringify(paymentList)]);

  const onEnableFlashPay = () => {
    if (balanceVisible) {
      onUncheck(product);
      cleanSelectedOptions();
    }
    setBalanceVisible(!balanceVisible);
  };

  const cleanSelectedOptions = () => {
    onSelect({} as PaymentOption, product.price);
  };

  const variant = useMemo(() => {
    if (
      (product.selectedHotelsLength > 0 || isHotel(product.type)) &&
      !hotelEnabled
    )
      return 'soon';
    if (isLoading || travelers.length !== travelersCount) return 'disabled';
    if (balanceInsufficient || paymentList?.length === 0) return 'insufficient';
    if (paymentList?.length > 0 === true) return 'active';
    return 'disabled';
  }, [
    product.selectedHotelsLength,
    balanceInsufficient,
    JSON.stringify(paymentList),
    JSON.stringify(travelers),
  ]);

  const onHotelGuaranteeToggleChanged = (useDifferentCard: boolean) => {
    setDifferentCardOnGuarantee(useDifferentCard);
    if (useDifferentCard) product.guaranteeOptions.onSelected(null);
    else product.guaranteeOptions.onSelected(selectedPaymentOption);
  };

  return (
    <>
      <SC.FlexRow>
        <div>
          {items && (
            <PaymentSelector
              items={items}
              selectedItem={selectorItem}
              required={required}
              forceValidation={showValidationsFields}
              addCard={false}
              products={product.products}
              supplier={product.supplier}
              onPaymentSelected={onSelectCard}
              disabled={balanceVisible || selectorDisabled}
            />
          )}
        </div>
        {flashPayVisible && (
          <FlashPayControllers
            variant={variant}
            optionListIsVisible={balanceVisible}
            showOptionsList={onEnableFlashPay}
            isLoading={isLoading}
          />
        )}
      </SC.FlexRow>
      <div>
        {flashPayVisible && (
          <FlashPayOptionsList
            isVisible={
              balanceVisible &&
              !isLoading &&
              !!paymentList &&
              !balanceInsufficient
            }
            options={paymentList}
            title={
              paymentList?.length > 1
                ? 'Escolha uma das opções disponíveis:'
                : ''
            }
            onSelect={onSelectFlashPay}
            price={product.price}
            isCurrentUserATravelerProfile={!isUserARequesterProfile}
          />
        )}
      </div>
      {isHotel(product.type) && (
        <HotelGuarantee
          guaranteeOptions={product.guaranteeOptions}
          showValidationsFields={showValidationsFields}
          hotelHasGuarantee={product.showHotelGuarantee}
          onToggle={onHotelGuaranteeToggleChanged}
        />
      )}
    </>
  );
}
