import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useCallback } from 'react';
import useModalStore from '../../../../../store/Modal.store';
import { ButtonOptions } from '../types';

type Actions = 'delete' | 'edit' | 'duplicate' | 'unlink';

interface Props {
  delete?: ButtonOptions;
  edit?: Pick<ButtonOptions, 'loading' | 'disabled'>;
  duplicate?: Pick<ButtonOptions, 'loading' | 'disabled'>;
  unlink?: Pick<ButtonOptions, 'loading'>; // Adicionado unlink
  onDelete?: () => Promise<void> | void;
  onEdit?: () => Promise<void> | void;
  onDuplicate?: () => Promise<void> | void;
  onUnlink?: () => Promise<void> | void; // Adicionado onUnlink
  actions?: Actions[];
}

export const TableBaseActions = (props: Props) => {
  const actions = props.actions || ['delete', 'edit'];

  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);

  const handleDeleteFlow = useCallback(() => {
    openModal({
      title: props.delete?.title || 'Deseja realmente excluir?',
      subTitle:
        props.delete?.message ||
        'Essa ação não poderá ser desfeita, deseja realmente excluir?',
      status: props.delete?.status || 'error',
      primaryButtonLabel: props.delete?.primaryButtonLabel || 'Excluir',
      primaryButtonAction: async () => {
        try {
          await props.onDelete?.();

          closeModal();
        } catch (error) {
          throw error;
        }
      },
    });
  }, [props.onDelete, props.delete]);

  const handleUnlinkFlow = useCallback(() => {
    openModal({
      title: 'Deseja realmente desvincular?',
      subTitle: 'Essa ação não poderá ser desfeita, deseja continuar?',
      status: 'error',
      primaryButtonLabel: 'Desvincular',
      primaryButtonAction: async () => {
        await props.onUnlink?.();
        closeModal();
      },
    });
  }, [props.onUnlink]);

  return (
    <>
      {actions.includes('edit') && (
        <PillButton
          icon="IconEdit"
          size="small"
          type="secondary"
          variant="default"
          onClick={() => props.onEdit?.()}
          loading={props.edit?.loading}
          disabled={props.edit?.disabled}
        />
      )}

      {actions.includes('delete') && (
        <PillButton
          icon="IconTrash"
          size="small"
          type="secondary"
          variant="default"
          onClick={() => handleDeleteFlow()}
          loading={props.delete?.loading}
          disabled={props.delete?.disabled}
        />
      )}

      {actions.includes('duplicate') && (
        <PillButton
          icon="IconCopy"
          size="small"
          type="secondary"
          variant="default"
          onClick={() => props.onDuplicate?.()}
          loading={props.duplicate?.loading}
          disabled={props.duplicate?.disabled}
        />
      )}

      {actions.includes('unlink') && (
        <PillButton
          icon="IconUnlink"
          size="small"
          type="secondary"
          variant="default"
          onClick={() => handleUnlinkFlow()}
          loading={props.unlink?.loading}
        />
      )}
    </>
  );
};
