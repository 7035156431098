import { Button, Modal, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { ModalRootProps } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Modal/Root';

import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ContentContainer } from './styled';

type Props = PropsWithChildren<{
  title: string;
  description: string;
  onCancel?: () => void;
  onSaveClick?: () => void;
  isSaving?: boolean;
  isLoading?: boolean;
}> &
  Partial<Pick<ModalRootProps, 'open' | 'icon'>>;

export const FormModal = ({
  open = true,
  icon,
  onCancel,
  onSaveClick,
  title,
  description,
  isSaving,
  children,
  isLoading,
}: Props) => {
  return (
    <Modal.Root onClose={onCancel} size="md" open={open} icon={icon}>
      <>
        <Modal.Header
          title={title}
          description={description}
          style={{ marginBottom: 0 }}
        />

        <Modal.Content style={{ paddingLeft: 0, paddingRight: 0 }}>
          <ContentContainer>
            {isLoading ? (
              <Box>
                <Skeleton width="100%" height="300px" />
              </Box>
            ) : (
              children
            )}
          </ContentContainer>
        </Modal.Content>

        <Modal.Footer style={{ marginTop: 0 }}>
          <Box ml="auto">
            <Button
              onClick={onSaveClick}
              loading={isSaving}
              variant="primary"
              size="large"
            >
              Salvar
            </Button>
          </Box>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
