import { trpc } from '@api/bff/client';
import { TableStatusToggle } from '@components/TravelDataTable/components/TableStatusToggle';
import { ReasonStatus } from 'bff/src/routers/reasons/enums';

type Props = {
  reason: { id?: string; status?: ReasonStatus };
};

export const ToggleReasonStatus = ({ reason }: Props) => {
  const updateReasonStatus = trpc.updateReasonStatus.useMutation();

  return (
    <TableStatusToggle
      defaultChecked={reason.status === ReasonStatus.ACTIVE}
      isLoading={updateReasonStatus.isLoading}
      onToggleStatus={(value) =>
        updateReasonStatus.mutateAsync({
          id: reason.id,
          status: value ? ReasonStatus.ACTIVE : ReasonStatus.INACTIVE,
        })
      }
    />
  );
};
