import AirTicketCard from '../booking-air-tickets/booking-ticket-card/air-ticket-card.component.js';
import BookingTicketDetails from '../booking-air-tickets/booking-ticket-details/booking-ticket-details.component.js';
import Popup from '../../../popup/popup.component.js';
import Form from '../../../common/form/form.component.js';
import Field from '../../../field/field.component.js';
import BookingAirSegments from '../booking-air-segments/booking-air-segments.component.js';
import { enums } from '@legacy-utils/enums';

var Template = {
  main: ({
    airTickets,
    selectedTicketIndex,
    airTicketItem,
    airTicketItemEdit,
    allowedActions,
    canEdit,
    form,
    dataSources,
    bookingLocator,
    connectionSource,
    events,
    onShowEmailPopupClick,
  }) => {
    return (
      <div className="exon-booking-air-tickets">
        <div className="exon-box-air-ticket-card">
          {airTickets &&
            airTickets.map((ticket, index) => {
              return (
                <AirTicketCard
                  key={index}
                  ticketIndex={index}
                  selectedTicketIndex={selectedTicketIndex}
                  ticket={ticket}
                  onSelectedTicketClick={events.onSelectedTicketClicked}
                />
              );
            })}

          {canEdit && (
            <button
              className="exon-add-air-tickets"
              onClick={() =>
                form.events.popup.onOpenFormPopupClicked(enums.actionsForm.add)
              }
            >
              <span className="exon-icon exon-icon-add-unfilled_enabled"></span>
              Adicionar bilhete
            </button>
          )}
        </div>

        {airTicketItem !== null && selectedTicketIndex != undefined && (
          <BookingTicketDetails
            airTicket={airTicketItem}
            allowedActions={allowedActions}
            onReplaceButtonClick={() =>
              events.onReplaceButtonClicked(airTicketItem)
            }
            onCancelButtonClick={events.onCancelButtonClicked}
            onOpenFormPopupClick={form.events.popup.onOpenFormPopupClicked}
            onShowEmailPopupClick={onShowEmailPopupClick}
            onSendAppsClicked={events.onSendAppsClicked}
            canEdit={canEdit}
          />
        )}

        {form.show && (
          <div className="exon-box-popup-form-rv exon-box-popup-air-ticket">
            <Template.form
              data={form}
              bookingLocator={bookingLocator}
              connectionSource={connectionSource}
              canEdit={canEdit}
              airTicketItem={airTicketItem}
              airTicketItemEdit={airTicketItemEdit}
              dataSources={dataSources}
              events={events.form.popup}
              onEditSegmentsClick={events.onEditSegmentsClicked}
            />
          </div>
        )}
      </div>
    );
  },

  form: ({
    data,
    canEdit,
    airTicketItem,
    airTicketItemEdit,
    dataSources,
    bookingLocator,
    connectionSource,
    events,
    onEditSegmentsClick,
  }) => {
    return (
      <Popup
        okButtonLabel={'Salvar'}
        cancelButtonLabel={'Cancelar'}
        title={'Bilhete'}
        cancelButtonClick={events.onCloseFormPopupClicked}
        exonStyle={true}
        okButtonClick={events.onConfirmPopupClicked}
      >
        <Form>
          {Object.keys(data.fields).map((refName, index) => {
            let field = data.fields[refName];

            if (
              field.groupType != 'currencyCode' &&
              field.groupType != 'fare' &&
              field.groupType != 'exchange'
            )
              return (
                <div
                  key={index}
                  className={
                    'exon-box-Form-field ' +
                    (refName == 'locator' ? 'exon-w-100' : '')
                  }
                >
                  <Field
                    type={field.type}
                    label={field.label}
                    value={field.value}
                    items={field.items}
                    refName={field.refName}
                    mask={field.mask}
                    completeItem={field.completeItem}
                    locationType={field.locationType}
                    placeholder={field.placeholder}
                    forceValidation={data.forceValidation}
                    disabled={field.disabled}
                    required={field.required}
                    readOnly={field.readOnly}
                    isZeroValid={field.isZeroValid}
                    options={field.options}
                    dataSource={field.dataSource}
                    removeMask={field.removeMask}
                    onFieldChange={field.onFieldChange}
                  />

                  {refName == 'locator' && (
                    <div className="exon-label-divider">
                      <label>
                        <label>
                          <strong> Segmentos </strong>
                        </label>
                      </label>
                      <hr />
                    </div>
                  )}
                </div>
              );
          })}

          <BookingAirSegments
            airTrips={
              airTicketItemEdit && airTicketItemEdit.airTrips
                ? airTicketItemEdit.airTrips
                : []
            }
            bookingLocator={bookingLocator}
            connectionSource={connectionSource}
            canEdit={canEdit}
            displayFlightReplacement={
              false
            } /*TODO - implementar posteriormente*/
            onReplaceAirTripClick={null}
            events={{
              onEditSegmentsClick: onEditSegmentsClick,
            }}
            dataSources={dataSources}
          />

          <hr className="exon-w-100" />

          {Object.keys(data.fields).map((refName, index) => {
            let field = data.fields[refName];

            if (
              field.groupType == 'currencyCode' ||
              field.groupType == 'fare' ||
              field.groupType == 'exchange'
            )
              return (
                <div key={index} className="exon-box-form-field">
                  <Field
                    type={field.type}
                    label={field.label}
                    value={field.value}
                    items={field.items}
                    refName={field.refName}
                    mask={field.mask}
                    completeItem={field.completeItem}
                    locationType={field.locationType}
                    placeholder={field.placeholder}
                    forceValidation={data.forceValidation}
                    readOnly={field.readOnly}
                    disabled={field.disabled}
                    required={field.required}
                    isZeroValid={field.isZeroValid}
                    options={field.options}
                    dataSource={field.dataSource}
                    removeMask={field.removeMask}
                    onFieldChange={field.onFieldChange}
                  />
                </div>
              );
          })}
        </Form>
      </Popup>
    );
  },
};

export default Template;
