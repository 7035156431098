import { SalesIntegrationList } from '@components/SalesIntegration/SalesIntegrationList';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { UserProfileData } from '@shared/types';
import { useState } from 'react';
import PageBase from '../PageBase';

export function SalesIntegration() {
  const [_, setUserProfile] = useState<UserProfileData>(null);

  return (
    <PageBase
      pageTitleKey="pages.reports.salesIntegration.title"
      userProfileLoaded={(profileData) => setUserProfile(profileData)}
      skeleton={<Skeleton variant="rectangular" height="100px" width="100%" />}
    >
      <SalesIntegrationList />
    </PageBase>
  );
}
