import {
  BoxAdornment,
  Divider,
  Skeleton,
  TextField,
  Toggle,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';

import {
  CustomFieldStatus,
  CustomFieldType,
} from 'bff/src/routers/custom-fields/enums';
import { CustomField, CustomFieldRegisterForm } from '../../types';
import CustomFieldPreview from '../CustomFieldPreview';
import CustomFieldSelectOptions from '../CustomFieldSelectOptions';
import CustomFieldTypeSelect from '../CustomFieldTypeSelect';
import {
  Container,
  Content,
  Description,
  FieldDataWrapper,
  LeftWrapper,
  RightWrapper,
  Title,
} from './styled';

type Props = {
  customField?: CustomField;
  customFieldForm: UseFormReturn<CustomFieldRegisterForm>;
  isLoading: boolean;
};

export const CustomFieldForm = ({
  customField,
  customFieldForm,
  isLoading,
}: Props) => {
  const customFieldType = useWatch({
    control: customFieldForm.control,
    name: 'type',
  });

  if (isLoading) {
    return (
      <Box px="112px" py="32px">
        <Skeleton width="100%" height="300px" />
      </Box>
    );
  }

  return (
    <Container>
      <Content>
        <LeftWrapper>
          <Title variant="headline8">Configurar campo</Title>
          <Description variant="body4">
            Configure as informações do campo
          </Description>
          <FieldDataWrapper>
            <CustomFieldTypeSelect
              control={customFieldForm.control}
              customField={customField}
            />

            <Divider orientation="horizontal" />

            <Controller
              control={customFieldForm.control}
              name="name"
              render={({ field }) => (
                <TextField
                  label="Nome do campo"
                  fullWidth
                  required
                  {...field}
                />
              )}
              rules={{ required: true }}
            />

            <Divider orientation="horizontal" />

            {customFieldType === CustomFieldType.OPTIONS && (
              <CustomFieldSelectOptions control={customFieldForm.control} />
            )}

            <Box mb={1}>
              <BoxAdornment
                title="Ativar campo customizado"
                description="Quando ativo, o campo customizado aparece como um campo para preencher ao solicitar uma viagem."
                leftAdornment={
                  <Controller
                    control={customFieldForm.control}
                    name="status"
                    render={({ field }) => (
                      <Toggle
                        checked={field.value === CustomFieldStatus.ACTIVE}
                        onChange={(e, checked) =>
                          field.onChange(
                            checked
                              ? CustomFieldStatus.ACTIVE
                              : CustomFieldStatus.INACTIVE,
                          )
                        }
                      />
                    )}
                  />
                }
              />
            </Box>

            <BoxAdornment
              title="Campo obrigatório"
              description="Quando obrigatório, o usuário só pode solicitar a viagem caso preencha o campo."
              leftAdornment={
                <Controller
                  control={customFieldForm.control}
                  name="required"
                  render={({ field }) => (
                    <Toggle
                      defaultChecked={field.value}
                      onChange={() => field.onChange(!field.value)}
                    />
                  )}
                />
              }
            />
          </FieldDataWrapper>

          <Box height={42} />
        </LeftWrapper>
        <Divider removeAt={768} orientation="vertical" />
        <Divider appearAt={768} orientation="vertical" />

        <RightWrapper>
          <Box position="fixed" width={320}>
            <Title variant="headline8">Preview</Title>
            <Description variant="body4">
              Confira a pré-visualização do campo
            </Description>

            <CustomFieldPreview control={customFieldForm.control} />
          </Box>
        </RightWrapper>
      </Content>
    </Container>
  );
};
