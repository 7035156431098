import { TextArea } from '@flash-tecnologia/hros-web-ui-v2';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { dataSources } from '@legacy-dataSources/travelRequests.dataSources';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { SelectWrapper } from '@shared/components/Form/SelectWrapper';
import { FormSection } from '@shared/components/Form/FormSection';

interface TravelReasonProps {
  handleIsDescription: (isDescriptionRequired: boolean) => void;
}

export function TravelReason({ handleIsDescription }: TravelReasonProps) {
  const contextId = useTravelStore((state) => state.contextId);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);

  const loadTravelReasons = async () => {
    const loadOptions = { contextId };
    const travelReasons = await dataSources.travelReason.load(loadOptions);
    return travelReasons;
  };
  const handleReasonChange = (selectedItem: any) => {
    if (selectedItem) {
      const isRequired = !!selectedItem.reason.IsDescriptionRequired;
      setIsDescriptionRequired(isRequired);
      handleIsDescription(isRequired);
    }
  };

  return (
    <FormSection title="Motivo da viagem">
      <SelectWrapper
        name="reason_travel"
        label="Selecione o motivo"
        loadOptions={loadTravelReasons}
        onChangeValue={handleReasonChange}
        fullWidth
        searchable
      />
      {isDescriptionRequired && (
        <Controller
          control={control}
          name="description_reason_travel"
          render={({ field: { onChange, value, ...field } }) => (
            <div>
              <TextArea
                onChange={onChange}
                value={value}
                {...field}
                placeholder="Motivo da viagem"
              />
              <DisplayErrorFormValidation
                errors={errors}
                fieldName="description_reason_travel"
              />
            </div>
          )}
        />
      )}
    </FormSection>
  );
}
