import { useMemo } from 'react';
import { trpc } from '@api/bff/client';
import { ApprovalItem } from '../../types';

export function useApprovalFlows() {
  const { data, isLoading, refetch, isError } = trpc.getApprovalFlows.useQuery(
    undefined,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const approvalItems: ApprovalItem[] = useMemo(
    () =>
      data?.approvalFlows.map((flow) => ({
        id: flow.id,
        name: flow.name,
        approvalType: flow.approvalType,
        description: flow.description,
        priority: flow.priority ?? 0,
        status: flow.status,
      })) ?? [],
    [data],
  );

  return {
    approvalItems,
    isLoading,
    refetch,
    isError,
    data,
  };
}
