import Popup from '../../../popup/popup.component.js';
import Field from '../../../field/field.component.js';
import Form from '../../../common/form/form.component.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { fareBaggage } from '@legacy-utils/fareMapping';

var Template = {
  main: ({
    connectionSource,
    bookingLocator,
    airTrips,
    airTripsEdit,
    canEdit,
    fareRuleIndex,
    displayFareRule,
    displayFlightReplacement,
    events,

    //Edição e adição de itens
    form,
    popupEditData,
  }) => {
    let existingReplacementButtons = {};
    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;
    let segments = [];
    let fareRules = [];

    airTrips &&
      airTrips.map((airTrip, airTripIndex) => {
        airTrip.flights
          ? airTrip.flights.map((segment, segmentIndex) => {
              let baggages = airTrip.baggages ? airTrip.baggages : 1;
              let empty = null;

              segments.push({
                segmentIndex: segmentIndex,
                airTripIndex: airTripIndex,
                airline: segment.airline ? segment.airline.name : empty,
                supplier: airTrip.supplier
                  ? airTrip.supplier
                  : connectionSource
                    ? connectionSource
                    : empty,
                operatedBy: segment.operatedBy
                  ? `${segment.operatedBy.name} ${segment.operatedBy.code}`
                  : empty,
                flightNumber: segment.flightNumber
                  ? segment.flightNumber
                  : empty,
                classOfService: segment.classOfService
                  ? segment.classOfService
                  : empty,
                cabinType: segment.cabinType
                  ? enums.airClasses[segment.cabinType]
                  : empty,
                departure: segment.departure ? segment.departure.iata : empty,
                arrival: segment.arrival ? segment.arrival.iata : empty,
                departureDate: segment.departureDate
                  ? `${
                      getFormattedDateObjFromDateObject(segment.departureDate)
                        .dateFormatted
                    } ${
                      getFormattedDateObjFromDateObject(segment.departureDate)
                        .timeFormatted
                    }`
                  : empty,
                arrivalDate: `${
                  getFormattedDateObjFromDateObject(segment.arrivalDate)
                    .dateFormatted
                } ${
                  getFormattedDateObjFromDateObject(segment.arrivalDate)
                    .timeFormatted
                }`,
                layover: segment.layover > 0 ? 'Sim' : 'Não',
                status: segment.status ? segment.status : empty,
                locator:
                  segment.locator && segment.locator !== ''
                    ? segment.locator
                    : bookingLocator
                      ? bookingLocator
                      : empty,
                baggages: baggages,
                fareRuleIndex: segment.fareRuleIndex,
                originalSegment: segment,
              });

              fareRules.push(segment.fareRules || 'NÃO INFORMADO');
            })
          : {};
      });

    return (
      <div className="exon-booking-air-segments">
        <Template.segmentsTable
          segments={segments}
          canEdit={canEdit}
          showActions={false}
          events={events}
          displayFlightReplacement={displayFlightReplacement}
          existingReplacementButtons={existingReplacementButtons}
        />

        {displayFareRule && (
          <div className="exon-box-popup-fare-role">
            <Template.fareRulesPopup
              fareRule={fareRules[fareRuleIndex].replaceAll('\n', '<br />')}
              events={events}
            />
          </div>
        )}

        {form.show && (
          <div className="exon-box-popup-form exon-box-popup-form-rv">
            <Template.form
              data={form}
              bookingLocator={bookingLocator}
              events={events.form.popup}
            />
          </div>
        )}

        {popupEditData.show && (
          <div className="exon-box-popup-form">
            <Template.popup.editSegments
              airTripsEdit={airTripsEdit}
              connectionSource={connectionSource}
              bookingLocator={bookingLocator}
              segments={segments}
              canEdit={canEdit}
              showActions={true}
              form={form}
              events={events}
              displayFlightReplacement={displayFlightReplacement}
              existingReplacementButtons={existingReplacementButtons}
            />
          </div>
        )}
      </div>
    );
  },

  fareRulesPopup: ({ fareRule, events }) => {
    return (
      <Popup
        okButtonLabel={'ok'}
        cancelButtonLabel={null}
        title={'Regras Tarifárias'}
        cancelButtonClick={events.onCloseFareRuleClicked}
        exonStyle={true}
        okButtonClick={events.onCloseFareRuleClicked}
      >
        <div dangerouslySetInnerHTML={{ __html: fareRule }}></div>
      </Popup>
    );
  },

  segmentsTable: ({
    segments,
    airTripIndex,
    showActions,
    canEdit,
    events,
    displayFlightReplacement,
    existingReplacementButtons,
  }) => {
    return (
      <table className="exon-table-line" cellSpacing="0">
        <thead>
          <tr>
            <th>Companhia Aérea</th>
            <th>Sistema</th>
            <th>Operado por</th>
            <th>N. do vôo</th>
            <th>Classe</th>
            <th>Cabine</th>
            <th>Origem</th>
            <th>Destino</th>
            <th>Saída</th>
            <th>Chegada</th>
            <th>Escala</th>
            <th>Status do vôo</th>
            <th>Loc Cia</th>
            <th>Bagagem</th>
            {!showActions && <th>Regras tarifárias</th>}
            {displayFlightReplacement && <th></th>}

            {canEdit && (
              <th className="exon-table-actions-header">
                {showActions ? (
                  <span
                    className="exon-icon exon-icon-button exon-icon-add-unfilled_enabled"
                    onClick={() =>
                      events.form.popup.onOpenFormPopupClicked(
                        enums.actionsForm.add,
                        null,
                        airTripIndex,
                      )
                    }
                  ></span>
                ) : (
                  <span
                    className="exon-icon exon-icon-button exon-icon-add-unfilled_enabled exon-add-button"
                    onClick={() => events.onOpenEditPopupClicked()}
                  ></span>
                )}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {segments &&
            segments.length > 0 &&
            segments.map((segment, segmentIndex) => {
              let totalFlightsInTrip = segments.filter(
                (s) => s.airTripIndex == segment.airTripIndex,
              ).length;
              let empty = '-';

              let row = (
                <tr key={segmentIndex}>
                  <td className={!segment.airline ? 'exon-text-center' : ''}>
                    {segment.airline ? segment.airline : empty}
                  </td>
                  <td className={!segment.supplier ? 'exon-text-center' : ''}>
                    {segment.supplier ? segment.supplier : empty}
                  </td>
                  <td className={!segment.operatedBy ? 'exon-text-center' : ''}>
                    {segment.operatedBy ? segment.operatedBy : empty}
                  </td>
                  <td
                    className={!segment.flightNumber ? 'exon-text-center' : ''}
                  >
                    {segment.flightNumber ? segment.flightNumber : empty}
                  </td>
                  <td
                    className={
                      !segment.classOfService ? 'exon-text-center' : ''
                    }
                  >
                    {segment.classOfService ? segment.classOfService : empty}
                  </td>
                  <td className={!segment.cabinType ? 'exon-text-center' : ''}>
                    {segment.cabinType ? segment.cabinType : empty}
                  </td>
                  <td className={!segment.departure ? 'exon-text-center' : ''}>
                    {segment.departure ? segment.departure : empty}
                  </td>
                  <td className={!segment.arrival ? 'exon-text-center' : ''}>
                    {segment.arrival ? segment.arrival : empty}
                  </td>
                  <td
                    className={!segment.departureDate ? 'exon-text-center' : ''}
                  >
                    {segment.departureDate ? segment.departureDate : empty}
                  </td>
                  <td
                    className={!segment.arrivalDate ? 'exon-text-center' : ''}
                  >
                    {segment.arrivalDate ? segment.arrivalDate : empty}
                  </td>
                  <td
                    className={
                      segment.layover == null ? 'exon-text-center' : ''
                    }
                  >
                    {segment.layover > 0 ? 'Sim' : 'Não'}
                  </td>
                  <td className={!segment.status ? 'exon-text-center' : ''}>
                    {segment.status ? segment.status : empty}
                  </td>
                  <td className={!segment.locCia ? 'exon-text-center' : ''}>
                    {segment.locator ? segment.locator : empty}
                  </td>
                  <td className={!segment.baggages ? 'exon-text-center' : ''}>
                    {fareBaggage[segment.baggages]}
                  </td>
                  {segment.fareRuleIndex != null && !showActions && (
                    <td className="exon-fare-rule">
                      <span
                        className="exon-icon exon-icon-clipboard-unfilled_enabled"
                        onClick={() =>
                          events.onOpenFareRuleClicked(segment.fareRuleIndex)
                        }
                      ></span>
                    </td>
                  )}
                  {displayFlightReplacement &&
                    !existingReplacementButtons[segment.airTripIndex] && (
                      <td rowSpan={totalFlightsInTrip}>
                        <span
                          tooltip="Trocar este segmento"
                          className="exon-icon exon-icon-replacement-unfilled_enabled"
                          onClick={() =>
                            events.onReplaceAirTripClicked(segment.airTripIndex)
                          }
                        ></span>
                      </td>
                    )}

                  {canEdit && showActions && (
                    <td className="exon-table-action-item">
                      <span
                        className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
                        onClick={() =>
                          events.form.popup.onOpenFormPopupClicked(
                            enums.actionsForm.edit,
                            segmentIndex,
                            segment.airTripIndex,
                          )
                        }
                      ></span>
                      <span
                        className="exon-icon exon-icon-button exon-icon-dump-unfilled_enabled"
                        onClick={() =>
                          events.onDeleteSegmentClicked(
                            segmentIndex,
                            segment.airTripIndex,
                          )
                        }
                      ></span>
                    </td>
                  )}
                </tr>
              );

              if (!existingReplacementButtons[segment.airTripIndex])
                existingReplacementButtons[segment.airTripIndex] = true;

              return row;
            })}
        </tbody>
      </table>
    );
  },

  popup: {
    editSegments: ({
      segments,
      connectionSource,
      bookingLocator,
      airTripsEdit,
      showActions,
      form,
      canEdit,
      events,
      displayFlightReplacement,
      existingReplacementButtons,
    }) => {
      let airTripsCurrent =
        airTripsEdit && airTripsEdit.length > 0 ? airTripsEdit : [{}];
      let getFormattedDateObjFromDateObject =
        utils.formatters.date.getFormattedDateObjFromDateObject;
      let segmentsCurrent = [];

      return (
        <Popup
          okButtonLabel={'Salvar'}
          cancelButtonLabel={'Cancelar'}
          title={'Segmentos Aéreos'}
          cancelButtonClick={events.onCloseEditPopupClicked}
          exonStyle={true}
          okButtonClick={events.onConfirmEditPopupClicked}
        >
          {airTripsEdit &&
            airTripsEdit.map((airTrip, airTripIndex) => {
              airTrip.flights
                ? airTrip.flights.map((segment, segmentIndex) => {
                    let baggages = airTrip.baggages ? airTrip.baggages : 1;
                    let empty = null;
                    segment.baggages = baggages;

                    segmentsCurrent.push({
                      segmentIndex: segmentIndex,
                      airTripIndex: airTripIndex,
                      airline: segment.airline ? segment.airline.name : empty,
                      supplier: airTrip.supplier
                        ? airTrip.supplier
                        : connectionSource
                          ? connectionSource
                          : empty,
                      operatedBy: segment.operatedBy
                        ? `${segment.operatedBy.name} ${segment.operatedBy.code}`
                        : empty,
                      flightNumber: segment.flightNumber
                        ? segment.flightNumber
                        : empty,
                      classOfService: segment.classOfService
                        ? segment.classOfService
                        : empty,
                      cabinType: segment.cabinType
                        ? enums.airClasses[segment.cabinType]
                        : empty,
                      departure: segment.departure
                        ? segment.departure.iata
                        : empty,
                      arrival: segment.arrival ? segment.arrival.iata : empty,
                      departureDate: segment.departureDate
                        ? `${
                            getFormattedDateObjFromDateObject(
                              segment.departureDate,
                            ).dateFormatted
                          } ${
                            getFormattedDateObjFromDateObject(
                              segment.departureDate,
                            ).timeFormatted
                          }`
                        : empty,
                      arrivalDate: `${
                        getFormattedDateObjFromDateObject(segment.arrivalDate)
                          .dateFormatted
                      } ${
                        getFormattedDateObjFromDateObject(segment.arrivalDate)
                          .timeFormatted
                      }`,
                      layover: segment.layover > 0 ? 'Sim' : 'Não',
                      status: segment.status ? segment.status : empty,
                      locator:
                        segment.locator && segment.locator !== ''
                          ? segment.locator
                          : bookingLocator
                            ? bookingLocator
                            : empty,
                      baggages: baggages,
                      fareRuleIndex: segment.fareRuleIndex,
                      originalSegment: segment,
                    });
                  })
                : {};
            })}

          {airTripsCurrent.map((airTrip, index) => {
            let segmentList = [];

            segmentsCurrent.map((segment) => {
              if (segment.airTripIndex == index) segmentList.push(segment);
            });

            return (
              <Template.airTripItemEdit
                key={`airTrip-${index}`}
                indexItem={index}
                segmentList={segmentList}
                airTrips={airTripsEdit}
                canEdit={canEdit}
                showActions={showActions}
                airTripIndex={index}
                form={form}
                events={events}
                displayFlightReplacement={displayFlightReplacement}
                existingReplacementButtons={existingReplacementButtons}
              />
            );
          })}

          <button
            className="exon-add-air-tickets"
            onClick={() => events.onAddAirTripClicked()}
          >
            <span className="exon-icon exon-icon-add-unfilled_enabled"></span>
            Adicionar trecho
          </button>

          {/*<span*/}
          {/*    className="exon-icon exon-icon-button exon-icon-add-unfilled_enabled exon-add-button"*/}
          {/*    onClick={() => events.onAddAirTripClicked()}>*/}
          {/*</span>*/}
        </Popup>
      );
    },
  },

  airTripItemEdit: ({
    segmentList,
    airTrips,
    airTripIndex,
    canEdit,
    showActions,
    form,
    events,
    displayFlightReplacement,
    existingReplacementButtons,
    indexItem,
  }) => {
    return (
      <div>
        <div className="exon-label-divider">
          <label>
            <label>
              Trecho <strong> {`-${indexItem + 1}`} </strong>
              {airTrips.length > 1 && (
                <span
                  className="exon-icon exon-icon-button exon-icon-dump-unfilled_enabled"
                  onClick={() => events.onDeleteAirTripClicked(indexItem)}
                ></span>
              )}
            </label>
          </label>
          <hr />
        </div>

        <Form>
          <div className="exon-air-trip-edit">
            {Object.keys(form.fields).map((refName, fieldIndex) => {
              let field = form.fields[refName];
              return (
                <div key={fieldIndex} className="exon-box-form-field">
                  {(refName == `supplier-${indexItem}` ||
                    refName == `issuerAirline-${indexItem}`) && (
                    <Field
                      type={field.type}
                      label={field.label}
                      value={field.value}
                      items={field.items}
                      refName={field.refName}
                      mask={field.mask}
                      completeItem={field.completeItem}
                      locationType={field.locationType}
                      placeholder={field.placeholder}
                      forceValidation={form.forceValidation}
                      disabled={field.disabled}
                      required={field.required}
                      readOnly={field.readOnly}
                      options={field.options}
                      isZeroValid={field.isZeroValid}
                      dataSource={field.dataSource}
                      onFieldChange={field.onFieldChange}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </Form>

        {segmentList && segmentList.length == 0 && form.forceValidation && (
          <p className="exon-text-danger">
            Para continuar é necessário inserir os dados do segmento
          </p>
        )}

        <Template.segmentsTable
          segments={segmentList}
          airTripIndex={airTripIndex}
          canEdit={canEdit}
          showActions={showActions}
          events={events}
          displayFlightReplacement={displayFlightReplacement}
          existingReplacementButtons={existingReplacementButtons}
        />
      </div>
    );
  },

  form: ({ data, events }) => {
    return (
      <Popup
        okButtonLabel={'Salvar'}
        cancelButtonLabel={'Cancelar'}
        title={'Segmentos Aéreos'}
        cancelButtonClick={events.onCloseFormPopupClicked}
        exonStyle={true}
        okButtonClick={events.onConfirmPopupClicked}
      >
        <Form>
          {Object.keys(data.fields).map((refName, fieldIndex) => {
            let field = data.fields[refName];
            return (
              <div
                key={fieldIndex}
                className={
                  'exon-box-Form-field ' +
                  (field.adtionalClass ? field.adtionalClass : '')
                }
              >
                {(!field.groupType || field.groupType !== 'airTrip') && (
                  <Field
                    type={field.type}
                    label={field.label}
                    value={field.value}
                    items={field.items}
                    refName={field.refName}
                    mask={field.mask}
                    completeItem={field.completeItem}
                    locationType={field.locationType}
                    placeholder={field.placeholder}
                    forceValidation={data.forceValidation}
                    disabled={field.disabled}
                    required={field.required}
                    readOnly={field.readOnly}
                    options={field.options}
                    isZeroValid={field.isZeroValid}
                    dataSource={field.dataSource}
                    removeMask={field.removeMask}
                    onFieldChange={field.onFieldChange}
                  />
                )}
              </div>
            );
          })}
        </Form>
      </Popup>
    );
  },
};
export default Template;
