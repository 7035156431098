import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacings.xs};
`;

export const Image = styled.img`
  width: 150px;
  margin: 0 auto;
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme.colors.neutral[40]};
  font-weight: 700 !important;
  text-align: center;
`;

export const Caption = styled(Typography)`
  color: ${(props) => props.theme.colors.neutral[50]};
  font-weight: 600 !important;
  text-align: center;
`;
