import { dispatchToast } from '@shared/toast';
import { useState, useCallback } from 'react';
import { TableBaseActions } from '@components/TravelDataTable';
import { useProfileConfigurationStore } from '../../../store/ProfileConfiguration.store';
import { useProfileForm } from '../../../RegisterProfile/hooks/useProfileForm';

export type Props = {
  profile: any;
  onRefresh: () => Promise<unknown> | unknown;
};

const ProfileTableActions = ({ profile, onRefresh }: Props) => {
  const { selectedEmployeeIds, setSelectedEmployeeIds } =
    useProfileConfigurationStore();
  const { submitProfile } = useProfileForm({});
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      const updatedEmployeeIds = selectedEmployeeIds.filter(
        (id) => id !== profile.employeeId,
      );
      setSelectedEmployeeIds(updatedEmployeeIds);
      await submitProfile({
        employeeIds: updatedEmployeeIds,
        type: profile.type,
      });
      await onRefresh?.();
    } catch (err) {
      dispatchToast({
        content: `Erro ao desvincular o perfil. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  }, [
    profile,
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    submitProfile,
    onRefresh,
  ]);
  return (
    <TableBaseActions
      unlink={{
        loading: isDeleting,
      }}
      onUnlink={handleDelete}
      actions={['unlink']}
    />
  );
};

export default ProfileTableActions;
