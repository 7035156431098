import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import React, { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { getServiceRequest } from '@api/connectOn/connectOn.service';
import { useTravelItemStore } from '../../../../../store/RequestTravelItem.store';
import { useTravelStore } from '../../../../../store/RequestTravel.store';

interface SelectTravelersProps {
  control: Control;
}

export function SelectTravelers({ control }: SelectTravelersProps) {
  const [options, setOptions] = useState<{ value: number; label: string }[]>(
    [],
  );
  const contextId = useTravelStore((state) => state.contextId);
  const idRv = useTravelItemStore((state) => state.id);

  useEffect(() => {
    const fetchServiceRequest = async () => {
      try {
        const response = await getServiceRequest({
          id: idRv,
          contextId: contextId,
        });
        const formattedOptions = response.serviceRequest.travelers.map(
          (traveler) => ({
            value: traveler.id,
            label: `${traveler.firstName}`,
            traveler: traveler,
          }),
        );
        setOptions(formattedOptions);
      } catch (error) {
        console.error('Failed to fetch travelers:', error);
        // Handle error scenarios appropriately
      }
    };

    fetchServiceRequest();
  }, []);

  return (
    <Controller
      control={control}
      name="travelers"
      render={({ field, fieldState: { error } }) => (
        <SelectField
          onSelectChange={(event, selectedItem) => {
            field.onChange(selectedItem);
          }}
          fullWidth
          value={field.value}
          options={options}
          label={'Selecione o condutor'}
          noOptionsText={'Nenhum viajante encontrado.'}
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  );
}
